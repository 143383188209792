// ModalInformacion.js
import React, { useState, useEffect, useMemo } from 'react';
import { XIcon } from '@heroicons/react/solid';

const CalculadorCuota = ({ 
  carencia,
  cantidadPorPagar,
  anosFaltantes,
  plazoOriginal,
  interesAnual,
  pagoMensualHipoteca 
}) => {
  const [selectedAmpliacion, setSelectedAmpliacion] = useState(0);
  const [cuotaCalculada, setCuotaCalculada] = useState(0);

  const maxAmpliacion = useMemo(() => {
    const anosHastaMaximo40 = 40 - plazoOriginal;
    return carencia === 'carencia5' 
      ? Math.max(0, anosHastaMaximo40)
      : Math.min(7, Math.max(0, anosHastaMaximo40));
  }, [carencia, plazoOriginal]);

  useEffect(() => {
    const calcularCuota = () => {
      const mesesTotales = (anosFaltantes + selectedAmpliacion) * 12;
      const interesOriginalMensual = (interesAnual / 100) / 12;
      const cuotaNueva = cantidadPorPagar * 
        (interesOriginalMensual * Math.pow(1 + interesOriginalMensual, mesesTotales)) /
        (Math.pow(1 + interesOriginalMensual, mesesTotales) - 1);
      
      setCuotaCalculada(Math.round(cuotaNueva));
    };

    calcularCuota();
  }, [selectedAmpliacion, cantidadPorPagar, anosFaltantes, interesAnual]);

  // Cálculo del dashoffset para el círculo de progreso
  const strokeDashoffset = 377 - (377 * (cuotaCalculada / pagoMensualHipoteca));
  const safeStrokeDashoffset = isNaN(strokeDashoffset) ? 0 : strokeDashoffset;

  return (
    <div className="w-full p-6 space-y-6">
      <div className="bg-purple-50 p-4 rounded-lg">
        <h3 className="text-lg font-bold text-purple-700 mb-4">
          Calcula la cuota después de la carencia
        </h3>
        <p className="text-sm text-gray-600 mb-4">
          Mueve la barra para ver cómo quedaría la cuota según los años que quieras ampliar la hipoteca
        </p>
        
        <div className="space-y-2">
          <div className="flex justify-between text-sm text-gray-600">
            <span>0 años</span>
            <span>{maxAmpliacion} años</span>
          </div>
          <input
            type="range"
            min="0"
            max={maxAmpliacion}
            value={selectedAmpliacion}
            onChange={(e) => setSelectedAmpliacion(Number(e.target.value))}
            className="w-full h-2 bg-purple-200 rounded-lg appearance-none cursor-pointer"
          />
          <div className="text-center text-purple-700 font-semibold">
            Ampliación: {selectedAmpliacion} años
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="flex flex-col items-center pr-4 mb-4 md:mb-0">
          <div className="border border-gray-300 p-4 rounded-md flex flex-col items-center">
            <span className="text-xl text-gray-700 mb-4 font-bold">Cuota después de la carencia</span>
            <div className="relative w-44 h-44">
              <svg className="absolute top-0 left-0 w-full h-full">
                <circle 
                  className="text-blue-200" 
                  strokeWidth="4" 
                  stroke="currentColor" 
                  fill="transparent" 
                  r="60" 
                  cx="88" 
                  cy="88" 
                />
                <circle 
                  className="text-purple-600" 
                  strokeWidth="4" 
                  strokeDasharray="377" 
                  strokeDashoffset={safeStrokeDashoffset} 
                  strokeLinecap="round" 
                  stroke="currentColor" 
                  fill="transparent" 
                  r="60" 
                  cx="88" 
                  cy="88" 
                />
                <text 
                  x="50%" 
                  y="50%" 
                  textAnchor="middle" 
                  dy=".3em" 
                  className="text-3xl font-bold text-gray-700"
                >
                  {Math.round(cuotaCalculada)}
                </text>
                <text 
                  x="50%" 
                  y="65%" 
                  textAnchor="middle" 
                  dy=".3em" 
                  className="text-lg text-gray-700 mb-2"
                >
                  €
                </text>
              </svg>
            </div>
          </div>
          {selectedAmpliacion > 0 && (
            <div className="text-center mt-4 text-sm text-gray-600">
              <p>Plazo total: {anosFaltantes + selectedAmpliacion} años</p>
              <p>Diferencia mensual: {pagoMensualHipoteca - cuotaCalculada}€</p>
            </div>
          )}
        </div>
      </div>

    </div>
  );
};

const PreguntaFrecuente = ({ pregunta, respuesta }) => (
  <details className="p-4 border rounded-md">
    <summary className="font-semibold cursor-pointer hover:text-purple-600 flex items-center justify-between">
      {pregunta}
      <span className="transform transition-transform duration-200 details-marker-hidden">
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </span>
    </summary>
    <div className="mt-4 text-gray-700 pl-4 border-l-2 border-purple-200">
      {respuesta}
    </div>
  </details>
);

const VideoExplicativo = () => (
  <div className="mb-8 rounded-lg overflow-hidden shadow-lg">
    <div 
      className="wistia_responsive_padding" 
      style={{ padding: '56.25% 0 0 0', position: 'relative' }}
    >
      <div 
        className="wistia_responsive_wrapper" 
        style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}
      >
        <iframe 
          src="https://fast.wistia.net/embed/iframe/tef64ulxos?seo=true&videoFoam=true" 
          title="Video explicativo del CBP" 
          allow="autoplay; fullscreen" 
          allowTransparency="true" 
          frameBorder="0" 
          scrolling="no" 
          className="wistia_embed" 
          name="wistia_embed" 
          width="100%" 
          height="100%"
        />
      </div>
    </div>
  </div>
);

const preguntasFrecuentes = [
  {
    pregunta: "¿Qué ocurre con lo que no pago durante la carencia?",
    respuesta: "La reducción del interés es un ahorro real, no se paga a futuro. El principal de la hipoteca queda congelado durante el período de carencia."
  },
  {
    pregunta: "¿Pagaré más dinero a largo plazo?",
    respuesta: "No, porque puedes mantener la fecha de vencimiento original. Además, gracias a la reducción en los intereses, el coste total será menor."
  },
  {
    pregunta: "¿Cuánto tiempo tarda el proceso?",
    respuesta: "El proceso suele durar entre 2 y 4 meses. Nosotros establecemos un máximo de trabajo de 5 meses para asegurar un servicio eficiente."
  },
  {
    pregunta: "¿Es obligatorio ampliar el plazo de la hipoteca?",
    respuesta: "No, la ampliación del plazo es completamente opcional. Si el banco indica lo contrario, están proporcionando información incorrecta."
  },
  {
    pregunta: "¿Puedo hacer el proceso una segunda vez?",
    respuesta: "Si la primera vez te acogiste al CBP antiguo, sí puedes hacerlo una segunda vez, siempre que la necesidad no sea consecuencia directa de la finalización de la primera carencia."
  },
  {
    pregunta: "¿Quién garantiza que el proceso saldrá bien?",
    respuesta: "La propia ley lo garantiza. Si cumples los requisitos, es obligatorio que el banco aplique las medidas del CBP. Además, puedes reclamar al Banco de España si la entidad no cumple."
  },
  {
    pregunta: "¿Puedo hacer el proceso aunque esté divorciado?",
    respuesta: "Sí. Si tu ex cónyuge sigue en las escrituras de la hipoteca, necesitaremos su documentación. Si se ha realizado una novación y ya no aparece en la hipoteca, solo necesitamos tu documentación y situación."
  },
  {
    pregunta: "¿Puedo hacer el proceso aunque la hipoteca esté impagada?",
    respuesta: "Sí, siempre que no haya fecha de subasta. Algunos bancos incluyen los impagos en la novación, otros no y hay que buscar alternativas para regularizar la situación."
  }
];

const ExplicacionCarencia = ({ formData, carencia }) => (
  <div className="space-y-4">
    <VideoExplicativo />
    
    <div className="bg-purple-50 p-4 rounded-lg mb-6">
      <h4 className="text-lg font-bold text-purple-700 mb-2">
        Puntos clave sobre la carencia
      </h4>
      <ul className="list-disc pl-5 space-y-2 text-gray-700">
        <li>Reducción significativa de la cuota mensual</li>
        <li>Ahorro real en intereses</li>
        <li>Sin obligación de ampliar el plazo</li>
        <li>Proceso avalado por el Banco de España</li>
      </ul>
    </div>

    <details className="p-4 border rounded-md">
      <summary className="font-semibold cursor-pointer text-purple-600 flex items-center justify-between">
        Calcula la cuota después de la carencia 
        <span className="transform transition-transform duration-200 details-marker-hidden">
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </span>
      </summary>
      <div className="mt-4">
        <CalculadorCuota 
          carencia={carencia}
          cantidadPorPagar={parseFloat(formData.cantidadPorPagar)}
          anosFaltantes={parseInt(formData.anosFaltantes)}
          plazoOriginal={parseInt(formData.plazoOriginalHipoteca)}
          interesAnual={parseFloat(formData.interesAnual)}
          pagoMensualHipoteca={parseFloat(formData.pagoMensualHipoteca)}
        />
      </div>
    </details>

    <div>
      <h4 className="text-lg font-bold mb-4">Preguntas frecuentes:</h4>
      <div className="space-y-2">
        {preguntasFrecuentes.map((pregunta, index) => (
          <PreguntaFrecuente
            key={index}
            pregunta={pregunta.pregunta}
            respuesta={pregunta.respuesta}
          />
        ))}
      </div>
    </div>
  </div>
);

const ModalInformacion = ({ isModalOpen, modalContent, handleModalClose, formData, carencia }) => {
  if (!isModalOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center p-4 z-50"
         onClick={handleModalClose}>
      <div 
        className="bg-white p-6 rounded-lg shadow-xl w-full max-w-2xl relative overflow-y-auto max-h-[90vh]"
        onClick={e => e.stopPropagation()}
      >
        <button 
          onClick={handleModalClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 
            transition-colors duration-200 focus:outline-none"
        >
          <XIcon className="h-6 w-6" />
        </button>

        <div className="border-b pb-4 mb-4">
          <h3 className="text-xl font-bold text-gray-900">
            {modalContent === 'Explicación carencia' ? 'Explicación de la Carencia' : 
             modalContent === 'calculador-cuota' ? 'Calcula tu cuota después de la carencia' :
             'Información'}
          </h3>
        </div>

        <div className="modal-content">
          {modalContent === 'Explicación carencia' ? (
            <ExplicacionCarencia formData={formData} carencia={carencia} />
          ) : modalContent === 'calculador-cuota' ? (
            <CalculadorCuota 
              carencia={carencia}
              cantidadPorPagar={parseFloat(formData.cantidadPorPagar)}
              anosFaltantes={parseInt(formData.anosFaltantes)}
              plazoOriginal={parseInt(formData.plazoOriginalHipoteca)}
              interesAnual={parseFloat(formData.interesAnual)}
              pagoMensualHipoteca={parseFloat(formData.pagoMensualHipoteca)}
            />
          ) : (
            <div className="prose max-w-none">
              <p className="text-gray-700">{modalContent}</p>
            </div>
          )}
        </div>

        <div className="mt-6 pt-4 border-t flex justify-end">
          <button
            onClick={handleModalClose}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md 
              hover:bg-gray-300 transition-colors duration-200"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalInformacion;
export { CalculadorCuota };
