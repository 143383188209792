import React, { useState } from 'react';
import { supabase } from '../utils/supabaseClient';

const AddUserModal = ({ isOpen, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    nombre: '',
    apellidos: '',
    dni: '',
    rol: '',
    colaborador: '',
    estado: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value.toLowerCase(), // Convertir a minúsculas
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password, nombre, apellidos, dni, rol, colaborador, estado } = formData;
    
    try {
      // Create user in Supabase
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (error) {
        throw error;
      }

      // Save additional user data in a separate table
      const { error: insertError } = await supabase
        .from('usuarios')
        .insert([{ nombre, apellidos, email, dni, rol, colaborador, estado, user_id: data.user.id }]);

      if (insertError) {
        throw insertError;
      }

      alert('Usuario creado con éxito. Por favor, revisa tu correo electrónico para confirmar tu cuenta.');
      onSave({ nombre, apellidos, email, dni, rol, colaborador, estado, user_id: data.user.id });
      onClose();
    } catch (error) {
      console.error('Error creando usuario:', error);
      alert('Error creando usuario: ' + error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-4 rounded-md w-96">
        <h2 className="text-xl font-bold mb-4">Añadir nuevo usuario</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-2">
            <label className="block text-sm">Correo</label>
            <input
              type="email"
              className="w-full border border-gray-300 p-2 rounded-md"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm">Contraseña</label>
            <input
              type="password"
              className="w-full border border-gray-300 p-2 rounded-md"
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm">Nombre</label>
            <input
              type="text"
              className="w-full border border-gray-300 p-2 rounded-md"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm">Apellidos</label>
            <input
              type="text"
              className="w-full border border-gray-300 p-2 rounded-md"
              name="apellidos"
              value={formData.apellidos}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm">DNI</label>
            <input
              type="text"
              className="w-full border border-gray-300 p-2 rounded-md"
              name="dni"
              value={formData.dni}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm">Rol</label>
            <select
              className="w-full border border-gray-300 p-2 rounded-md"
              name="rol"
              value={formData.rol}
              onChange={handleChange}
            >
              <option value="">Seleccione un rol</option>
              <option value="abogado">Abogado</option>
              <option value="comercial">Comercial</option>
              <option value="admin">Admin</option>
              <option value="superadmin">Superadmin</option>
              <option value="cbp">CBP</option>
              <option value="contable">Contable</option>
            </select>
          </div>
          <div className="mb-2">
            <label className="block text-sm">Colaborador</label>
            <input
              type="text"
              className="w-full border border-gray-300 p-2 rounded-md"
              name="colaborador"
              value={formData.colaborador}
              onChange={handleChange}
            />
          </div>
          <div className="mb-2">
            <label className="block text-sm">Estado</label>
            <select
              className="w-full border border-gray-300 p-2 rounded-md"
              name="estado"
              value={formData.estado}
              onChange={handleChange}
            >
              <option value="">Seleccione un estado</option>
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="bg-gray-200 text-black px-4 py-2 rounded-md mr-2"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="bg-gradient-to-r from-purple-500 to-blue-500 text-white px-4 py-2 rounded-md"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddUserModal;
