import React, { useEffect, useState } from 'react';
import { supabase } from '../../utils/supabaseClient';
import Table from '../../components/Table';
import Select from 'react-select';

const Pagos = () => {
  const [pagos, setPagos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [cuantia, setCuantia] = useState('');
  const [selectedModalidadPago, setSelectedModalidadPago] = useState({ value: 'Tarjeta de crédito', label: 'Tarjeta de crédito' });
  const [fecha, setFecha] = useState(new Date().toISOString().split('T')[0]);
  const [showFormPagoInicial, setShowFormPagoInicial] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [leads, setLeads] = useState([]);
  const [selectedCarencia, setSelectedCarencia] = useState(null);

  const fetchDatos = async () => {
    try {
      setLoading(true);
      // Fetch pagos data
      const { data: pagosData, error: pagosError } = await supabase
        .from('pagos')
        .select('cliente_id, cuantia, modalidad_de_pago, fecha');

      if (pagosError) {
        throw pagosError;
      }

      // Fetch all cliente data
      const { data: clientesData, error: clientesError } = await supabase
        .from('clientes')
        .select('id, nombre, apellidos');

      if (clientesError) {
        throw clientesError;
      }

      // Fetch leads data
      const { data: leadsData, error: leadsError } = await supabase
        .from('leads')
        .select('id, nombre, apellidos');

      if (leadsError) {
        throw leadsError;
      }

      // Combine the data
      const combinedData = pagosData.map(pago => {
        const cliente = clientesData.find(c => c.id === pago.cliente_id);
        return {
          ...pago,
          nombre: cliente ? cliente.nombre : '',
          apellidos: cliente ? cliente.apellidos : '',
        };
      });

      setPagos(combinedData);
      setClientes(clientesData);
      setLeads(leadsData);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDatos();
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: 'Nombre',
      accessor: 'nombre',
    },
    {
      Header: 'Apellidos',
      accessor: 'apellidos',
    },
    {
      Header: 'Cuantía',
      accessor: 'cuantia',
    },
    {
      Header: 'Modalidad de Pago',
      accessor: 'modalidad_de_pago',
    },
    {
      Header: 'Fecha',
      accessor: 'fecha',
    },
  ], []);

  const handleAddPago = async () => {
    if (!selectedCliente || !cuantia || !selectedModalidadPago || !fecha) {
      alert('Por favor, complete todos los campos');
      return;
    }

    const { data: pagoData, error: pagoError } = await supabase
      .from('pagos')
      .insert([
        { cliente_id: selectedCliente.value, cuantia, modalidad_de_pago: selectedModalidadPago.value, fecha }
      ]);

    if (pagoError) {
      console.error('Error adding pago:', pagoError.message);
      return;
    }

    // Check and update cuotas
    const { data: cuotasData, error: cuotasError } = await supabase
      .from('cuotas')
      .select('id, cuantia, fecha_prevista, estado')
      .eq('cliente_id', selectedCliente.value)
      .order('fecha_prevista', { ascending: true });

    if (cuotasError) {
      console.error('Error fetching cuotas:', cuotasError.message);
      return;
    }

    let remainingAmount = parseFloat(cuantia);
    for (const cuota of cuotasData) {
      if (remainingAmount >= cuota.cuantia && cuota.estado !== 'Cuota Pagada') {
        remainingAmount -= cuota.cuantia;
        await supabase
          .from('cuotas')
          .update({ estado: 'Cuota Pagada' })
          .eq('id', cuota.id);
      }
    }

    setPagos([...pagos, { cliente_id: selectedCliente.value, cuantia, modalidad_de_pago: selectedModalidadPago.value, fecha, nombre: selectedCliente.label.split(' ')[0], apellidos: selectedCliente.label.split(' ')[1] }]);
    setShowForm(false);
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  const handleClienteChange = (selectedOption) => {
    setSelectedCliente(selectedOption);
  };

  const handleModalidadPagoChange = (selectedOption) => {
    setSelectedModalidadPago(selectedOption);
  };

  const handleShowFormPagoInicial = () => {
    setShowFormPagoInicial(!showFormPagoInicial);
  };

  const handleLeadChange = (selectedOption) => {
    setSelectedLead(selectedOption);
  };

  const handlePlanChange = (selectedOption) => {
    setSelectedPlan(selectedOption);
  };

  const handleProcesarPagoInicial = async () => {
    if (!selectedLead || !selectedPlan || !selectedCarencia) {
      alert('Por favor, complete todos los campos');
      return;
    }

    try {
      // 1. Obtener datos del lead
      const { data: leadData, error: leadError } = await supabase
        .from('leads')
        .select('*')
        .eq('id', selectedLead.value)
        .single();

      if (leadError) throw leadError;

      // 2. Crear cliente con los datos del lead
      const clienteData = {
        id: selectedLead.value,
        nombre: leadData.nombre,
        apellidos: leadData.apellidos,
        telefono: leadData.telefono,
        email: leadData.email,
        provincia: leadData.provincia,
        domicilio: leadData.domicilio,
        created_at: new Date(),
        // Campos opcionales que pueden venir del lead
        estado_civil: leadData.estado_civil || null,
        bancoHipoteca: leadData.banco || null,
        precio_vivienda: leadData.precio_vivienda || null,
        ingresosBrutosAnuales: leadData.ingresos_brutos || null,
        ingresosNetosAnuales: leadData.ingresos_netos || null,
        pagoMensualHipoteca: leadData.cuota_mensual || null,
        vivienda_habitual: leadData.vivienda_habitual || null,
        fecha_firma_hipoteca: leadData.fecha_firma || null,
        Carencia: selectedCarencia.label // Añadimos la carencia seleccionada
      };

      const { error: clienteError } = await supabase
        .from('clientes')
        .upsert([clienteData], { onConflict: 'id' });

      if (clienteError) throw clienteError;

      // 3. Insertar en plan_honorarios
      const { error: planError } = await supabase
        .from('plan_honorarios')
        .insert([{
          user_id: selectedLead.value,
          duracion_carencia: selectedCarencia.label,
          total_honorarios: selectedPlan.honorarios,
          tipo_plan: selectedPlan.label,
          pago_inicial: selectedPlan.pagoInicial,
          pago_mensual: selectedPlan.pagoMensual,
          nr_de_cuotas: selectedPlan.cuotas,
          created_at: new Date()
        }]);

      if (planError) throw planError;

      // 4. Insertar/Actualizar en customer_journey
      const { error: journeyError } = await supabase
        .from('customer_journey')
        .upsert([{
          user_id: selectedLead.value,
          estado: 'Cliente Primeros Pasos',
          timestamp: new Date(),
          updated_at: new Date()
        }], { onConflict: 'user_id' });

      if (journeyError) throw journeyError;

      // 5. Insertar en pagos
      const { error: pagoError } = await supabase
        .from('pagos')
        .insert([{
          cliente_id: selectedLead.value,
          cuantia: selectedPlan.pagoInicial,
          modalidad_de_pago: selectedModalidadPago.value,
          fecha: new Date().toISOString().split('T')[0]
        }]);

      if (pagoError) throw pagoError;

      // 6. Insertar cuotas si existen
      if (selectedPlan.cuotas > 0) {
        const cuotas = [];
        const currentDate = new Date();
        
        for (let i = 0; i < selectedPlan.cuotas; i++) {
          const fechaPrevista = new Date(currentDate);
          fechaPrevista.setMonth(currentDate.getMonth() + i + 1);
          
          cuotas.push({
            cliente_id: selectedLead.value,
            cuantia: selectedPlan.pagoMensual,
            fecha_prevista: fechaPrevista.toISOString().split('T')[0],
            estado: 'pendiente'
          });
        }

        const { error: cuotasError } = await supabase
          .from('cuotas')
          .insert(cuotas);

        if (cuotasError) throw cuotasError;
      }

      // Si todo sale bien, mostrar mensaje de éxito y resetear el formulario
      alert('Cliente creado y pago inicial procesado correctamente');
      setShowFormPagoInicial(false);
      setSelectedLead(null);
      setSelectedCarencia(null);
      setSelectedPlan(null);
      
      // Actualizar la tabla de pagos
      fetchDatos();

    } catch (error) {
      console.error('Error al procesar el pago inicial:', error);
      alert('Error al procesar el pago inicial. Por favor, intente nuevamente.');
    }
  };

  const carenciaOptions = [
    { value: 'carencia2o5', label: 'Carencia 2 o 5 años' },
    { value: 'carencia1', label: 'Carencia 1 año' }
  ];

  const getPlanOptions = (carencia) => {
    if (carencia === 'carencia2o5') {
      return [
        { 
          value: 'pro', 
          label: 'Pro',
          honorarios: 1489,
          pagoInicial: 499,
          pagoMensual: 99,
          cuotas: 10
        },
        { 
          value: 'premium', 
          label: 'Premium',
          honorarios: 999,
          pagoInicial: 999,
          pagoMensual: 0,
          cuotas: 0
        }
      ];
    } else if (carencia === 'carencia1') {
      return [
        { 
          value: 'pro', 
          label: 'Pro',
          honorarios: 793,
          pagoInicial: 199,
          pagoMensual: 99,
          cuotas: 6
        },
        { 
          value: 'premium', 
          label: 'Premium',
          honorarios: 499,
          pagoInicial: 499,
          pagoMensual: 0,
          cuotas: 0
        }
      ];
    }
    return [];
  };

  const handleCarenciaChange = (selectedOption) => {
    setSelectedCarencia(selectedOption);
    setSelectedPlan(null); // Reset plan when carencia changes
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const clienteOptions = clientes.map(cliente => ({
    value: cliente.id,
    label: `${cliente.nombre} ${cliente.apellidos}`
  }));

  const modalidadPagoOptions = [
    { value: 'Tarjeta de crédito', label: 'Tarjeta de crédito' },
    { value: 'Transferencia', label: 'Transferencia' },
    { value: 'Efectivo', label: 'Efectivo' },
    { value: 'Descuento', label: 'Descuento' }
  ];

  const leadOptions = leads.map(lead => ({
    value: lead.id,
    label: `${lead.nombre} ${lead.apellidos}`
  }));

  return (
    <div>
      <div className="flex gap-4 mb-4">
        <button 
          onClick={handleShowForm} 
          className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white flex items-center p-2 rounded"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Añadir Pago
        </button>
        <button 
          onClick={handleShowFormPagoInicial} 
          className="bg-gradient-to-r from-blue-500 to-indigo-500 text-white flex items-center p-2 rounded"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
          Añadir Pago Inicial
        </button>
      </div>

      {showFormPagoInicial && (
        <form className="mt-4 p-4 border rounded bg-gray-50">
          <label className="block mb-2">
            Buscar Lead:
            <Select
              options={leadOptions}
              onChange={handleLeadChange}
              className="block w-full mt-1"
            />
          </label>
          <label className="block mb-2">
            Duración carencia:
            <Select
              options={carenciaOptions}
              onChange={handleCarenciaChange}
              value={selectedCarencia}
              className="block w-full mt-1"
            />
          </label>
          <label className="block mb-2">
            Tipo de Plan:
            <Select
              options={selectedCarencia ? getPlanOptions(selectedCarencia.value) : []}
              onChange={handlePlanChange}
              value={selectedPlan}
              isDisabled={!selectedCarencia}
              className="block w-full mt-1"
            />
          </label>
          {selectedPlan && (
            <div className="mt-4 p-4 bg-gray-100 rounded">
              <p>Total Honorarios: {selectedPlan.honorarios}€</p>
              <p>Pago Inicial: {selectedPlan.pagoInicial}€</p>
              <p>Pago Mensual: {selectedPlan.pagoMensual}€</p>
              <p>Número de cuotas: {selectedPlan.cuotas}</p>
            </div>
          )}
          <label className="block mb-2">
            Modalidad de Pago:
            <Select
              options={modalidadPagoOptions}
              onChange={handleModalidadPagoChange}
              value={selectedModalidadPago}
              className="block w-full mt-1"
            />
          </label>
          <button 
            type="button" 
            onClick={handleProcesarPagoInicial} 
            className="bg-gradient-to-r from-green-500 to-teal-500 text-white p-2 rounded mt-4"
          >
            Procesar Pago Inicial
          </button>
        </form>
      )}

      {showForm && (
        <form className="mt-4 p-4 border rounded">
          <label className="block mb-2">
            Cliente:
            <Select
              options={clienteOptions}
              onChange={handleClienteChange}
              className="block w-full mt-1"
            />
          </label>
          <label className="block mb-2">
            Cuantía:
            <input 
              type="number" 
              value={cuantia} 
              onChange={(e) => setCuantia(e.target.value)} 
              className="block w-full mt-1 p-2 border rounded"
            />
          </label>
          <label className="block mb-2">
            Modalidad de Pago:
            <Select
              options={modalidadPagoOptions}
              onChange={handleModalidadPagoChange}
              className="block w-full mt-1"
              value={selectedModalidadPago}
            />
          </label>
          <label className="block mb-2">
            Fecha:
            <input 
              type="date" 
              value={fecha} 
              onChange={(e) => setFecha(e.target.value)} 
              className="block w-full mt-1 p-2 border rounded"
            />
          </label>
          <button 
            type="button" 
            onClick={handleAddPago} 
            className="bg-gradient-to-r from-purple-500 to-blue-500 text-white p-2 rounded"
          >
            Añadir
          </button>
        </form>
      )}
      <Table columns={columns} data={pagos} />
    </div>
  );
}

export default Pagos;
