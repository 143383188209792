import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '../../utils/supabaseClient';
import Table from '../../components/Table';

const CentralizadorClientes = () => {
  const [planHonorarios, setPlanHonorarios] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDatos = async () => {
      try {
        // Fetch plan_honorarios data
        const { data: honorariosData, error: honorariosError } = await supabase
          .from('plan_honorarios')
          .select('user_id, total_honorarios, tipo_plan, pago_inicial, pago_mensual, nr_de_cuotas');

        if (honorariosError) {
          throw honorariosError;
        }

        // Fetch cliente data for each user_id in plan_honorarios
        const userIds = honorariosData.map(h => h.user_id);
        const { data: clientesData, error: clientesError } = await supabase
          .from('clientes')
          .select('id, nombre, apellidos, telefono, email')
          .in('id', userIds);

        if (clientesError) {
          throw clientesError;
        }

        // Combine the data
        const combinedData = honorariosData.map(honorario => {
          const cliente = clientesData.find(c => c.id === honorario.user_id);
          return {
            ...honorario,
            nombre: cliente ? cliente.nombre : '',
            apellidos: cliente ? cliente.apellidos : '',
            telefono: cliente ? cliente.telefono : '',
            email: cliente ? cliente.email : '',
          };
        });

        setPlanHonorarios(combinedData);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDatos();
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: 'Nombre',
      accessor: 'nombre',
      Cell: ({ row }) => (
        <Link to={`/clientes/${row.original.user_id}`} className="text-blue-500 hover:underline">
          {row.original.nombre}
        </Link>
      ),
    },
    {
      Header: 'Apellidos',
      accessor: 'apellidos',
    },
    {
      Header: 'Teléfono',
      accessor: 'telefono',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Total Honorarios',
      accessor: 'total_honorarios',
    },
    {
      Header: 'Tipo de Plan',
      accessor: 'tipo_plan',
    },
    {
      Header: 'Pago Inicial',
      accessor: 'pago_inicial',
    },
    {
      Header: 'Pago Mensual',
      accessor: 'pago_mensual',
    },
    {
      Header: 'Número de Cuotas',
      accessor: 'nr_de_cuotas',
    },
  ], []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Centralizador Clientes</h1>
      <Table columns={columns} data={planHonorarios} />
    </div>
  );
}

export default CentralizadorClientes;
