import React, { useMemo, useState } from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;

  return (
    <div className="flex items-center h-10 border border-gray-300 rounded-lg overflow-hidden">
      <input
        value={globalFilter || ''}
        onChange={e => setGlobalFilter(e.target.value || undefined)}
        placeholder={`Buscar en ${count} registros...`}
        className="p-2 border-none outline-none"
      />
      <button className="p-2 bg-gradient-to-r from-purple-500 to-blue-500 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
        </svg>
      </button>
    </div>
  );
}

const Table = ({ columns, data, isLeadsPage }) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const defaultColumn = useMemo(() => ({ Filter: () => null }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter: setTableGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { globalFilter },
    },
    useGlobalFilter,
    useSortBy
  );

  const pageCount = Math.ceil(rows.length / rowsPerPage);
  const paginatedRows = rows.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);

  return (
    <section className="py-4 overflow-hidden">
      <div className="container mx-auto px-4 max-w-full">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-4">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setTableGlobalFilter}
            />
          </div>
        </div>
        <div className="pt-2 bg-white border border-gray-200 rounded-xl">
          <div className="px-1">
            <div className="w-full overflow-x-auto">
              <table {...getTableProps()} className="w-full min-w-max">
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} className="text-left">
                      {headerGroup.headers.map(column => {
                        const { key, ...rest } = column.getHeaderProps(column.getSortByToggleProps());
                        return (
                          <th key={key} {...rest} className="p-3 border-b border-gray-300 bg-gray-100 text-gray-700">
                            <div className="flex items-center">
                              {column.render('Header')}
                              <span className="ml-2">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                    </svg>
                                  ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                                    </svg>
                                  )
                                ) : (
                                  ''
                                )}
                              </span>
                            </div>
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {paginatedRows.map((row, i) => {
                    prepareRow(row);
                    const backgroundColor = i % 2 === 0 ? 'bg-gray-50' : 'bg-white';
                    return (
                      <tr key={row.id} {...row.getRowProps()} className={`${backgroundColor} hover:bg-gray-100`}>
                        {row.cells.map(cell => {
                          const { key, ...rest } = cell.getCellProps();
                          return (
                            <td key={key} {...rest} className="py-3 pr-4 pl-3 border-b border-gray-200">
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-4 pt-2">
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-600">
              Mostrando {currentPage * rowsPerPage + 1} a {Math.min((currentPage + 1) * rowsPerPage, rows.length)} de {rows.length} resultados
            </span>
            <span className="text-sm text-gray-600 ml-4">Mostrar</span>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setCurrentPage(0);
              }}
              className="p-2 border border-gray-300 rounded-lg"
            >
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <span className="text-sm text-gray-600">entradas</span>
          </div>
          <div className="flex space-x-1">
            <button className="p-2 bg-gray-200 text-black rounded" onClick={() => setCurrentPage(0)} disabled={currentPage === 0}>
              {"<<"}
            </button>
            <button className="p-2 bg-gray-200 text-black rounded" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
              {"<"}
            </button>
            {Array.from({ length: pageCount }, (_, index) => (
              <button
                key={index}
                className={`p-2 ${index === currentPage ? 'bg-gray-300' : 'bg-gray-200'} text-black rounded`}
                onClick={() => setCurrentPage(index)}
              >
                {index + 1}
              </button>
            ))}
            <button className="p-2 bg-gray-200 text-black rounded" onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage >= pageCount - 1}>
              {">"}
            </button>
            <button className="p-2 bg-gray-200 text-black rounded" onClick={() => setCurrentPage(pageCount - 1)} disabled={currentPage >= pageCount - 1}>
              {">>"}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Table;
