// Navbar.js

import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'; // Asegúrate de que la ruta sea correcta
import { AuthContext } from '../contexts/AuthContext';
import { supabase } from '../utils/supabaseClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const UserIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-3" width={20} height={20}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
  </svg>
);

const ClipboardIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-3" width={20} height={20}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
  </svg>
);

const UserGroupIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-3" width={20} height={20}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z" />
  </svg>
);

const FinanceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-3" width={20} height={20}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
  </svg>
);

const PaymentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-3" width={20} height={20}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M14.25 7.756a4.5 4.5 0 1 0 0 8.488M7.5 10.5h5.25m-5.25 3h5.25M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  </svg>
);

const QuotaIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="mr-3" width={20} height={20}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
  </svg>
);

const Navbar = () => {
  const { user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const [userData, setUserData] = useState({ nombre: '', apellidos: '' });

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const { data, error } = await supabase
          .from('usuarios')
          .select('nombre, apellidos')
          .eq('user_id', user.id)
          .single();

        if (error) {
          console.error('Error fetching user data:', error);
        } else {
          setUserData(data);
        }
      };

      fetchUserData();
    }
  }, [user]);

  const handleLogout = async () => {
    await supabase.auth.signOut();
    setUser(null);
    navigate('/signin');
  };

  return (
    <nav className="bg-gray-100 p-4 w-64 flex flex-col justify-between" style={{ minHeight: '100vh' }}>
      <div>
        <div className="flex items-center justify-center mb-8">
          <NavLink to="/">
            <img src={logo} alt="Logo" className="h-10 w-35" />
          </NavLink>
        </div>
        <ul className="flex flex-col space-y-4">
          <li className="py-1">
            <NavLink
              to="/usuarios"
              className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
              }`}
            >
              <UserIcon />
              <span>Usuarios</span>
            </NavLink>
          </li>
          <li className="py-1">
            <NavLink
              to="/leads"
              className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
              }`}
            >
              <ClipboardIcon />
              <span>Leads</span>
            </NavLink>
            <ul className="ml-4 mt-2">
              <li className="py-1">
                <NavLink
                  to="/cbp-2-5"
                  className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                    isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
                  }`}
                >
                  <ClipboardIcon />
                  <span>CBP 2 o 5 años</span>
                </NavLink>
              </li>
              <li className="py-1">
                <NavLink
                  to="/cbp-1"
                  className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                    isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
                  }`}
                >
                  <ClipboardIcon />
                  <span>CBP 1 año</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="py-1">
            <NavLink
              to="/clientes"
              className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
              }`}
            >
              <UserGroupIcon />
              <span>Clientes</span>
            </NavLink>
            <ul className="ml-4 mt-2"> {/* Añadido mt-2 para el padding superior */}
              <li className="py-1">
                <NavLink
                  to="/clientes/documentacion"
                  className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                    isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
                  }`}
                >
                  <ClipboardIcon />
                  <span>Documentación</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="py-1">
            <NavLink
              to="/tareas"
              className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
              }`}
            >
              <ClipboardIcon />
              <span>Tareas</span>
            </NavLink>
          </li>
          <li className="py-1">
            <NavLink
              to="/colaboradores"
              className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
              }`}
            >
              <ClipboardIcon />
              <span>Colaboradores</span>
            </NavLink>
          </li>
          <li className="py-1">
            <NavLink
              to="/gestoresbancos"
              className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
              }`}
            >
              <ClipboardIcon />
              <span>Gestores Bancos</span>
            </NavLink>
          </li>
          <li className="py-1">
            <NavLink
              to="/finanzas"
              className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
              }`}
            >
              <FinanceIcon />
              <span>Finanzas</span>
            </NavLink>
            <ul className="ml-4 mt-2">
              <li className="py-1">
                <NavLink
                  to="/finanzas/centralizador-clientes"
                  className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                    isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
                  }`}
                >
                  <ClipboardIcon />
                  <span>Centralizador Clientes</span>
                </NavLink>
              </li>
              <li className="py-1">
                <NavLink
                  to="/finanzas/pagos"
                  className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                    isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
                  }`}
                >
                  <PaymentIcon />
                  <span>Pagos</span>
                </NavLink>
              </li>
              <li className="py-1">
                <NavLink
                  to="/finanzas/cuotas"
                  className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                    isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
                  }`}
                >
                  <QuotaIcon />
                  <span>Cuotas</span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="py-1">
            <NavLink
              to="/informes"
              className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
              }`}
            >
              <ClipboardIcon />
              <span>Informes</span>
            </NavLink>
          </li>
          <li className="py-1">
            <NavLink
              to="/llamadas"
              className={({ isActive }) => `flex items-center text-gray-800 px-2 py-2 rounded-lg ${
                isActive ? 'bg-blue-500 text-white' : 'hover:bg-gray-200'
              }`}
            >
              <FontAwesomeIcon icon={faPhone} className="mr-3" />
              <span>Centralizador Llamadas</span>
            </NavLink>
          </li>
          {user && (
            <li className="flex items-center py-1.5 mt-4">
              <ClipboardIcon className="mr-2" />
              <span>{userData.nombre} {userData.apellidos}</span>
            </li>
          )}
        </ul>
      </div>
      <div className="mt-4 pt-4 border-t border-gray-200">
        <button
          onClick={handleLogout}
          className="flex items-center text-gray-800 px-2 py-2 rounded-lg hover:bg-gray-200 w-full"
        >
          <ClipboardIcon />
          <span>Cerrar Sesión</span>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
