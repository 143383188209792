import React, { useEffect, useState } from 'react';
import { supabase } from '../../utils/supabaseClient';
import Table from '../../components/Table';

const Cuotas = () => {
  const [cuotas, setCuotas] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDatos = async () => {
      try {
        // Fetch cuotas data
        const { data: cuotasData, error: cuotasError } = await supabase
          .from('cuotas')
          .select('cliente_id, cuantia, fecha_prevista, estado');

        if (cuotasError) {
          throw cuotasError;
        }

        // Fetch cliente data for each cliente_id in cuotas
        const clienteIds = cuotasData.map(c => c.cliente_id);
        const { data: clientesData, error: clientesError } = await supabase
          .from('clientes')
          .select('id, nombre, apellidos')
          .in('id', clienteIds);

        if (clientesError) {
          throw clientesError;
        }

        // Combine the data
        const combinedData = cuotasData.map(cuota => {
          const cliente = clientesData.find(c => c.id === cuota.cliente_id);
          return {
            ...cuota,
            nombre: cliente ? cliente.nombre : '',
            apellidos: cliente ? cliente.apellidos : '',
          };
        });

        setCuotas(combinedData);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDatos();
  }, []);

  const columns = React.useMemo(() => [
    {
      Header: 'Nombre',
      accessor: 'nombre',
    },
    {
      Header: 'Apellidos',
      accessor: 'apellidos',
    },
    {
      Header: 'Cuantía',
      accessor: 'cuantia',
    },
    {
      Header: 'Fecha Prevista',
      accessor: 'fecha_prevista',
    },
    {
      Header: 'Estado',
      accessor: 'estado',
    },
  ], []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Cuotas</h1>
      <Table columns={columns} data={cuotas} />
    </div>
  );
}

export default Cuotas;
