// src/pages/Usuarios/UsuarioDetalle.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../utils/supabaseClient';

const UsuarioDetalle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    email: '',
    dni: '',
    rol: '',
    colaborador: '',
    estado: '',
  });

  useEffect(() => {
    const fetchUser = async () => {
      const { data, error } = await supabase
        .from('usuarios')
        .select('*')
        .eq('user_id', id)
        .single();

      if (error) {
        setError('Error fetching user');
        setLoading(false);
      } else {
        setUser(data);
        setFormData(data);
        setLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { error } = await supabase
      .from('usuarios')
      .update(formData)
      .eq('user_id', id);

    if (error) {
      setError('Error updating user');
    } else {
      navigate('/usuarios');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container mx-auto px-4 pt-10">
      <div className="flex items-center mb-6">
        <button
          type="button"
          className="flex items-center text-black bg-gray-200 px-4 py-2 rounded-md mr-2"
          onClick={() => navigate('/usuarios')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15 3 9m0 0 6-6M3 9h12a6 6 0 0 1 0 12h-3" />
          </svg>
          Volver
        </button>
      </div>
      <h1 className="text-3xl font-bold mb-6 text-center">Detalle del Usuario</h1>
      <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-6 bg-white p-6 rounded-lg shadow-lg">
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Nombre</label>
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Apellidos</label>
          <input
            type="text"
            name="apellidos"
            value={formData.apellidos}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Correo</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">DNI</label>
          <input
            type="text"
            name="dni"
            value={formData.dni}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Rol</label>
          <select
            name="rol"
            value={formData.rol}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
          >
            <option value="">Seleccione un rol</option>
            <option value="abogado">Abogado</option>
            <option value="comercial">Comercial</option>
            <option value="admin">Admin</option>
            <option value="superadmin">Superadmin</option>
            <option value="cbp">CBP</option>
            <option value="contable">Contable</option>
          </select>
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Colaborador</label>
          <input
            type="text"
            name="colaborador"
            value={formData.colaborador}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">Estado</label>
          <select
            name="estado"
            value={formData.estado}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
          >
            <option value="">Seleccione un estado</option>
            <option value="activo">Activo</option>
            <option value="inactivo">Inactivo</option>
          </select>
        </div>
        <div className="col-span-2 flex justify-end mt-4">
          <button
            type="submit"
            className="bg-gradient-to-r from-purple-500 to-blue-500 text-white px-6 py-3 rounded-md text-lg"
          >
            Guardar
          </button>
        </div>
      </form>
    </div>
  );
};

export default UsuarioDetalle;
