import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { 
  CalculatorIcon, 
  DocumentDuplicateIcon,
  PaperAirplaneIcon
} from '@heroicons/react/24/outline';

import PrimerosPasos from './PrimerosPasos';
import GenerarDocumentos from './GenerarDocumentos';
import EnviarGestor from './EnviarGestor';

const PrepararExpedienteGastos = () => {
  // Cambiamos para usar el parámetro correcto de la URL
  const params = useParams();
  const clientId = params.id; // Esto capturará el ID de /clientes/:id

  // Log para debugging
  console.log('Client ID from URL params:', clientId);

  const [activeTab, setActiveTab] = useState('primerospasos');

  const tabs = [
    { 
      id: 'primerospasos', 
      label: 'Rellenar Datos', 
      component: PrimerosPasos, 
      icon: CalculatorIcon 
    },
    { 
      id: 'generarDocumentos', 
      label: 'Generar documentos', 
      component: GenerarDocumentos, 
      icon: DocumentDuplicateIcon 
    },
    { 
      id: 'enviarGestor', 
      label: 'Enviar la documentación al gestor', 
      component: EnviarGestor, 
      icon: PaperAirplaneIcon 
    }
  ];

  return (
    <div className="flex p-4">
      <div className="flex-1 p-4">
        {tabs.map((tab) => (
          activeTab === tab.id && 
          <tab.component 
            key={tab.id} 
            leadId={clientId}  // Pasamos el clientId como leadId
          />
        ))}
      </div>
      <div className="w-82 bg-gray-100 p-4" style={{ minHeight: 'calc(100vh - 2rem)' }}>
        <h2 className="text-xl font-bold mb-4">Preparar Expediente </h2>
        <ul className="flex flex-col space-y-2">
          {tabs.map((tab) => (
            <li key={tab.id} className="py-1">
              <button
                onClick={() => setActiveTab(tab.id)}
                className={`flex items-center text-gray-800 px-2 py-2 rounded-lg w-full text-left ${
                  activeTab === tab.id 
                    ? 'bg-gradient-to-r from-purple-500 to-blue-500 text-white' 
                    : 'hover:bg-gradient-to-r from-purple-500 to-blue-500-hover hover:text-white'
                } transition duration-300`}
              >
                <tab.icon className="h-5 w-5 mr-2" />
                <span>{tab.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PrepararExpedienteGastos;