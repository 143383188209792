import React, { useEffect, useState } from 'react';
import { supabase } from '../../utils/supabaseClient';
import Table from '../../components/Table';
import Modal from '../../components/Modal';
import RejectModal from '../../components/RejectModal';

const Documentacion = () => {
  const [documentos, setDocumentos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState(null);

  useEffect(() => {
    const fetchDatos = async () => {
      try {
        const { data: documentosData, error: documentosError } = await supabase
          .from('documentos')
          .select('id, cliente_id, familiar_id, tipo_documento, estado, url_documento, motivo_rechazo')
          .eq('estado', 'pendiente');

        if (documentosError) {
          throw documentosError;
        }

        const { data: clientesData, error: clientesError } = await supabase
          .from('clientes')
          .select('id, nombre, apellidos');

        if (clientesError) {
          throw clientesError;
        }

        const { data: familiaresData, error: familiaresError } = await supabase
          .from('familiares')
          .select('id, nombre, apellidos');

        if (familiaresError) {
          throw familiaresError;
        }

        const documentosConDatos = documentosData.map(documento => {
          const cliente = clientesData.find(cliente => cliente.id === documento.cliente_id);
          const familiar = familiaresData.find(familiar => familiar.id === documento.familiar_id);
          return {
            ...documento,
            nombre_cliente: cliente ? cliente.nombre : '',
            apellidos_cliente: cliente ? cliente.apellidos : '',
            nombre_familiar: familiar ? familiar.nombre : '',
            apellidos_familiar: familiar ? familiar.apellidos : ''
          };
        });

        setDocumentos(documentosConDatos);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDatos();
  }, []);

  const openModal = async (path, id) => {
    try {
      const { data, error } = await supabase
        .storage
        .from('documentos')
        .createSignedUrl(path, 60 * 60 * 24 * 7); // URL válida por una semana

      if (error) {
        throw error;
      }

      if (!data.signedUrl) {
        throw new Error('Public URL not found');
      }

      setSelectedDocument(data.signedUrl);
      setSelectedDocumentId(id);
      setModalOpen(true);
    } catch (error) {
      console.error('Error viewing document:', error.message);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedDocument(null);
    setSelectedDocumentId(null);
  };

  const approveDocument = async () => {
    try {
      const { error } = await supabase
        .from('documentos')
        .update({ estado: 'aprobado' })
        .eq('id', selectedDocumentId);

      if (error) {
        throw error;
      }

      setDocumentos((prevDocuments) =>
        prevDocuments.filter((doc) => doc.id !== selectedDocumentId)
      );
      closeModal();
    } catch (error) {
      console.error('Error approving document:', error.message);
    }
  };

  const rejectDocument = () => {
    setModalOpen(false);
    setRejectModalOpen(true);
  };

  const handleReject = async (motivoRechazo) => {
    try {
      const { error } = await supabase
        .from('documentos')
        .update({ estado: 'rechazado', motivo_rechazo: motivoRechazo })
        .eq('id', selectedDocumentId);

      if (error) {
        throw error;
      }

      setDocumentos((prevDocuments) =>
        prevDocuments.filter((doc) => doc.id !== selectedDocumentId)
      );
      setRejectModalOpen(false);
      setSelectedDocument(null);
      setSelectedDocumentId(null);
    } catch (error) {
      console.error('Error rejecting document:', error.message);
    }
  };

  const closeRejectModal = () => {
    setRejectModalOpen(false);
    setSelectedDocument(null);
    setSelectedDocumentId(null);
  };

  const downloadDocument = async (path) => {
    try {
      const { data, error } = await supabase
        .storage
        .from('documentos')
        .download(path);

      if (error) {
        throw error;
      }

      if (!data) {
        throw new Error('File not found');
      }

      const url = URL.createObjectURL(data);
      const a = document.createElement('a');
      a.href = url;
      a.download = path.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading document:', error.message);
    }
  };

  const columns = React.useMemo(() => [
    {
      Header: 'Nombre',
      accessor: 'nombre_cliente',
    },
    {
      Header: 'Apellidos',
      accessor: 'apellidos_cliente',
    },
    {
      Header: 'Nombre del Familiar',
      accessor: 'nombre_familiar',
    },
    {
      Header: 'Apellidos del Familiar',
      accessor: 'apellidos_familiar',
    },
    {
      Header: 'Tipo de Documento',
      accessor: 'tipo_documento',
    },
    {
      Header: 'Estado',
      accessor: 'estado',
    },
    {
      Header: 'Acciones',
      Cell: ({ row }) => (
        <div className="flex space-x-2">
          <button
            onClick={() => openModal(row.original.url_documento, row.original.id)}
            className="p-2 bg-gray-500 text-white rounded hover:bg-gradient-to-r hover:from-purple-500 hover:to-blue-500"
            title="Ver"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
              <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>
          </button>
          <button
            onClick={() => downloadDocument(row.original.url_documento)}
            className="p-2 bg-gray-500 text-white rounded hover:bg-gradient-to-r hover:from-purple-500 hover:to-blue-500"
            title="Descargar"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
            </svg>
          </button>
        </div>
      ),
    },
  ], []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Documentación</h1>
      <Table columns={columns} data={documentos} />
      <Modal
        isOpen={modalOpen}
        onClose={closeModal}
        onApprove={approveDocument}
        onReject={rejectDocument}
        documentUrl={selectedDocument}
      />
      <RejectModal
        isOpen={rejectModalOpen}
        onClose={closeRejectModal}
        onSubmit={handleReject}
      />
    </div>
  );
};

export default Documentacion;
