import React from 'react';

const Modal = ({ isOpen, onClose, onApprove, onReject, documentUrl }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
      <div className="relative w-full h-full">
        <button onClick={onClose} className="absolute top-0 right-0 m-4 p-2 bg-gray-700 text-white rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="w-full h-full flex flex-col">
          <div className="flex-1">
            <iframe src={documentUrl} title="Documento" className="w-full h-full"></iframe>
          </div>
          <div className="p-4 bg-gray-800 flex justify-end space-x-2">
            <button onClick={onClose} className="px-4 py-2 bg-gray-500 text-white rounded">Cerrar</button>
            <button onClick={onReject} className="px-4 py-2 bg-red-500 text-white rounded">Rechazar</button>
            <button onClick={onApprove} className="px-4 py-2 bg-green-500 text-white rounded">Aprobar</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
