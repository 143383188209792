// src/pages/Usuarios/Usuarios.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../../utils/supabaseClient';
import Table from '../../components/Table';
import AddUserModal from '../../components/AddUserModal';
import { Link } from 'react-router-dom';

const Usuarios = () => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchUsuarios = async () => {
      const { data: usuarios, error } = await supabase
        .from('usuarios')
        .select('*');

      if (error) {
        console.error('Error fetching usuarios:', error);
      } else {
        setData(usuarios);
      }
    };

    fetchUsuarios();
  }, []);

  const handleAddUser = (user) => {
    setData([...data, user]);
  };

  const columns = [
    {
      Header: 'Nombre',
      accessor: 'nombre',
      Cell: ({ row }) => (
        <Link to={`/usuarios/${row.original.user_id}`} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
          {row.original.nombre}
        </Link>
      ),
    },
    {
      Header: 'Apellidos',
      accessor: 'apellidos',
    },
    {
      Header: 'Correo',
      accessor: 'email',
    },
    {
      Header: 'DNI',
      accessor: 'dni',
    },
    {
      Header: 'Rol',
      accessor: 'rol',
    },
    {
      Header: 'Colaborador',
      accessor: 'colaborador',
    },
    {
      Header: 'Estado',
      accessor: 'estado',
    },
  ];

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between mb-4">
        <h1 className="text-2xl font-bold">Usuarios</h1>
        <button onClick={() => setIsModalOpen(true)} className="bg-gradient-to-r from-purple-500 to-blue-500 text-white px-4 py-2 rounded-md">
          + Nuevo usuario
        </button>
      </div>
      <Table columns={columns} data={data} />
      <AddUserModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSave={handleAddUser} />
    </div>
  );
};

export default Usuarios;
