import React, { useState } from 'react';

const RejectModal = ({ isOpen, onClose, onSubmit }) => {
  const [motivoRechazo, setMotivoRechazo] = useState('');

  if (!isOpen) return null;

  const handleSubmit = () => {
    onSubmit(motivoRechazo);
    setMotivoRechazo('');
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-xl mb-4">Motivo de Rechazo</h2>
        <textarea
          className="w-full p-2 border border-gray-300 rounded-lg"
          rows="4"
          value={motivoRechazo}
          onChange={(e) => setMotivoRechazo(e.target.value)}
        ></textarea>
        <div className="mt-4 flex justify-end space-x-2">
          <button onClick={onClose} className="px-4 py-2 bg-gray-500 text-white rounded">Cancelar</button>
          <button onClick={handleSubmit} className="px-4 py-2 bg-red-500 text-white rounded">Rechazar</button>
        </div>
      </div>
    </div>
  );
};

export default RejectModal;
