// src/components/Correos.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../utils/supabaseClient';

const Correos = ({ leadId, clienteId }) => {
  const [correos, setCorreos] = useState([]);

  useEffect(() => {
    const fetchCorreos = async () => {
      const { data: correosData } = await supabase
        .from('correos')
        .select('*')
        .or(`lead_id.eq.${leadId},cliente_id.eq.${clienteId}`);

      setCorreos(correosData || []);
    };

    fetchCorreos();
  }, [leadId, clienteId]);

  return (
    <div className="mb-6">
      <h2 className="text-lg font-bold mb-2">Correos</h2>
      <div className="space-y-2">
        {correos.map((correo) => (
          <div key={correo.id} className="bg-gray-100 p-2 rounded-lg shadow-md text-sm">
            <p><strong>Asunto:</strong> {correo.asunto}</p>
            <p><strong>Destinatario:</strong> {correo.destinatario}</p>
            <p>{correo.contenido}</p>
            <p>{new Date(correo.fecha).toLocaleString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Correos;
