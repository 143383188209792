import React, { useEffect, useState } from 'react';
import { supabase } from '../utils/supabaseClient';
import Table from './Table'; // Asegúrate de ajustar la ruta según tu estructura

const HonorariosPagosCuotas = ({ userId }) => {
  const [honorariosData, setHonorariosData] = useState([]);
  const [pagosData, setPagosData] = useState([]);
  const [cuotasData, setCuotasData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isHonorariosExpanded, setHonorariosExpanded] = useState(false);
  const [isPagosExpanded, setPagosExpanded] = useState(false);
  const [isCuotasExpanded, setCuotasExpanded] = useState(false);

  useEffect(() => {
    const fetchHonorariosData = async () => {
      try {
        const { data: honorarios, error: honorariosError } = await supabase
          .from('plan_honorarios')
          .select('total_honorarios, tipo_plan, pago_inicial, pago_mensual, nr_de_cuotas')
          .eq('user_id', userId);

        if (honorariosError) {
          throw honorariosError;
        }

        setHonorariosData(honorarios);

        const { data: pagos, error: pagosError } = await supabase
          .from('pagos')
          .select('cuantia, modalidad_de_pago, fecha')
          .eq('cliente_id', userId);

        if (pagosError) {
          throw pagosError;
        }

        setPagosData(pagos);

        const { data: cuotas, error: cuotasError } = await supabase
          .from('cuotas')
          .select('cuantia, fecha_prevista, estado')
          .eq('cliente_id', userId);

        if (cuotasError) {
          throw cuotasError;
        }

        setCuotasData(cuotas);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchHonorariosData();
  }, [userId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const totalPagado = pagosData.reduce((sum, pago) => sum + pago.cuantia, 0);

  const updatedHonorariosData = honorariosData.map(item => ({
    ...item,
    total_pagado: totalPagado,
    falta_por_pagar: item.total_honorarios - totalPagado,
  }));

  const honorariosColumns = [
    { Header: 'Total Honorarios', accessor: 'total_honorarios' },
    { Header: 'Tipo de Plan', accessor: 'tipo_plan' },
    { Header: 'Pago Inicial', accessor: 'pago_inicial' },
    { Header: 'Pago Mensual', accessor: 'pago_mensual' },
    { Header: 'Número de Cuotas', accessor: 'nr_de_cuotas' },
    { Header: 'Total Pagado', accessor: 'total_pagado' },
    { Header: 'Falta por Pagar', accessor: 'falta_por_pagar' },
  ];

  const pagosColumns = [
    { Header: 'Fecha', accessor: d => new Date(d.fecha).toLocaleDateString() },
    { Header: 'Cuantía', accessor: 'cuantia' },
    { Header: 'Modalidad de Pago', accessor: 'modalidad_de_pago' },
  ];

  const cuotasColumns = [
    { Header: 'Fecha Prevista', accessor: d => new Date(d.fecha_prevista).toLocaleDateString() },
    { Header: 'Cuantía', accessor: 'cuantia' },
    { Header: 'Estado', accessor: 'estado' },
  ];

  return (
    <div className="mt-6">
      <h2 className="text-xl font-semibold mb-4">Honorarios, Pagos y Cuotas</h2>
      
      <div className="flex items-center justify-between mb-2">
        <h3 className="text-lg font-semibold">Honorarios</h3>
        <button onClick={() => setHonorariosExpanded(!isHonorariosExpanded)}>
          {isHonorariosExpanded ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
            </svg>
          )}
        </button>
      </div>
      {isHonorariosExpanded && <Table columns={honorariosColumns} data={updatedHonorariosData} />}
      
      <div className="flex items-center justify-between mt-6 mb-2">
        <h3 className="text-lg font-semibold">Pagos</h3>
        <button onClick={() => setPagosExpanded(!isPagosExpanded)}>
          {isPagosExpanded ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
            </svg>
          )}
        </button>
      </div>
      {isPagosExpanded && <Table columns={pagosColumns} data={pagosData} />}

      <div className="flex items-center justify-between mt-6 mb-2">
        <h3 className="text-lg font-semibold">Cuotas</h3>
        <button onClick={() => setCuotasExpanded(!isCuotasExpanded)}>
          {isCuotasExpanded ? (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
            </svg>
          )}
        </button>
      </div>
      {isCuotasExpanded && <Table columns={cuotasColumns} data={cuotasData} />}
    </div>
  );
};

export default HonorariosPagosCuotas;
