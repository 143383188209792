// src/components/Llamadas.js
import React, { useEffect, useState } from 'react';

import { 
  faPhone, 
  faPhoneVolume,
  faPlay, 
  faFileLines 
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { toast } from 'react-toastify';

const Llamadas = ({ leadId, clienteId, telefono }) => {
  const [llamadas, setLlamadas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transcripcionVisible, setTranscripcionVisible] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedCards, setExpandedCards] = useState(new Set());

  useEffect(() => {
    const fetchLlamadas = async () => {
      setLoading(true);
      try {
        const response = await axios.get('https://backend.codigobuenaspracticas.es/api/aircall/calls', {
          params: {
            from: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString(),
            to: new Date().toISOString()
          }
        });
        setLlamadas(response.data);
      } catch (error) {
        console.error('Error al obtener llamadas:', error);
        toast.error('Error al cargar las llamadas');
        setLlamadas([]);
      } finally {
        setLoading(false);
      }
    };

    fetchLlamadas();
  }, [leadId, clienteId]);

  const getTipoLlamadaIcon = (tipo) => {
    return tipo === 'entrante' ? 
      <FontAwesomeIcon icon={faPhone} className="text-green-500" /> : 
      <FontAwesomeIcon icon={faPhoneVolume} className="text-blue-500" />;
  };

  const toggleTranscripcion = (id) => {
    setTranscripcionVisible(transcripcionVisible === id ? null : id);
  };

  const filteredLlamadas = llamadas.filter((llamada) => {
    const term = searchTerm.toLowerCase();
    return (
      (telefono && (llamada.telefono_emisor === telefono || llamada.telefono_receptor === telefono)) &&
      (llamada.tipo.toLowerCase().includes(term) ||
      llamada.usuario_emisor?.nombre.toLowerCase().includes(term) ||
      llamada.usuario_receptor?.nombre.toLowerCase().includes(term) ||
      llamada.fecha.toLocaleDateString().includes(term))
    );
  });

  const toggleCardExpansion = (id) => {
    setExpandedCards(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  return (
    <div className="mb-6">
      <h2 className="text-lg font-bold mb-4">Historial de Llamadas</h2>
      <input
        type="text"
        placeholder="Buscar por tipo, usuario o fecha"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 p-2 border border-gray-300 rounded"
      />
      
      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
        </div>
      ) : (
        <div className="space-y-4">
          {filteredLlamadas.map((llamada) => (
            <div key={llamada.id} className="bg-white p-4 rounded-lg shadow-md border border-gray-200">
              <div
                className="flex items-center justify-between mb-2 cursor-pointer"
                onClick={() => toggleCardExpansion(llamada.id)}
              >
                <div className="flex items-center gap-2">
                  {getTipoLlamadaIcon(llamada.tipo)}
                  <span className="font-medium">
                    {llamada.tipo === 'entrante' ? 'Llamada entrante' : 'Llamada saliente'}
                  </span>
                </div>
                <span className="text-sm text-gray-500">
                  {new Date(llamada.fecha).toLocaleString()}
                </span>
                <span className="text-sm text-blue-600 hover:text-blue-800">
                  {expandedCards.has(llamada.id) ? 'Contraer' : 'Expandir'}
                </span>
              </div>

              {expandedCards.has(llamada.id) && (
                <>
                  <div className="grid grid-cols-2 gap-4 mb-3 text-sm">
                    <div>
                      <p className="text-gray-600">De:</p>
                      <p>{llamada.usuario_emisor?.nombre || llamada.telefono_emisor}</p>
                    </div>
                    <div>
                      <p className="text-gray-600">Para:</p>
                      <p>{llamada.usuario_receptor?.nombre || llamada.telefono_receptor}</p>
                    </div>
                  </div>

                  {llamada.descripcion && (
                    <div className="mb-3">
                      <p className="text-gray-600 mb-1">Descripción:</p>
                      <p className="text-sm">{llamada.descripcion}</p>
                    </div>
                  )}

                  {llamada.resumen && (
                    <div className="mb-3">
                      <p className="text-gray-600 mb-1">Resumen AI:</p>
                      <p className="text-sm bg-gray-50 p-2 rounded">{llamada.resumen}</p>
                    </div>
                  )}

                  <div className="flex gap-3 mt-3">
                    {llamada.url_grabacion && (
                      <div className="flex items-center gap-1">
                        <FontAwesomeIcon icon={faPlay} className="text-xs" />
                        <audio controls className="w-full">
                          <source src={llamada.url_grabacion} type="audio/mpeg" />
                          Tu navegador no soporta el elemento de audio.
                        </audio>
                      </div>
                    )}
                    {llamada.transcripcion && (
                      <button
                        className="flex items-center gap-1 text-sm text-blue-600 hover:text-blue-800"
                        onClick={(e) => {
                          e.stopPropagation(); // Evitar que el clic en el botón afecte al título
                          toggleTranscripcion(llamada.id);
                        }}
                      >
                        <FontAwesomeIcon icon={faFileLines} className="text-xs" />
                        {transcripcionVisible === llamada.id ? 'Ocultar transcripción' : 'Ver transcripción'}
                      </button>
                    )}
                  </div>

                  {transcripcionVisible === llamada.id && llamada.transcripcion && (
                    <div className="mt-3 p-2 bg-gray-100 rounded">
                      <p className="text-sm">{llamada.transcripcion}</p>
                    </div>
                  )}
                </>
              )}
            </div>
          ))}
          
          {llamadas.length === 0 && (
            <div className="text-center py-8 text-gray-500">
              No hay llamadas registradas
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Llamadas;
