import React, { useState, useEffect, useRef } from 'react';
import { createClient } from '@supabase/supabase-js';
import documentacionConfig from '../../data/documentacionConfig';
import { TableCellsIcon, EyeIcon, PlusIcon } from '@heroicons/react/24/outline';
import { FaFileAlt, FaFileInvoice, FaHome, FaUser } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import Select from 'react-select';
import axios from 'axios';

import 'jspdf-autotable';
import CreatableSelect from 'react-select/creatable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPhone, 
  faPhoneVolume, 
  faPlay, 
  faFileLines 
} from '@fortawesome/free-solid-svg-icons';


const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

// Mover la definición del DocumentosEnvioModal antes de los otros componentes
const DocumentosEnvioModal = ({ isOpen, onClose, envio, onDownload }) => {
    if (!isOpen || !envio) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose}></div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
                    <div className="absolute top-0 right-0 pt-4 pr-4">
                        <button
                            onClick={onClose}
                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                        >
                            <span className="sr-only">Cerrar</span>
                            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                                Documentos enviados - {new Date(envio.fecha_envio).toLocaleString('es-ES')}
                            </h3>
                            <div className="mt-4 max-h-[60vh] overflow-y-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Documento
                                            </th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Acciones
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {envio.documentos && Object.entries(envio.documentos).map(([index, doc]) => (
                                            <tr key={index}>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                                    {doc.nombre}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <button
                                                        onClick={() => onDownload(doc.url, doc.nombre)}
                                                        className="text-purple-600 hover:text-purple-900 flex items-center"
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                                                            <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                                                        </svg>
                                                        Descargar
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const EnviarGestor = ({ leadId }) => {
    const [activeTab, setActiveTab] = useState('documentos');
    const [loading, setLoading] = useState(false);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [success, setSuccess] = useState(false);
    const [documentosSubidos, setDocumentosSubidos] = useState({});
    const [familiares, setFamiliares] = useState([]);
    const [selectedFamiliar, setSelectedFamiliar] = useState(null);
    const [uploadProgress, setUploadProgress] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [formData, setFormData] = useState({
        nombregestor: '',
        emailgestor: '',
        emailsAdicionales: [], // Inicializar como array vacío
        telefonogestor: '',
        mensajePersonalizado: ''
    });
    const [envios, setEnvios] = useState([]);
   const [expandedCategories, setExpandedCategories] = useState({});
    const [showAddDocModal, setShowAddDocModal] = useState(false);
    const [documentosExtras, setDocumentosExtras] = useState([]);
    const [nuevoDocumento, setNuevoDocumento] = useState('');
    const [documentosDisponibles, setDocumentosDisponibles] = useState([]);
    const [categorias, setCategorias] = useState({});
    const [gestorData, setGestorData] = useState(null);
    const [showGestorForm, setShowGestorForm] = useState(false);
    const [activeMainTab, setActiveMainTab] = useState('documentos');
    const [activeHistorialTab, setActiveHistorialTab] = useState('enviar');
    const [loadingDocumentacion, setLoadingDocumentacion] = useState({});
    const [sessionInProgress, setSessionInProgress] = useState({});
    const [selectedEnvio, setSelectedEnvio] = useState(null);
    const [showDocumentosModal, setShowDocumentosModal] = useState(false);
    const [showProgressModal, setShowProgressModal] = useState(false);
    const [sendingProgress, setSendingProgress] = useState(0);
    const [sendingStatus, setSendingStatus] = useState('');

    const DEFAULT_DOCUMENTS = [
        ...documentacionConfig.requisitos_generales.doc_hacienda_y_seguridad_social,
        ...documentacionConfig.requisitos_generales.documentacion_personal
    ];

    const normalizedDocumentacionConfig = {
        ...documentacionConfig,
        documentacion_ingresos: Object.fromEntries(
            Object.entries(documentacionConfig.documentacion_ingresos).map(
                ([key, value]) => [key.toLowerCase(), value]
            )
        )
    };
    useEffect(() => {
        const fetchFamiliares = async () => {
            try {
                const { data: familiaresData, error: familiaresError } = await supabase
                    .from('familiares')
                    .select(`
                        id,
                        cliente_id,
                        nombre,
                        apellidos,
                        dni,
                        tipoIngreso,
                        ingresosBrutosAnuales,
                        ingresosNetosAnuales,
                        relacion,
                        domicilio,
                        estado_civil,
                        familia_monoparental,
                        victima_violencia,
                        grado_discapacidad
                    `)
                    .eq('cliente_id', leadId);
    
                if (familiaresError) {
                    console.error('Error fetching familiares:', familiaresError);
                    toast.error('Error al cargar los familiares');
                    return;
                }
    
                if (familiaresData && familiaresData.length > 0) {
                    setFamiliares(familiaresData);
                    setSelectedFamiliar(familiaresData[0]);
                    setActiveTab(familiaresData[0].id);
                }
            } catch (error) {
                console.error('Error unexpected:', error);
                toast.error('Error inesperado al cargar los datos');
            }
        };
    
        if (leadId) {
            fetchFamiliares();
        }
    }, [leadId]);

    useEffect(() => {
        const fetchDocumentos = async () => {
            if (!selectedFamiliar) {
                console.log('No hay familiar seleccionado');
                return;
            }
            
            try {
                const { data: documentosData, error } = await supabase
                    .from('documentos')
                    .select('*')
                    .eq('familiar_id', selectedFamiliar.id)
                    .eq('cliente_id', leadId)
                    .order('created_at', { ascending: false });

                if (error) throw error;

                const documentosUnicos = documentosData.reduce((acc, doc) => {
                    if (!acc[doc.tipo_documento] || 
                        new Date(doc.created_at) > new Date(acc[doc.tipo_documento].created_at)) {
                        acc[doc.tipo_documento] = doc;
                    }
                    return acc;
                }, {});

                const documentosFormateados = {
                    [selectedFamiliar.id]: {}
                };

                // Mantener los documentos extras existentes
                const documentosExtrasList = [...documentosExtras];

                for (const doc of Object.values(documentosUnicos)) {
                    let signedUrl = null;
                    if (doc.url_documento) {
                        signedUrl = await getSignedUrl(doc.url_documento);
                    }
                    
                    documentosFormateados[selectedFamiliar.id][doc.tipo_documento] = {
                        nombre: doc.tipo_documento,
                        url: signedUrl,
                        estado: doc.estado || 'pendiente',
                        id: doc.id,
                        motivo_rechazo: doc.motivo_rechazo,
                        url_documento: doc.url_documento,
                        created_at: doc.created_at
                    };

                    // Verificar si es un documento extra
                    const isInPredefinedDocs = DEFAULT_DOCUMENTS.includes(doc.tipo_documento) ||
                        Object.values(documentacionConfig.documentacion_ingresos)
                            .flatMap(cat => [...(cat.doc_hacienda_y_seguridad_social || []), ...(cat.documentacion_personal || [])])
                            .includes(doc.tipo_documento) ||
                        Object.values(documentacionConfig.documentacion_situacion_personal)
                            .flatMap(cat => cat.documentacion_personal)
                            .includes(doc.tipo_documento) ||
                        [...documentacionConfig.documentacion_relacion_titular1.doc_hacienda_y_seguridad_social,
                         ...documentacionConfig.documentacion_relacion_titular1.documentacion_personal]
                            .includes(doc.tipo_documento);

                    if (!isInPredefinedDocs && !documentosExtrasList.includes(doc.tipo_documento)) {
                        documentosExtrasList.push(doc.tipo_documento);
                    }
                }

                setDocumentosSubidos(prev => ({
                    ...prev,
                    ...documentosFormateados
                }));

                // Actualizar documentos extras solo si hay cambios reales
                if (JSON.stringify(documentosExtrasList.sort()) !== JSON.stringify(documentosExtras.sort())) {
                    setDocumentosExtras(documentosExtrasList);
                }

            } catch (error) {
                console.error('Error al cargar documentos:', error);
                toast.error('Error al cargar los documentos');
            }
        };

        if (selectedFamiliar?.id && leadId) {
            fetchDocumentos();
        }
    }, [selectedFamiliar?.id, leadId, documentosExtras]);

    useEffect(() => {
        if (selectedFamiliar) {
            const documentosNecesarios = getRequiredDocuments(selectedFamiliar);
            const categoriasTemp = {
                "Documentos Personales": documentosNecesarios.filter(doc => 
                    documentacionConfig.requisitos_generales.documentacion_personal.includes(doc)),
                "Documentos Hacienda": documentosNecesarios.filter(doc => 
                    documentacionConfig.requisitos_generales.doc_hacienda_y_seguridad_social.includes(doc)),
                "Autorización y Declaraciones": documentosNecesarios.filter(doc => 
                    documentacionConfig.requisitos_generales.autorizaciones.includes(doc)),
                "Documentos de Ingresos": documentosNecesarios.filter(doc => 
                    Object.values(documentacionConfig.documentacion_ingresos)
                        .flatMap(cat => [...(cat.doc_hacienda_y_seguridad_social || []), ...(cat.documentacion_personal || [])])
                        .includes(doc)),
                "Documentos de Situación Personal": documentosNecesarios.filter(doc => 
                    Object.values(documentacionConfig.documentacion_situacion_personal)
                        .flatMap(cat => cat.documentacion_personal)
                        .includes(doc)),
                "Documentos de Titular Principal": documentosNecesarios.filter(doc => 
                    [...documentacionConfig.documentacion_relacion_titular1.doc_hacienda_y_seguridad_social,
                     ...documentacionConfig.documentacion_relacion_titular1.documentacion_personal].includes(doc))
            };
            
            // Siempre incluir la categoría de documentos extras si hay documentos
            if (documentosExtras && documentosExtras.length > 0) {
                categoriasTemp["Documentos Extras"] = documentosExtras;
            }
            
            setCategorias(categoriasTemp);
        }
    }, [selectedFamiliar, documentosExtras]);

    useEffect(() => {
        // Crear lista de todos los documentos disponibles
        const todosDocumentos = [
            ...documentacionConfig.requisitos_generales.doc_hacienda_y_seguridad_social,
            ...documentacionConfig.requisitos_generales.documentacion_personal,
            ...documentacionConfig.requisitos_generales.autorizaciones,
            ...Object.values(documentacionConfig.documentacion_ingresos)
                .flatMap(cat => [...(cat.doc_hacienda_y_seguridad_social || []), ...(cat.documentacion_personal || [])]),
            ...Object.values(documentacionConfig.documentacion_situacion_personal)
                .flatMap(cat => cat.documentacion_personal),
            ...documentacionConfig.documentacion_relacion_titular1.doc_hacienda_y_seguridad_social,
            ...documentacionConfig.documentacion_relacion_titular1.documentacion_personal
        ];
        setDocumentosDisponibles([...new Set(todosDocumentos)]);
    }, []);

    useEffect(() => {
        const fetchGestorData = async () => {
            try {
                const { data: leadData, error: leadError } = await supabase
                    .from('leads')
                    .select(`
                        id,
                        nr_titulares,
                        miembros_familias,
                        pagoMensualHipoteca,
                        cantidadPorPagar,
                        precioVivienda,
                        fecha_vencimiento,
                        bancoHipoteca,
                        interesAnual,
                        cuotaJunio2022,
                        cuotaHace4Anos,
                        plazoOriginalHipoteca,
                        anosFaltantes,
                        ingresosHace4Anos,
                        mayor60,
                        dificultadesPago,
                        Carencia,
                        NuevaCuota
                    `)
                    .eq('id', leadId)
                    .single();

                if (leadError) throw leadError;

                // Luego obtener datos del gestor
                const { data: clienteData, error } = await supabase
                    .from('clientes')
                    .select(`
                        gestor_nombre,
                        gestor_apellidos,
                        gestor_email,
                        gestor_telefono,
                        gestor_direccion
                    `)
                    .eq('id', leadId)
                    .single();

                if (error) throw error;

                if (clienteData) {
                    // Generar mensaje por defecto usando leadData
                    const mensajeDefault = await generateEmailHtml(familiares, leadData);
                    
                    setFormData({
                        nombregestor: clienteData.gestor_nombre || '',
                        emailgestor: clienteData.gestor_email || '',
                        telefonogestor: clienteData.gestor_telefono || '',
                        mensajePersonalizado: mensajeDefault || ''
                    });
                }
            } catch (error) {
                console.error('Error al cargar datos:', error);
                toast.error('Error al cargar datos del gestor');
            }
        };

        if (leadId) {
            fetchGestorData();
        }
    }, [leadId, familiares]);

    const fetchEnvios = async () => {
        try {
            const { data, error } = await supabase
                .from('envios_documentacion')
                .select('*')
                .eq('lead_id', leadId)
                .order('fecha_envio', { ascending: false });

            if (error) throw error;
            setEnvios(data || []);
        } catch (error) {
            console.error('Error al obtener envíos:', error);
            toast.error('Error al cargar el historial de envíos');
        }
    };

    useEffect(() => {
        if (leadId) {
            fetchEnvios();
        }
    }, [leadId]);

    const getRequiredDocuments = (familiar) => {
        let documentosNecesarios = [
            ...documentacionConfig.requisitos_generales.doc_hacienda_y_seguridad_social,
            ...documentacionConfig.requisitos_generales.documentacion_personal,
            ...documentacionConfig.requisitos_generales.autorizaciones
        ];

        // Documentos según tipo de ingreso
        if (familiar?.tipoIngreso) {
            const tipoIngreso = familiar.tipoIngreso.toLowerCase().trim();
            
            // Si el tipo de ingreso es nómina, incluir todos los documentos de nómina
            if (tipoIngreso === 'nomina' || tipoIngreso === 'nómina') {
                documentosNecesarios.push(
                    ...documentacionConfig.documentacion_ingresos["Nomina del ultimo mes"].documentacion_personal,
                    ...documentacionConfig.documentacion_ingresos["Nomina de hace dos meses"].documentacion_personal,
                    ...documentacionConfig.documentacion_ingresos["Nomina de hace tres meses"].documentacion_personal
                );
            }
            // Si es autónomo, incluir todas las facturas
            else if (tipoIngreso === 'autonomo' || tipoIngreso === 'autónomo') {
                documentosNecesarios.push(
                    ...documentacionConfig.documentacion_ingresos["Factura del ultimo mes"].documentacion_personal,
                    ...documentacionConfig.documentacion_ingresos["Factura de hace dos meses"].documentacion_personal,
                    ...documentacionConfig.documentacion_ingresos["Factura de hace tres meses"].documentacion_personal
                );
            }
            // Para otros tipos de ingreso
            else {
                const tipoIngresoMapped = {
                    'pension': 'Pension',
                    'pensión': 'Pension',
                    'paro': 'Prestacion desempleo',
                    'desempleo': 'Prestacion desempleo',
                    'ayuda': 'Ayuda'
                }[tipoIngreso] || tipoIngreso;

                if (documentacionConfig.documentacion_ingresos[tipoIngresoMapped]) {
                    const docIngresos = documentacionConfig.documentacion_ingresos[tipoIngresoMapped];
                    if (docIngresos.doc_hacienda_y_seguridad_social) {
                        documentosNecesarios.push(...docIngresos.doc_hacienda_y_seguridad_social);
                    }
                    if (docIngresos.documentacion_personal) {
                        documentosNecesarios.push(...docIngresos.documentacion_personal);
                    }
                }
            }
        }

        // Resto del código para otros tipos de documentos...
        // (mantener el código existente para documentos de titular1 y situación personal)

        return [...new Set(documentosNecesarios)];
    };
    const DocumentModal = ({ isOpen, onClose, document }) => {
        const [showRejectionModal, setShowRejectionModal] = useState(false);
        const [rejectionReason, setRejectionReason] = useState('');

        if (!isOpen || !document) return null;

        const handleApprove = () => {
            actualizarEstadoDocumento(document.id, 'aprobado');
            onClose();
        };

        const handleReject = () => {
            setShowRejectionModal(true);
        };

        const handleConfirmReject = () => {
            actualizarEstadoDocumento(document.id, 'rechazado', rejectionReason);
            setShowRejectionModal(false);
            onClose();
        };

        return (
            <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={onClose}></div>

                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full sm:p-6">
                        <div className="absolute top-0 right-0 pt-4 pr-4">
                            <button
                                type="button"
                                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                                onClick={onClose}
                            >
                                <span className="sr-only">Cerrar</span>
                                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>

                        <div className="mb-4">
                            <span className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${getStatusClass(document.estado)}`}>
                                <span className="w-2 h-2 mr-2 rounded-full bg-current"></span>
                                {document.estado}
                            </span>
                            {document.motivo_rechazo && (
                                <div className="mt-2 text-sm text-red-600">
                                    Motivo de rechazo: {document.motivo_rechazo}
                                </div>
                            )}
                        </div>

                        <div className="h-[80vh] w-full mb-4">
                            <iframe
                                src={document.url}
                                className="w-full h-full"
                                title="Vista previa del documento"
                            />
                        </div>

                        <div className="mt-4 flex justify-end space-x-3">
                            <button
                                onClick={handleApprove}
                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-transparent rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            >
                                Aprobar
                            </button>
                            <button
                                onClick={handleReject}
                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            >
                                Rechazar
                            </button>
                        </div>
                    </div>
                </div>

                {showRejectionModal && (
                    <div className="fixed inset-0 z-50 overflow-y-auto">
                        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

                            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                                            Motivo del rechazo
                                        </h3>
                                        <div className="mt-2">
                                            <textarea
                                                rows={4}
                                                className="shadow-sm block w-full focus:ring-red-500 focus:border-red-500 sm:text-sm border border-gray-300 rounded-md"
                                                value={rejectionReason}
                                                onChange={(e) => setRejectionReason(e.target.value)}
                                                placeholder="Ingrese el motivo del rechazo..."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={handleConfirmReject}
                                    >
                                        Confirmar rechazo
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={() => setShowRejectionModal(false)}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const handleDocumentoChange = async (e, documentoId) => {
        const file = e.target.files[0];
        if (!file) return;

        setLoadingUpload(true);
        const toastId = toast.loading(`Subiendo ${documentoId}...`);

        try {
            const fileExtension = file.name.split('.').pop();
            // Sanitizar el nombre del documento eliminando espacios y caracteres especiales
            const sanitizedDocId = documentoId
                .toLowerCase()
                .replace(/[áéíóúñ]/g, x => ({ á: 'a', é: 'e', í: 'i', ó: 'o', ú: 'u', ñ: 'n' })[x])
                .replace(/[^a-z0-9]/g, '_');
            const fileName = `${sanitizedDocId}.${fileExtension}`;
            const filePath = `${leadId}/${selectedFamiliar.id}/${fileName}`;

            // Subir archivo al storage
            const { error: storageError } = await supabase
                .storage
                .from('documentos')
                .upload(filePath, file, {
                    upsert: true
                });

            if (storageError) throw storageError;

            // Obtener URL firmada
            const signedUrl = await getSignedUrl(filePath);

            // Insertar en la tabla documentos
            const { data: documentoData, error: documentoError } = await supabase
                .from('documentos')
                .upsert({
                    cliente_id: leadId,
                    familiar_id: selectedFamiliar.id,
                    tipo_documento: documentoId,
                    url_documento: filePath, // Guardamos el path en lugar de la URL
                    estado: 'aprobado',
                    created_at: new Date().toISOString()
                })
                .select()
                .single();

            if (documentoError) throw documentoError;

            // Actualizar estado local
            setDocumentosSubidos(prev => ({
                ...prev,
                [selectedFamiliar.id]: {
                    ...prev[selectedFamiliar.id],
                    [documentoId]: {
                        nombre: fileName,
                        url: signedUrl,
                        estado: 'aprobado',
                        id: documentoData.id
                    }
                }
            }));

            toast.update(toastId, {
                render: `${documentoId} subido correctamente`,
                type: "success",
                isLoading: false,
                autoClose: 5000
            });
        } catch (error) {
            console.error('Error al subir documento:', error);
            toast.update(toastId, {
                render: `Error al subir ${documentoId}: ${error.message}`,
                type: "error",
                isLoading: false,
                autoClose: 5000
            });
        } finally {
            setLoadingUpload(false);
            // Limpiar progreso
            setUploadProgress(prev => ({ ...prev, [documentoId]: 100 }));
        }
    };

    const generateAndUploadZip = async (documentosSubidos) => {
        try {
            const MAX_ZIP_SIZE = 50 * 1024 * 1024; // 50MB máximo por archivo
            const zip = new JSZip();
            const documentsToZip = documentosSubidos[selectedFamiliar.id] || {};
            let currentSize = 0;
            let zipIndex = 1;
            let currentZip = new JSZip();

            // Opciones de compresión
            const compressionOptions = {
                type: "blob",
                compression: "DEFLATE", // Método de compresión
                compressionOptions: {
                    level: 9 // Nivel máximo de compresión (1-9)
                }
            };

            if (Object.keys(documentsToZip).length === 0) {
                throw new Error('No hay documentos para generar el ZIP');
            }

            for (const [docName, doc] of Object.entries(documentsToZip)) {
                try {
                    if (!doc.url) {
                        console.warn(`Documento ${docName} no tiene URL`);
                        continue;
                    }

                    const response = await fetch(doc.url);
                    if (!response.ok) {
                        console.warn(`Error al obtener documento ${docName}: ${response.statusText}`);
                        continue;
                    }

                    const blob = await response.blob();
                    
                    // Comprimir el archivo individual si es posible
                    let compressedBlob = blob;
                    if (blob.type.includes('image')) {
                        compressedBlob = await compressImage(blob);
                    }

                    const fileSize = compressedBlob.size;

                    if (currentSize + fileSize > MAX_ZIP_SIZE) {
                        await uploadZipPart(currentZip, zipIndex, compressionOptions);
                        currentZip = new JSZip();
                        currentSize = 0;
                        zipIndex++;
                    }

                    const sanitizedDocName = docName
                        .toLowerCase()
                        .replace(/[áéíóúñ]/g, x => ({ á: 'a', é: 'e', í: 'i', ó: 'o', ú: 'u', ñ: 'n' })[x])
                        .replace(/[^a-z0-9]/g, '_');
                    
                    currentZip.file(sanitizedDocName, compressedBlob, {
                        compression: "DEFLATE",
                        compressionOptions: {
                            level: 9
                        }
                    });
                    
                    currentSize += fileSize;

                } catch (error) {
                    console.error(`Error procesando documento ${docName}:`, error);
                    continue;
                }
            }

            if (Object.keys(currentZip.files).length > 0) {
                return await uploadZipPart(currentZip, zipIndex, compressionOptions);
            }

            throw new Error('No se pudieron procesar los documentos');

        } catch (error) {
            console.error('Error al generar y subir ZIP:', error);
            toast.error('Error al generar y subir el archivo ZIP');
            throw error;
        }
    };

    // Función para comprimir imágenes
    const compressImage = async (blob) => {
        return new Promise((resolve) => {
            if (!blob.type.startsWith('image/')) {
                resolve(blob);
                return;
            }

            const img = new Image();
            img.src = URL.createObjectURL(blob);

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Calcular nuevas dimensiones manteniendo la proporción
                let width = img.width;
                let height = img.height;
                const MAX_WIDTH = 1920;
                const MAX_HEIGHT = 1080;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                        height = Math.round((height * MAX_WIDTH) / width);
                        width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                        width = Math.round((width * MAX_HEIGHT) / height);
                        height = MAX_HEIGHT;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(
                    (blob) => {
                        URL.revokeObjectURL(img.src);
                        resolve(blob);
                    },
                    'image/jpeg',
                    0.7 // Calidad de la compresión (0-1)
                );
            };
        });
    };

    // Función auxiliar para subir una parte del ZIP
    const uploadZipPart = async (zip, index, compressionOptions) => {
        const zipBlob = await zip.generateAsync(compressionOptions);
        const zipFileName = `documentos_${selectedFamiliar?.nombre || 'default'}_parte${index}_${Date.now()}.zip`;
        const file = new File([zipBlob], zipFileName, { type: 'application/zip' });

        const { data, error } = await supabase
            .storage
            .from('documentos')
            .upload(`zips/${leadId}/${selectedFamiliar.id}/${zipFileName}`, file);

        if (error) throw error;

        const { data: signedUrlData, error: signedUrlError } = await supabase
            .storage
            .from('documentos')
            .createSignedUrl(`zips/${leadId}/${selectedFamiliar.id}/${zipFileName}`, 60 * 60 * 24 * 7);

        if (signedUrlError) throw signedUrlError;

        return {
            zipUrl: signedUrlData.signedUrl,
            zipFileName: zipFileName
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setShowProgressModal(true);
        setSendingProgress(0);
        setSendingStatus('Iniciando envío...');

        try {
            // Validar datos del formulario
            if (!formData.emailgestor || !formData.nombregestor) {
                throw new Error('El nombre y email del gestor son obligatorios');
            }

            // Encontrar el primer titular
            const primerTitular = familiares.find(f => 
                f.relacion?.toLowerCase() === 'titular1' || 
                f.relacion?.toLowerCase() === 'titular'
            );

            if (!primerTitular) {
                throw new Error('No se encontró el titular principal');
            }

            // Preparar los enlaces a documentos
            setSendingStatus('Preparando documentos...');
            setSendingProgress(30);
            
            // Recolectar documentos aprobados de todos los familiares
            let todosLosDocumentos = [];
            for (const familiarId in documentosSubidos) {
                const documentosFamiliar = documentosSubidos[familiarId];
                const familiar = familiares.find(f => f.id === familiarId);
                
                for (const [nombreDoc, doc] of Object.entries(documentosFamiliar)) {
                    if (doc.estado === 'aprobado' && doc.url) {
                        todosLosDocumentos.push({
                            nombre: `${familiar.nombre}_${familiar.apellidos}_${nombreDoc}`,
                            url: doc.url
                        });
                    }
                }
            }

            if (todosLosDocumentos.length === 0) {
                throw new Error('No hay documentos aprobados para enviar');
            }

            // Preparar destinatarios
            const destinatarios = [
                formData.emailgestor,
                ...(formData.emailsAdicionales?.map(email => email.value) || [])
            ].filter(Boolean);

            setSendingProgress(60);
            setSendingStatus('Enviando documentación...');

            // Enviar email con documentos
            const response = await axios.post('https://backend.codigobuenaspracticas.es/api/email/send-with-documents', {
                to: destinatarios,
                subject: `Documentación de ${primerTitular.nombre} ${primerTitular.apellidos}`,
                text: formData.mensajePersonalizado,
                documentLinks: todosLosDocumentos
            });

            if (!response.data.success) {
                throw new Error(response.data.message || 'Error al enviar la documentación');
            }

            // Registrar el envío en la base de datos
            setSendingProgress(80);
            setSendingStatus('Registrando envío...');
            
            const { error: supabaseError } = await supabase
                .from('envios_documentacion')
                .insert({
                    lead_id: leadId,
                    gestor_nombre: formData.nombregestor,
                    gestor_email: formData.emailgestor,
                    gestor_telefono: formData.telefonogestor,
                    emails_adicionales: formData.emailsAdicionales?.map(email => email.value),
                    fecha_envio: new Date().toISOString(),
                    estado: 'enviado',
                    documentos: documentosSubidos,
                    mensaje: formData.mensajePersonalizado
                });

            if (supabaseError) throw supabaseError;

            setSendingProgress(100);
            setSendingStatus('¡Envío completado!');
            
            setTimeout(() => {
                setShowProgressModal(false);
                toast.success('Documentación enviada al gestor exitosamente');
                fetchEnvios();
            }, 1000);

        } catch (error) {
            console.error('Error:', error);
            setSendingStatus('Error: ' + error.message);
            toast.error(`Error al enviar la documentación: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const generateEmailHtml = (familiares, leadData) => {
        const formatNumber = (num) => {
            if (!num) return '0 €';
            return new Intl.NumberFormat('es-ES', { 
                style: 'currency', 
                currency: 'EUR' 
            }).format(num);
        };

        // Función para obtener el texto de carencia
        const getCarenciaText = (carencia) => {
            switch(carencia) {
                case 'carencia5':
                    return 'Periodo carencia 5 años';
                case 'carencia2':
                    return 'Periodo carencia 2 años';
                case 'carencia1':
                    return 'Periodo carencia 1 año';
                default:
                    return 'Sin periodo de carencia';
            }
        };

        const titulares = familiares.filter(f => f.relacion?.toLowerCase().includes('titular'));
        const otrosMiembros = familiares.filter(f => !f.relacion?.toLowerCase().includes('titular'));

        return `
Buenas tardes,

Soy Fina Salazar, socia y abogada de Código Buenas Prácticas (Salatan Abogados SL).
Por la presente les hago llegar la solicitud de acogida al Código de Buenas Prácticas con la documentación completa de mis mandantes.
Aquí tienen un resumen de los datos de la solicitud:

Datos generales
--------------
Número de titulares: ${leadData.nr_titulares}
Miembros unidad familiar: ${leadData.miembros_familias}

Datos de la hipoteca
-------------
Cuota actual: ${formatNumber(leadData.pagoMensualHipoteca)}
Capital pendiente: ${formatNumber(leadData.cantidadPorPagar)}
Valor tasación: ${formatNumber(leadData.precioVivienda)}
Fecha vencimiento: ${new Date(leadData.fecha_vencimiento).toLocaleDateString('es-ES')}
Banco: ${leadData.bancoHipoteca || 'No especificado'}
Interés anual: ${leadData.interesAnual ? leadData.interesAnual + '%' : 'No especificado'}
Cuota Junio 2022: ${formatNumber(leadData.cuotaJunio2022)}
Cuota hace 4 años: ${formatNumber(leadData.cuotaHace4Anos)}
Plazo original: ${leadData.plazoOriginalHipoteca || 'No especificado'} años
Años faltantes: ${leadData.anosFaltantes || 'No especificado'}

Solicitud de carencia
-------------
${getCarenciaText(leadData.Carencia)}
${leadData.NuevaCuota ? `Nueva cuota durante la carencia: ${formatNumber(leadData.NuevaCuota)}` : ''}

Titulares
---------
${titulares.map(titular => `
Nombre: ${titular.nombre} ${titular.apellidos}
DNI: ${titular.dni}
Tipo Ingreso: ${titular.tipoIngreso}
Ingresos Brutos: ${formatNumber(titular.ingresosBrutosAnuales)}
Ingresos Netos: ${formatNumber(titular.ingresosNetosAnuales)}
Estado Civil: ${titular.estado_civil || 'N/A'}
Familia Monoparental: ${titular.familia_monoparental === 'si' ? 'Sí' : 'No'}
Víctima Violencia Género: ${titular.victima_violencia === 'si' ? 'Sí' : 'No'}
Grado Discapacidad: ${titular.grado_discapacidad || 'No'}
`).join('\n')}

${otrosMiembros.length > 0 ? `
Otros miembros de la unidad familiar
-------------
${otrosMiembros.map(miembro => `
Nombre: ${miembro.nombre} ${miembro.apellidos}
DNI: ${miembro.dni}
Relación: ${miembro.relacion}
Tipo Ingreso: ${miembro.tipoIngreso}
Ingresos Brutos: ${formatNumber(miembro.ingresosBrutosAnuales)}
Ingresos Netos: ${formatNumber(miembro.ingresosNetosAnuales)}
Grado Discapacidad: ${miembro.grado_discapacidad || 'No'}
`).join('\n')}
` : ''}

Datos económicos adicionales
----------------------------
Ingresos hace 4 años: ${formatNumber(leadData.ingresosHace4Anos)}
Mayor de 60 años: ${leadData.mayor60 === 'si' ? 'Sí' : 'No'}
Dificultades de pago: ${leadData.dificultadesPago === 'si' ? 'Sí' : 'No'}

Rogamos se conteste en el plazo máximo de un mes, tal como establece la misma normativa y, así mismo, se proceda a la suspensión de cobros de las cuotas hipotecarias hasta que haya una resolución al respecto.

Si necesitan aclarar cualquier cuestión pueden llamarme al 653810566.

Muchas gracias
Reciban un cordial saludo`;
    };

    const getIcon = (docName) => {
        switch (docName) {
            case 'Renta 2020':
            case 'Renta 2021':
            case 'Renta 2022':
            case 'Renta 2023':
            case 'Ultimas 3 nominas':
            case 'Ultimas 3 facturas':
            case 'Certificado de prestaciones':
            case 'Certificado Sepe':
                return <FaFileInvoice className="text-green-500" />;
            case 'Certificado de empadronamiento':
            case 'Certificado Catastral o Nota de localización':
                return <FaHome className="text-yellow-500" />;
            case 'Copia dni frontal':
            case 'Copia dni anverso':
                return <FaUser className="text-blue-500" />;
            case 'Libro Familia':
            case 'Declaración Responsable':
            case 'Autorización':
            case 'Escritura Hipoteca':
                return <FaFileAlt className="text-red-500" />;
            default:
                return <FaFileAlt className="text-blue-500" />;
        }
    };

    const getStatusClass = (estado) => {
        switch (estado) {
            case 'aprobado':
                return 'bg-green-100 text-green-600';
            case 'rechazado':
                return 'bg-red-100 text-red-600';
            case 'pendiente':
                return 'bg-yellow-100 text-yellow-600';
            case 'esperando clave pin':
                return 'bg-purple-100 text-purple-600';
            default:
                return 'bg-gray-100 text-gray-600';
        }
    };

    const renderFamiliarTabs = () => (
        <div className="border-b border-gray-200">
            <nav className="-mb-px flex items-center justify-between" aria-label="Tabs">
                <div className="flex space-x-8">
                    {familiares.map((familiar) => (
                        <button
                            key={familiar.id}
                            onClick={() => {
                                setSelectedFamiliar(familiar);
                                setActiveMainTab('documentos');
                                setActiveTab(familiar.id);
                            }}
                            className={`${
                                activeMainTab === 'documentos' && activeTab === familiar.id
                                    ? 'border-purple-600 text-purple-600'
                                    : 'border-transparent text-gray-500 hover:text-purple-600 hover:border-purple-300'
                            } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200`}
                        >
                            <div className="flex flex-col items-start">
                                <span>{familiar.nombre} {familiar.apellidos}</span>
                                <span className={`text-xs ${
                                    activeTab === familiar.id 
                                        ? 'text-purple-500' 
                                        : 'text-gray-500'
                                }`}>
                                    {familiar.tipoIngreso || 'Sin tipo de ingreso'}
                                    {familiar.relacion === 'titular1' && ' (Titular Principal)'}
                                    {familiar.relacion === 'titular' && ' (Titular)'}
                                </span>
                            </div>
                        </button>
                    ))}
                    
                    <button
                        onClick={() => setActiveMainTab('historial')}
                        className={`${
                            activeMainTab === 'historial'
                                ? 'border-purple-600 text-purple-600'
                                : 'border-transparent text-gray-500 hover:text-purple-600 hover:border-purple-300'
                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm transition-colors duration-200`}
                    >
                        Historial y enviar al gestor
                    </button>
                </div>
            </nav>
        </div>
    );

    const AddDocumentoSection = ({ documentosDisponibles, documentosExtras, setDocumentosExtras }) => {
        const inputRef = useRef(null);
        const [isSubmitting, setIsSubmitting] = useState(false);

        const documentOptions = documentosDisponibles
            .filter(doc => !documentosExtras.includes(doc))
            .map(doc => ({
                value: doc,
                label: doc
            }));

        const guardarDocumentoExtra = async (nombreDocumento) => {
            setIsSubmitting(true);
            try {
                const { error } = await supabase
                    .from('documentos')
                    .insert({
                        cliente_id: leadId,
                        familiar_id: selectedFamiliar.id,
                        tipo_documento: nombreDocumento,
                        estado: 'solicitado',
                        created_at: new Date().toISOString()
                    });

                if (error) throw error;
                
                // Actualizar documentosExtras de manera más segura
                setDocumentosExtras(prevDocs => {
                    const newDocs = [...new Set([...prevDocs, nombreDocumento])];
                    return newDocs;
                });
                
                setExpandedCategories(prev => ({
                    ...prev,
                    "Documentos Extras": true
                }));
                
                toast.success(`Documento "${nombreDocumento}" solicitado correctamente`);
            } catch (error) {
                console.error('Error al guardar documento extra:', error);
                toast.error('Error al solicitar el documento');
            } finally {
                setIsSubmitting(false);
            }
        };

        const handleAddDocumento = async () => {
            const nuevoDocumento = inputRef.current.value.trim();
            if (nuevoDocumento && !documentosExtras.includes(nuevoDocumento)) {
                await guardarDocumentoExtra(nuevoDocumento);
                inputRef.current.value = '';
            }
        };

        const handleSelectDocumento = async (option) => {
            if (option?.value) {
                await guardarDocumentoExtra(option.value);
            }
        };

        return (
            <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
                <div className="space-y-6">
                    <div>
                        <h3 className="text-lg font-medium text-gray-900 mb-4">
                            Añadir Documento Extra
                        </h3>

                        <div className="mb-6">
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                Seleccionar documento existente
                            </label>
                            <Select
                                options={documentOptions}
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder="Buscar documento..."
                                isClearable={true}
                                isDisabled={isSubmitting}
                                onChange={handleSelectDocumento}
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        minHeight: '50px',
                                    }),
                                }}
                            />
                        </div>

                        <div className="mt-6">
                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                O añadir nuevo documento
                            </label>
                            <div className="flex space-x-4">
                                <input
                                    type="text"
                                    ref={inputRef} // Usar ref para acceder al valor
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleAddDocumento();
                                        }
                                    }}
                                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-3 sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Escribe el nombre del nuevo documento..."
                                />
                                <button
                                    type="button"
                                    onClick={handleAddDocumento}
                                    disabled={isSubmitting}
                                    className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                                        isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                                    }`}
                                >
                                    {isSubmitting ? 'Añadiendo...' : 'Añadir'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const handleObtenerDocumentacion = async (familiarId) => {
        setLoadingDocumentacion(prevState => ({
            ...prevState,
            [familiarId]: true
        }));
        
        try {
            const activeFamiliar = familiares.find(familiar => familiar.id === familiarId);

            if (!activeFamiliar) {
                toast.error('No se encontró el familiar seleccionado');
                return;
            }

            console.log('Iniciando obtención de documentación:', {
                clienteId: leadId,
                familiarId: familiarId,
                nombreFamiliar: activeFamiliar.nombre,
                relacion: activeFamiliar.relacion
            });

            // Obtener documentos que faltan por subir o están rechazados
            const documentosSubidosFamiliar = documentosSubidos[familiarId] || {};
            
            // Obtener solo los documentos que pueden ser solicitados a través de Bankflip
            const documentosBankflipDisponibles = Object.keys(documentacionConfig.mapeoBankflip);
            
            // Filtrar documentos necesarios que tienen mapeo en Bankflip y no están subidos o están rechazados
            const documentosFaltantes = documentosBankflipDisponibles.filter(doc => {
                const documentoSubido = documentosSubidosFamiliar[doc];
                return (!documentoSubido || documentoSubido.estado === 'rechazado');
            });

            // Mapear documentos al formato requerido por Bankflip
            const documentosBankflip = documentosFaltantes.map(doc => {
                const mapeo = documentacionConfig.mapeoBankflip[doc];
                if (mapeo) {
                    return {
                        model: {
                            type: mapeo.type,
                            ...(mapeo.year && { year: mapeo.year })
                        }
                    };
                }
                return null;
            }).filter(Boolean);

            if (documentosBankflip.length === 0) {
                toast.info('No hay documentos pendientes para solicitar a través de Bankflip');
                return;
            }

            console.log('Documentos a solicitar:', documentosBankflip);

            // Actualizar estado de documentos a "esperando clave pin"
            const updatedDocs = { ...documentosSubidos };
            if (!updatedDocs[familiarId]) {
                updatedDocs[familiarId] = {};
            }
            
            documentosFaltantes.forEach(doc => {
                updatedDocs[familiarId][doc] = {
                    ...updatedDocs[familiarId][doc],
                    estado: 'esperando clave pin'
                };
            });
            setDocumentosSubidos(updatedDocs);

            // Hacer la petición a Bankflip
            const response = await axios.post('https://backend.codigobuenaspracticas.es/api/create-session', {
                documentos: documentosBankflip,
                familiarId: activeFamiliar.id,
                clienteId: leadId
            });

            const { session } = response.data;

            if (session && (session.widget_link || session.widgetLink)) {
                const sessionUrl = session.widget_link || session.widgetLink;
                window.open(sessionUrl, '_blank', 'width=600,height=800');
                setSessionInProgress(prevState => ({
                    ...prevState,
                    [familiarId]: true
                }));
                toast.success('Sesión de Bankflip iniciada correctamente');
            } else {
                throw new Error('La respuesta no contiene el enlace del widget');
            }

        } catch (error) {
            console.error('Error creating session:', error);
            toast.error('Error al crear la sesión de Bankflip');
        } finally {
            setLoadingDocumentacion(prevState => ({
                ...prevState,
                [familiarId]: false
            }));
        }
    };

    const renderDocumentosTab = () => {
        if (!selectedFamiliar) {
            return (
                <div className="text-center py-4">
                    No hay familiares registrados
                </div>
            );
        }

        const documentosFamiliarActual = documentosSubidos[selectedFamiliar.id] || {};

        return (
            <div className="space-y-6">
                <div className="bg-white shadow-lg rounded-lg p-6">
                    <div className="mb-6">
                        <div className="flex justify-between items-start">
                            <h3 className="text-xl font-semibold">
                                Documentación para {selectedFamiliar?.nombre} {selectedFamiliar?.apellidos}
                            </h3>
                            <div className="w-1/2">
                                <div className="bg-white rounded-lg shadow-lg p-6 flex border-4 border-dashed border-purple-600">
                                    <div className="mr-4">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 24 24">
                                            <path fill="#FFFF00" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 2L3 14H11L10 22L21 10H13L14 2H13Z" />
                                        </svg>
                                    </div>
                                    <div className="flex-1">
                                        <h3 className="text-lg font-semibold mb-3">
                                            Obtener documentación con Bankflip
                                        </h3>
                                        <p className="text-gray-600 text-sm mb-4">
                                            Obtén automáticamente la documentación de Hacienda y Seguridad Social
                                        </p>
                                        <button 
                                            className="w-full bg-purple-600 text-white py-3 px-6 rounded-lg hover:bg-purple-700 transition duration-200 font-medium" 
                                            onClick={() => handleObtenerDocumentacion(selectedFamiliar.id)} 
                                            disabled={loadingDocumentacion[selectedFamiliar.id] || sessionInProgress[selectedFamiliar.id]}
                                        >
                                            {loadingDocumentacion[selectedFamiliar.id] ? "Cargando..." : 
                                             sessionInProgress[selectedFamiliar.id] ? "Obteniendo documentación" : 
                                             "Obtener documentación"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="space-y-4">
                            {Object.entries(categorias).map(([categoria, documentos]) => 
                                documentos.length > 0 && (
                                    <div key={categoria} className="border rounded-lg overflow-hidden">
                                        <button
                                            onClick={() => toggleCategory(categoria)}
                                            className="w-full px-4 py-3 bg-gray-50 hover:bg-gray-100 flex justify-between items-center"
                                        >
                                            <div className="flex items-center space-x-2">
                                                <span className="font-medium text-gray-900">{categoria}</span>
                                                <span className="bg-gray-200 text-gray-600 px-2 py-0.5 rounded-full text-xs">
                                                    {documentos.length}
                                                </span>
                                            </div>
                                            <svg
                                                className={`w-5 h-5 transform transition-transform ${
                                                    expandedCategories[categoria] ? 'rotate-180' : ''
                                                }`}
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                                            </svg>
                                        </button>

                                        {expandedCategories[categoria] && (
                                            <div className="divide-y divide-gray-200">
                                                {documentos.map((documento, index) => {
                                                    const documentoSubido = documentosFamiliarActual[documento];
                                                    
                                                    return (
                                                        <div
                                                            key={index}
                                                            className="p-4 hover:bg-gray-50 transition-colors"
                                                        >
                                                            <div className="flex justify-between items-center">
                                                                <div className="flex items-center">
                                                                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-blue-100 mr-2">
                                                                        {getIcon(documento)}
                                                                    </div>
                                                                    <div className="flex flex-col">
                                                                        <span className="text-sm font-medium text-gray-900">{documento}</span>
                                                                        {documentoSubido && (
                                                                            <>
                                                                                <span className="text-xs text-gray-500">
                                                                                    {documentoSubido.nombre}
                                                                                </span>
                                                                                {documentoSubido.estado === 'rechazado' && documentoSubido.motivo_rechazo && (
                                                                                    <span className="text-xs text-red-500 mt-1">
                                                                                        Motivo de rechazo: {documentoSubido.motivo_rechazo}
                                                                                    </span>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center space-x-2">
                                                                    {documentoSubido && (
                                                                        <>
                                                                            <div className={`inline-flex items-center px-3 py-1 rounded-full text-xs font-medium ${getStatusClass(documentoSubido.estado)}`}>
                                                                                <span className="w-2 h-2 mr-2 rounded-full bg-current"></span>
                                                                                {documentoSubido.estado}
                                                                            </div>
                                                                            <button
                                                                                onClick={() => {
                                                                                    setSelectedDocument(documentoSubido);
                                                                                    setModalOpen(true);
                                                                                }}
                                                                                className="p-2 text-gray-400 hover:text-purple-600 rounded-full hover:bg-gray-100"
                                                                                title="Ver documento"
                                                                            >
                                                                                <EyeIcon className="h-5 w-5" />
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                    <div className="relative">
                                                                        <input
                                                                            type="file"
                                                                            onChange={(e) => handleDocumentoChange(e, documento)}
                                                                            className="sr-only"
                                                                            id={`file-upload-${documento}`}
                                                                            disabled={loadingUpload}
                                                                        />
                                                                        <label
                                                                            htmlFor={`file-upload-${documento}`}
                                                                            className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-purple"
                                                                        >
                                                                            {documentoSubido ? 'Cambiar' : 'Subir'}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {uploadProgress[documento] && uploadProgress[documento] < 100 && (
                                                                <div className="mt-2 w-full bg-gray-200 rounded-full h-2.5">
                                                                    <div 
                                                                        className="bg-blue-600 h-2.5 rounded-full"
                                                                        style={{ width: `${uploadProgress[documento]}%` }}
                                                                    ></div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    {/* Después mostramos la sección de documentación extra */}
                    <div className="border-t border-gray-200 pt-6">
                        <div className="flex justify-end mb-4">
                            <button
                                onClick={() => setShowAddDocModal(!showAddDocModal)}
                                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                            >
                                {showAddDocModal ? 'Cerrar' : 'Pedir documentación extra'}
                                {!showAddDocModal && <PlusIcon className="ml-2 h-5 w-5" />}
                            </button>
                        </div>

                        {showAddDocModal && (
                            <div className="mt-4">
                                <AddDocumentoSection 
                                    documentosDisponibles={documentosDisponibles} 
                                    documentosExtras={documentosExtras} 
                                    setDocumentosExtras={setDocumentosExtras} 
                                />
                            </div>
                        )}
                    </div>

                    {/* Añadir el Modal para ver documentos */}
                    <DocumentModal
                        isOpen={modalOpen}
                        onClose={() => setModalOpen(false)}
                        document={selectedDocument}
                    />
                </div>
            </div>
        );
    };

 

    const renderHistorialTab = () => (
        <div className="space-y-6">
            <div className="bg-white shadow-lg rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-6">Historial de Envíos</h3>
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Fecha
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Gestor
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Emails Adicionales
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Estado
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Documentos
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Acciones
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {envios.map((envio) => (
                                <tr key={envio.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {new Date(envio.fecha_envio).toLocaleString('es-ES')}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-gray-900">{envio.gestor_nombre}</div>
                                        <div className="text-sm text-gray-500">{envio.gestor_email}</div>
                                        <div className="text-sm text-gray-500">{envio.gestor_telefono}</div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {envio.emails_adicionales ? (
                                            typeof envio.emails_adicionales === 'string' ? (
                                                <div className="flex flex-col gap-1">
                                                    {envio.emails_adicionales
                                                        .replace(/[\[\]"]/g, '') // Elimina [], y comillas
                                                        .split(',') // Divide por comas
                                                        .map((email, index) => (
                                                            <span key={index} className="text-sm text-gray-600">
                                                                {email.trim()} {/* Elimina espacios en blanco */}
                                                            </span>
                                                        ))}
                                                </div>
                                            ) : (
                                                <span className="text-gray-400">-</span>
                                            )
                                        ) : (
                                            <span className="text-gray-400">-</span>
                                        )}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                            envio.estado === 'enviado' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                                        }`}>
                                            {envio.estado || 'pendiente'}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <button
                                            onClick={() => {
                                                setSelectedEnvio(envio);
                                                setShowDocumentosModal(true);
                                            }}
                                            className="text-purple-600 hover:text-purple-900 flex items-center"
                                        >
                                            <TableCellsIcon className="h-5 w-5 mr-1" />
                                            Ver {envio.documentos ? Object.keys(envio.documentos).length : 0} documentos
                                        </button>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <div className="flex space-x-2">
                                            {envio.zip_url && (
                                                <button
                                                    onClick={() => handleDownloadDocument(envio.zip_url, envio.zip_filename)}
                                                    className="text-purple-600 hover:text-purple-900"
                                                    title="Descargar ZIP"
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                            )}
                                            {envio.comentarios && (
                                                <button
                                                    className="text-gray-600 hover:text-gray-900"
                                                    title="Ver comentarios"
                                                    onClick={() => toast.info(envio.comentarios)}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clipRule="evenodd" />
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            {envios.length === 0 && (
                                <tr>
                                    <td colSpan={6} className="px-6 py-4 text-center text-sm text-gray-500">
                                        No hay envíos registrados
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            
            {/* Añadir el modal aquí */}
            <DocumentosEnvioModal 
                isOpen={showDocumentosModal}
                onClose={() => setShowDocumentosModal(false)}
                envio={selectedEnvio}
                onDownload={handleDownloadDocument}
            />
        </div>
    );

    const getSignedUrl = async (filePath) => {
        try {
            const expirationTime = 60 * 24 * 60 * 60;
            
            const { data, error } = await supabase
                .storage
                .from('documentos')
                .createSignedUrl(filePath, expirationTime);

            if (error) {
                throw error;
            }

            if (!data?.signedUrl) {
                return null;
            }

            return data.signedUrl;
        } catch (error) {
            console.error('Error in getSignedUrl:', error);
            return null;
        }
    };

    const refreshDocumentUrls = async (documentos) => {
        const refreshedDocs = {};
        
        for (const [familiarId, docs] of Object.entries(documentos)) {
            refreshedDocs[familiarId] = {};
            for (const [docType, doc] of Object.entries(docs)) {
                if (doc.nombre && doc.url_documento) {
                    try {
                        const signedUrl = await getSignedUrl(doc.url_documento);
                        refreshedDocs[familiarId][docType] = {
                            ...doc,
                            url: signedUrl || doc.url // Mantener la URL anterior si no se puede obtener una nueva
                        };
                    } catch (error) {
                        console.error(`Error refreshing URL for ${docType}:`, error);
                        refreshedDocs[familiarId][docType] = doc;
                    }
                } else {
                    refreshedDocs[familiarId][docType] = doc;
                }
            }
        }
        
        return refreshedDocs;
    };

    const handleDownloadDocument = async (docUrl, docName) => {
        try {
            const response = await fetch(docUrl);
            const blob = await response.blob();
            saveAs(blob, docName);
            toast.success(`Documento ${docName} descargado exitosamente`);
        } catch (error) {
            console.error('Error al descargar el documento:', error);
            toast.error(`Error al descargar el documento ${docName}`);
        }
    };

    const actualizarEstadoDocumento = async (documentoId, nuevoEstado, motivoRechazo = null) => {
        try {
            const { error } = await supabase
                .from('documentos')
                .update({ 
                    estado: nuevoEstado,
                    motivo_rechazo: motivoRechazo 
                })
                .eq('id', documentoId);

            if (error) throw error;

            setDocumentosSubidos(prev => {
                const newState = { ...prev };
                Object.keys(newState).forEach(familiarId => {
                    Object.keys(newState[familiarId] || {}).forEach(docName => {
                        if (newState[familiarId][docName].id === documentoId) {
                            newState[familiarId][docName] = {
                                ...newState[familiarId][docName],
                                estado: nuevoEstado,
                                motivo_rechazo: motivoRechazo
                            };
                        }
                    });
                });
                return newState;
            });

            toast.success(`Documento ${nuevoEstado} exitosamente`);
        } catch (error) {
            console.error('Error al actualizar el estado del documento:', error);
            toast.error('Error al actualizar el estado del documento');
        }
    };

    const toggleCategory = (categoria) => {
        setExpandedCategories(prev => ({
            ...prev,
            [categoria]: !prev[categoria]
        }));
    };

    // Añadir este manejador para los campos del formulario
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const renderHistorialYEnviarTab = () => (
        <div className="space-y-6">
            {/* Sub-tabs para Historial y Enviar */}
            <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8">
                    <button
                        onClick={() => setActiveHistorialTab('enviar')}
                        className={`${
                            activeHistorialTab === 'enviar'
                                ? 'border-purple-600 text-purple-600'
                                : 'border-transparent text-gray-500'
                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                    >
                        Enviar al Gestor
                    </button>
                    <button
                        onClick={() => setActiveHistorialTab('historial')}
                        className={`${
                            activeHistorialTab === 'historial'
                                ? 'border-purple-600 text-purple-600'
                                : 'border-transparent text-gray-500'
                        } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
                    >
                        Historial de Envíos
                    </button>
                </nav>
            </div>

            {/* Contenido según el tab activo */}
            {activeHistorialTab === 'enviar' ? (
                <div className="bg-white shadow-lg rounded-lg p-6">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">
                        Enviar documentación al gestor
                    </h3>
                    
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Nombre del gestor
                            </label>
                            <input
                                type="text"
                                name="nombregestor"
                                value={formData.nombregestor}
                                onChange={handleChange}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Email principal del gestor
                            </label>
                            <input
                                type="email"
                                name="emailgestor"
                                value={formData.emailgestor}
                                onChange={handleChange}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                            />
                        </div>

                        {/* Nuevo campo para emails adicionales */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Emails adicionales
                            </label>
                            <CreatableSelect
                                isMulti
                                value={formData.emailsAdicionales}
                                onChange={(selectedOptions) => {
                                    setFormData(prev => ({
                                        ...prev,
                                        emailsAdicionales: selectedOptions || []
                                    }));
                                }}
                                placeholder="Añadir email..."
                                noOptionsMessage={() => "Escribe un email y presiona enter"}
                                className="mt-1"
                                classNamePrefix="select"
                                formatCreateLabel={(inputValue) => `Añadir "${inputValue}"`}
                                components={{
                                    DropdownIndicator: null
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault();
                                        const input = event.target.value;
                                        if (input && /\S+@\S+\.\S+/.test(input)) {
                                            const newOption = { value: input, label: input };
                                            setFormData(prev => ({
                                                ...prev,
                                                emailsAdicionales: [...(prev.emailsAdicionales || []), newOption]
                                            }));
                                        }
                                    }
                                }}
                            />
                            <p className="mt-1 text-sm text-gray-500">
                                Escribe un email y presiona Enter para añadirlo
                            </p>
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Teléfono del gestor
                            </label>
                            <input
                                type="text"
                                name="telefonogestor"
                                value={formData.telefonogestor}
                                onChange={handleChange}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Mensaje al gestor
                            </label>
                            <textarea
                                name="mensajePersonalizado"
                                value={formData.mensajePersonalizado}
                                onChange={handleChange}
                                rows={15}
                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm font-mono"
                            />
                        </div>

                        <button
                            type="submit"
                            disabled={loading}
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:text-sm"
                        >
                            {loading ? 'Enviando...' : 'Enviar documentación'}
                        </button>
                    </form>
                </div>
            ) : (
                renderHistorialTab()
            )}
        </div>
    );

    const ProgressModal = ({ isOpen, progress, status }) => {
        if (!isOpen) return null;

        return (
            <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

                    <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                        <div>
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-purple-100">
                                <svg className="animate-spin h-6 w-6 text-purple-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900">
                                    Enviando documentación
                                </h3>
                                <div className="mt-4">
                                    <div className="relative pt-1">
                                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-purple-200">
                                            <div 
                                                style={{ width: `${progress}%` }}
                                                className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-purple-600 transition-all duration-500"
                                            ></div>
                                        </div>
                                        <div className="text-center text-sm text-gray-600">
                                            {progress}% completado
                                        </div>
                                        <div className="mt-2 text-sm text-gray-500">
                                            {status}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="space-y-4">
            <ToastContainer />
            {renderFamiliarTabs()}
            
            <div className="bg-white shadow rounded-lg">
                {activeMainTab === 'historial' ? renderHistorialYEnviarTab() : renderDocumentosTab()}
            </div>

            <ProgressModal 
                isOpen={showProgressModal} 
                progress={sendingProgress}
                status={sendingStatus}
            />
        </div>
    );
};

export default EnviarGestor;