// src/data/bankOptions.js
const bankOptions = [
  'Abanca Corporación Bancaria, SA.',
  'Arquia Bank, SA.',
  'Banca March, SA.',
  'Banco Bilbao Vizcaya Argentaria, SA.',
  'Banco Caminos, SA.',
  'Banco Cooperativo Español, SA.',
  'Banco de Crédito Social Cooperativo, SA.',
  'Banco de Sabadell, SA.',
  'Banco Mediolanum, SA.',
  'Banco Santander, SA.',
  'Bancofar, SA.',
  'Bankinter, SA.',
  'CaixaBank, SA.',
  'Deutsche Bank SAE.',
  'Caixa de Credit dels EnginyersCaja de Crédito de los Ingenieros, S. Coop. de',
  'Caixa PopularCaixa Rural, S. Coop. de Crédito V.',
  'Caixa Rural Altea, Cooperativa de Crèdit Valenciana.',
  'Caixa Rural Benicarló, S. Coop. de Crèdit V.',
  'Caixa Rural D’Algemesi, S. Coop. V. de Crèdit.',
  'Caixa Rural de Callosa D’en Sarrià, Cooperativa de Crédito Valenciana.',
  'Caixa Rural de L’Alcudia, Sociedad Cooperativa Valenciana de Crédito.',
  'Caixa Rural de Turís, Cooperativa de Crédito Valenciana.',
  'Caixa Rural Galega, Sociedad Cooperativa de Crédito Limitada Gallega.',
  'Caixa Rural la Vall San Isidro, Sociedad Cooperativa de Crédito Valenciana.',
  'Caixa Rural les Coves de Vinromà, S. Coop. de Crèdit V.',
  'Caixa Rural Sant Josep de Vilavella, S. Coop. de Crédito V.',
  'Caixa Rural Sant Vicent Ferrer de la Vall’Uixó, Coop. de Crèdit V.',
  'Caixa Rural Torrent, Cooperativa de Crèdit Valenciana.',
  'Caixa Rural Vinaròs, S. Coop. de Crèdit V.',
  'Caja de Ahorros y Monte de Piedad de Ontinyent.',
  'Caja de Crédito de Petrel, Caja Rural, Cooperativa de Crédito Valenciana.',
  'Caja Laboral Popular, Coop. de Crédito.',
  'Caja Rural Católico Agraria, S. Coop. de Crédito V.',
  'Caja Rural Central, Sociedad Cooperativa de Crédito.',
  'Caja Rural de Albacete, Ciudad Real y Cuenca, Sociedad Cooperativa de Crédito (Globalcaja).',
  'Caja Rural de Albal, Coop. de Crédito V.',
  'Caja Rural de Alginet, Sociedad Cooperativa Crédito Valenciana.',
  'Caja Rural de Almendralejo, Sociedad Cooperativa de Crédito (Cajalmendralejo).',
  'Caja Rural de Aragón, Sociedad Cooperativa de Crédito.',
  'Caja Rural de Asturias, Sociedad Cooperativa de Crédito.',
  'Caja Rural de Baena Ntra. Sra. de Guadalupe, Sociedad Cooperativa de Crédito Andaluza.',
  'Caja Rural de Cañete de las Torres, Ntra. Sra. del Campo, Sociedad Cooperativa Andaluza de Crédito.',
  'Caja Rural de Casas Ibañez, S. Coop. de Crédito de CastillaLa Mancha.',
  'Caja Rural de Cheste, Sociedad Cooperativa de Crédito Valenciana.',
  'Caja Rural de Extremadura, Sociedad Cooperativa de Crédito.',
  'Caja Rural de Gijón, Sociedad Cooperativa Asturiana de Crédito.',
  'Caja Rural de Granada, Sociedad Cooperativa de Crédito.',
  'Caja Rural de Guissona, Sociedad Cooperativa de Crédito.',
  'Caja Rural de Navarra, S. Coop. de Crédito.',
  'Caja Rural de Nueva Carteya, Sociedad Cooperativa Andaluza de Crédito.',
  'Caja Rural de Salamanca, Sociedad Cooperativa de Crédito.',
  'Caja Rural de Soria, Sociedad Cooperativa de Crédito.',
  'Caja Rural de Teruel, Sociedad Cooperativa de Crédito.',
  'Caja Rural de Utrera, Sociedad Cooperativa Andaluza de Crédito.',
  'Caja Rural de Villamalea, S. Coop. de Crédito Agrario de CastillaLa Mancha.',
  'Caja Rural de Villar, Coop. de Crédito V.',
  'Caja Rural de Zamora, Cooperativa de Crédito.',
  'Caja Rural del Sur, S. Coop. de Crédito.',
  'Caja Rural la Junquera de Chilches, S. Coop. de Crédito V.',
  'Caja Rural Nuestra Madre del Sol, S. Coop. Andaluza de Crédito.',
  'Caja Rural Regional San Agustín Fuente Álamo Murcia, Sociedad Cooperativa de Crédito.',
  'Caja Rural San Isidro de Vilafames, S. Coop. de Crédito V.',
  'Caja Rural San Jaime de Alquerías Niño Perdido, S. Coop. de Crédito V.',
  'Caja Rural San José de Almassora, S. Coop. de Crédito V.',
  'Caja Rural San José de Burriana, S. Coop. de Crédito V.',
  'Caja Rural San José de Nules, S. Coop. de Crédito V.',
  'Caja Rural San Roque de Almenara, S. Coop. de Crédito V.',
  'Cajamar Caja Rural, Sociedad Cooperativa de Crédito.',
  'Cajasiete, Caja Rural, Sociedad Cooperativa de Crédito.',
  'Cajasur Banco, SA.',
  'ColonyaCaixa D’estalvis de Pollensa.',
  'Eurocaja Rural, Sociedad Cooperativa de Crédito.',
  'Evo Banco, SA.',
  'Ibercaja Banco, SA.',
  'ING Bank NV. Sucursal en España.',
  'Kutxabank, SA.',
  'Myinvestor Banco, SA.',
  'Open Bank, SA.',
  'Publicredit, SL.',
  'Targobank, SA.',
  'Triodos Bank, NV, SE.',
  'Unicaja Banco, SA.',
  'Unión de Créditos Inmobiliarios, SA, Establecimiento Financiero de Crédito.',
  'Wizink Bank, SA.',
];

export default bankOptions;
