import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../../utils/supabaseClient';
import Actividades from '../../components/Actividades';
import { AuthContext } from '../../contexts/AuthContext';
import Select from 'react-select';


const LeadDetalle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [lead, setLead] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    nombre: '',
    apellidos: '',
    email: '',
    telefono: '',
    provincia: '',
    bancoHipoteca: '',
    ingresosBrutosAnuales: '',
    pagoMensualHipoteca: '',
    cantidadPorPagar: '',
    anosFaltantes: '',
    interesAnual: '',
    cuotaHace4Anos: '',
    ingresosHace4Anos: '',
    discapacidad: '',
    mayor60: '',
    dificultadesPago: '',
    grupoFamiliar: '',
    ingresosNetosAnuales: '',
    plazoOriginalHipoteca: '',
    cuotaJunio2022: '',
    Carencia: '',
    NuevaCuota: '',
    InteresCarencia: '',
    estadoLead: '',
    prevision_primerpago: '', // Nueva columna añadida
    fase_comercial: '',
  });

  const [expandedSections, setExpandedSections] = useState({
    'Datos Hipoteca': false,
    'Ingresos': false,
    'Resultados Simulador': false,
    'Informacion Vulnerable': false,
  });

  const [isEditingEstado, setIsEditingEstado] = useState(false);
  const [isEditingPrevision, setIsEditingPrevision] = useState(false);
  const [isEditingFaseComercial, setIsEditingFaseComercial] = useState(false);

  const estados = [
    "Lead Nuevo",
    "Intento de contacto",
    "Mal momento",
    "Lead Simulador en curso",
    "Lead Simulador completado",
    "Lead Pricing",
    "Lead No cumple requisitos",
    "Cliente Primeros Pasos",
    "Cliente Subir Doc",
    "Cliente Firmar Documentación",
    "Cliente Como Va mi Caso",
    "Cliente Caso Resuelto",
    "Cliente solicitud enviada",
    "Recordatorio al banco",
    "Reclamación propuesta",
    "Reclamación BDE",
    "Revisión notaria/acuerdo",
    "Firma notaría/acuerdo",
    "Caso Resuelto"
  ];

  const previsionOptions = [
    { value: '99', label: '99' },
    { value: '249', label: '249' },
    { value: '499', label: '499' },
    { value: '999', label: '999' }
  ];

  const estadoOptions = estados.map(estado => ({
    value: estado,
    label: estado
  }));

  const { user } = useContext(AuthContext);
  const [userData, setUserData] = useState({ nombre: '', apellidos: '' });

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        const { data, error } = await supabase
          .from('usuarios')
          .select('nombre, apellidos')
          .eq('user_id', user.id)
          .single();

        if (error) {
          console.error('Error al obtener datos del usuario:', error);
        } else {
          setUserData(data);
        }
      };

      fetchUserData();
    }
  }, [user]);

  useEffect(() => {
    const fetchLead = async () => {
      try {
        const cleanedId = id.trim();
        console.log(`Buscando por cleanedId: '${cleanedId}'`);

        let { data: simulacionData, error: simulacionError } = await supabase
          .from('leads')
          .select('*')
          .eq('id', cleanedId)
          .single();

        if (simulacionError) {
          throw new Error('Error fetching lead from simulacion_resultados');
        }

        let { data: customerJourneyData, error: customerJourneyError } = await supabase
          .from('customer_journey')
          .select('estado, prevision_primerpago')
          .eq('user_id', cleanedId)
          .single();

        if (customerJourneyError) {
          console.warn('No corresponding entry in customer_journey for user_id:', cleanedId);
          setFormData({ ...simulacionData, estadoLead: 'Desconocido', prevision_primerpago: '' });
        } else {
          setFormData({ ...simulacionData, estadoLead: customerJourneyData.estado, prevision_primerpago: customerJourneyData.prevision_primerpago });
        }

        setLead(simulacionData);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchLead();
  }, [id]);

  const toggleSection = (section) => {
    setExpandedSections((prevSections) => ({
      ...prevSections,
      [section]: !prevSections[section],
    }));
  };

  const handleEstadoChange = async (selectedOption) => {
    const newEstado = selectedOption.value;
    setFormData({ ...formData, estadoLead: newEstado });

    const { error } = await supabase
      .from('customer_journey')
      .upsert({ user_id: id, estado: newEstado }, { onConflict: 'user_id' });

    if (error) {
      console.error('Error updating estado:', error.message);
    } else {
      console.log('Estado updated successfully');
    }

    setIsEditingEstado(false);
  };

  const handlePrevisionChange = async (selectedOption) => {
    const newPrevision = selectedOption.value;
    setFormData({ ...formData, prevision_primerpago: newPrevision });

    const { error } = await supabase
      .from('customer_journey')
      .upsert({ user_id: id, prevision_primerpago: newPrevision }, { onConflict: 'user_id' });

    if (error) {
      console.error('Error updating prevision_primerpago:', error.message);
    } else {
      console.log('Prevision Primer Pago updated successfully');
    }

    setIsEditingPrevision(false);
  };

  const toggleEditEstado = () => {
    setIsEditingEstado(!isEditingEstado);
  };

  const toggleEditPrevision = () => {
    setIsEditingPrevision(!isEditingPrevision);
  };

  const toggleEditFaseComercial = () => {
    setIsEditingFaseComercial(!isEditingFaseComercial);
  };

  const handleFaseComercialChange = async (selectedOption) => {
    const newFase = selectedOption.value;
    setFormData({ ...formData, fase_comercial: newFase });

    const { error } = await supabase
      .from('leads')
      .update({ fase_comercial: newFase })
      .eq('id', id);

    if (error) {
      console.error('Error updating fase_comercial:', error.message);
    } else {
      console.log('Fase comercial updated successfully');
    }

    setIsEditingFaseComercial(false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const excludedFields = [
    'user_id',
    'precioVivienda',
    'firmasteHipoteca',
    'viviendaHabitual',
    'politicaPrivacidad',
    'created_at',
    'id',
    'usuario_asignado',
  ];

  const friendlyFieldNames = {
    bancoHipoteca: 'Banco donde tiene la hipoteca',
    ingresosBrutosAnuales: 'Ingresos brutos anuales',
    discapacidad: '¿Tiene a alguien con discapacidad?',
    mayor60: '¿Es mayor de 60 años?',
    dificultadesPago: '¿Ha tenido dificultades para pagar la hipoteca?',
    grupoFamiliar: '¿Pertenece a un grupo familiar vulnerable?',
    pagoMensualHipoteca: 'Cuota mensual hipoteca',
    cantidadPorPagar: 'Cantidad que le falta por pagar de la hipoteca',
    anosFaltantes: 'Cuantos años faltan para pagar la hipoteca',
    interesAnual: 'El interes anual',
    cuotaHace4Anos: 'Cuota de la hipoteca hace 4 años',
    ingresosHace4Anos: 'Ingresos netos hace 4 años',
    provincia: 'Provincia',
    ingresosNetosAnuales: 'Ingresos netos anuales',
    plazoOriginalHipoteca: 'Plazo original de la hipoteca',
    cuotaJunio2022: 'Cuota en junio 2022',
    Carencia: 'Carencia',
    NuevaCuota: 'Nueva Cuota',
    InteresCarencia: 'Interes Carencia',
    estadoLead: 'Estado lead',
    prevision_primerpago: 'Previsión Primer Pago', // Nueva columna añadida
  };

  const categories = {
    'Datos Hipoteca': [
      'bancoHipoteca', 'pagoMensualHipoteca', 'cantidadPorPagar', 'anosFaltantes', 'interesAnual',
      'cuotaHace4Anos', 'plazoOriginalHipoteca', 'cuotaJunio2022'
    ],
    'Ingresos': [
      'ingresosBrutosAnuales', 'ingresosNetosAnuales', 'ingresosHace4Anos'
    ],
    'Resultados Simulador': [
      'Carencia', 'NuevaCuota', 'InteresCarencia'
    ],
    'Informacion Vulnerable': [
      'discapacidad', 'mayor60', 'dificultadesPago', 'grupoFamiliar'
    ]
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      minWidth: '300px',
      width: '100%',
    }),
    menu: (provided) => ({
      ...provided,
      width: '300px',
      zIndex: 9999,
    }),
  };

  return (
    
    <div className="container mx-auto px-4 pt-2">
       <div className="flex items-center mb-2">
        <button
          type="button"
          className="flex items-center text-black bg-gray-200 px-4 py-2 rounded-md mr-2"
          onClick={() => navigate('/leads')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 0 1 0 12h-3" />
          </svg>
          Volver
        </button>
      </div>
      {/* Datos del lead en la parte superior */}
      <div className="bg-gray-100 p-6 rounded-lg shadow-lg mb-6 grid grid-cols-3 gap-4">
        <div className="flex items-center col-span-3 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10 mr-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 12c2.28 0 4-1.72 4-4S14.28 4 12 4 8 5.72 8 8s1.72 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
          </svg>
          <h2 className="text-xl font-semibold">{formData.nombre} {formData.apellidos}</h2>
        </div>
  
        <div>
          <p className="text-gray-700 mb-2"><strong>Email:</strong> {formData.email}</p>
          <p className="text-gray-700 mb-2"><strong>Teléfono:</strong> {formData.telefono}</p>
          <p className="text-gray-700 mb-2"><strong>{friendlyFieldNames['provincia']}:</strong> {formData.provincia}</p>
        </div>
  
        <div>
          <div className="mb-2">
            <strong>Estado Lead: </strong>
            {isEditingEstado ? (
              <Select
                options={[
                  { value: "Cliente Primeros Pasos", label: "Cliente Primeros Pasos" },
                  { value: "Cliente Subir Doc", label: "Cliente Subir Doc" },
                  { value: "Cliente Firmar Documentación", label: "Cliente Firmar Documentación" },
                  { value: "Cliente Como Va mi Caso", label: "Cliente Como Va mi Caso" },
                  { value: "Cliente Caso Resuelto", label: "Cliente Caso Resuelto" },
                  { value: "Cliente solicitud enviada", label: "Cliente solicitud enviada" },
                  { value: "Recordatorio al banco", label: "Recordatorio al banco" },
                  { value: "Reclamación propuesta", label: "Reclamación propuesta" },
                  { value: "Reclamación BDE", label: "Reclamación BDE" },
                  { value: "Revisión notaria/acuerdo", label: "Revisión notaria/acuerdo" },
                  { value: "Firma notaría/acuerdo", label: "Firma notaría/acuerdo" },
                  { value: "Caso Resuelto", label: "Caso Resuelto" }
                ]}
                value={{ value: formData.estadoLead, label: formData.estadoLead }}
                onChange={handleEstadoChange}
                styles={customStyles}
              />
            ) : (
              <span onClick={toggleEditEstado} className="cursor-pointer hover:bg-gray-200 px-2 py-1 rounded">
                {formData.estadoLead}
              </span>
            )}
          </div>
          <div className="mb-2">
            <strong>Fase Comercial: </strong>
            {isEditingFaseComercial ? (
              <Select
                options={[
                  { value: 'Nuevo', label: 'Nuevo' },
                  { value: 'Interesado', label: 'Interesado' },
                  { value: 'Mal Momento', label: 'Mal Momento' },
                  { value: 'No contesta', label: 'No contesta' },
                  { value: 'Telefono incorrecto', label: 'Telefono incorrecto' },
                  { value: 'No tiene perfil', label: 'No tiene perfil' },
                  { value: 'No interesado', label: 'No interesado' },
                  { value: 'Contratado', label: 'Contratado' }
                ]}
                value={{ value: formData.fase_comercial, label: formData.fase_comercial }}
                onChange={handleFaseComercialChange}
                styles={customStyles}
              />
            ) : (
              <span onClick={toggleEditFaseComercial} className="cursor-pointer hover:bg-gray-200 px-2 py-1 rounded">
                {formData.fase_comercial || '-'}
              </span>
            )}
          </div>
          <div className="mb-2">
            <strong>Previsión Primer Pago: </strong>
            {isEditingPrevision ? (
              <Select
                options={previsionOptions}
                value={{ value: formData.prevision_primerpago, label: formData.prevision_primerpago }}
                onChange={handlePrevisionChange}
                styles={customStyles}
              />
            ) : (
              <span onClick={toggleEditPrevision} className="cursor-pointer hover:bg-gray-200 px-2 py-1 rounded">
                {formData.prevision_primerpago || '-'}
              </span>
            )}
          </div>
        </div>

      </div>
       
     
  
      <div className="col-span-2">
      <Actividades leadId={id} clienteId={null} hideExpediente={true} />
    </div>
    </div>
  );
  
  
};

export default LeadDetalle;
