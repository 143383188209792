import React, { useEffect, useState } from 'react';
import { supabase } from '../utils/supabaseClient';

const Notas = ({ leadId, clienteId, refreshActividades }) => {
  const [notas, setNotas] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [newNota, setNewNota] = useState('');
  const [visibleParaElCliente, setVisibleParaElCliente] = useState(false);
  const [anclarNota, setAnclarNota] = useState(false);
  const [editingNotaId, setEditingNotaId] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [editVisibleParaElCliente, setEditVisibleParaElCliente] = useState(false);
  const [editAnclarNota, setEditAnclarNota] = useState(false);
  const [userData, setUserData] = useState(null);
  const [ancladaError, setAncladaError] = useState('');

  const [uploading, setUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [fileUrl, setFileUrl] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();

        if (sessionError) {
          console.error('Error al obtener la sesión:', sessionError);
          return;
        }

        if (!session) {
          console.log('No hay sesión activa');
          return;
        }

        const { data, error } = await supabase
          .from('usuarios')
          .select('user_id, nombre, apellidos')
          .eq('user_id', session.user.id)
          .single();

        if (error) {
          console.error('Error al obtener datos del usuario:', error);
          return;
        }

        if (data) {
          setUserData(data);
          console.log("Datos de usuario obtenidos:", data);
        }
      } catch (error) {
        console.error('Error general:', error);
      }
    };

    fetchUserData();
  }, []);

  const fetchNotas = async () => {
    let query = supabase
      .from('notas')
      .select(`
        *,
        documentos (
          url_documento
        )
      `)
      .order('notaanclada', { ascending: false })
      .order('fecha', { ascending: false });
    if (leadId) {
      query = query.eq('lead_id', leadId);
    } else if (clienteId) {
      query = query.eq('cliente_id', clienteId);
    }

    const { data: notasData, error } = await query;

    if (error) {
      console.error('Error fetching notas:', error);
    } else {
      const notasConUrlsFirmadas = await Promise.all(notasData.map(async (nota) => {
        if (nota.documentos.length > 0) {
          const { data: signedUrlData, error: signedUrlError } = await supabase
            .storage
            .from('documentos')
            .createSignedUrl(nota.documentos[0].url_documento, 60 * 60 * 24 * 7);

          if (signedUrlError) {
            console.error('Error creating signed URL:', signedUrlError);
            return nota;
          }

          if (signedUrlData.signedUrl) {
            nota.documentos[0].signedUrl = signedUrlData.signedUrl;
          }
        }
        return nota;
      }));

      setNotas(notasConUrlsFirmadas);
    }
  };

  useEffect(() => {
    fetchNotas();
  }, [leadId, clienteId]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const sanitizedFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '_');

    setUploading(true);
    setUploadComplete(false);

    const { data, error } = await supabase.storage
      .from('documentos')
      .upload(`documentos/${sanitizedFileName}`, file);

    if (error) {
      console.error('Error uploading file:', error);
      setUploading(false);
      return;
    }

    const filePath = `documentos/${sanitizedFileName}`;
    setFileUrl(filePath);

    setUploading(false);
    setUploadComplete(true);
    setUploadedFileName(file.name);
  };

  const toggleNuevaNota = () => {
    setShowInput(!showInput);
  };

  const handleGuardarNota = async () => {
    if (!userData) {
      console.error('Los datos del usuario no están cargados aún');
      return;
    }

    if (!newNota.trim()) {
      console.error('La nota no puede estar vacía');
      return;
    }

    try {
      const { data: notaData, error: notaError } = await supabase
        .from('notas')
        .insert([{
          contenido: newNota,
          lead_id: leadId,
          cliente_id: clienteId,
          fecha: new Date(),
          user_id: userData.user_id,
          usuario_nombre: userData.nombre,
          usuario_apellidos: userData.apellidos,
          visibleparaelcliente: visibleParaElCliente,
          notaanclada: anclarNota
        }])
        .select();

      if (notaError) {
        console.error('Error al guardar la nota:', notaError);
        return;
      }

      if (notaData && notaData.length > 0) {
        const notaId = notaData[0].id;

        if (fileUrl) {
          const { error: insertError } = await supabase
            .from('documentos')
            .insert([{
              cliente_id: clienteId,
              nota_id: notaId, // Relacionando la nota con el documento
              tipo_documento: 'Documento Subido CBP',
              url_documento: fileUrl,
              estado: 'aprobado'
            }]);

          if (insertError) {
            console.error('Error inserting document data:', insertError);
            return;
          }
        }

        fetchNotas();
        setNewNota('');
        setVisibleParaElCliente(false);
        setAnclarNota(false);
        setShowInput(false);
        setUploadComplete(false);
        setUploadedFileName('');
        setFileUrl('');

        const { error: actividadError } = await supabase
          .from('actividades')
          .insert([{
            tipo: 'Nota',
            descripcion: newNota,
            lead_id: leadId,
            cliente_id: clienteId,
            fecha: new Date(),
            user_id: userData.user_id,
            usuario_nombre: userData.nombre,
            usuario_apellidos: userData.apellidos,
            visibleparaelcliente: visibleParaElCliente
          }]);

        if (actividadError) {
          console.error('Error al guardar la actividad:', actividadError);
        } else {
          refreshActividades();
        }
      } else {
        console.error('No se pudo obtener la nota guardada.');
      }
    } catch (error) {
      console.error('Error al guardar la nota:', error);
    }
  };

  const handleEditNota = (nota) => {
    if (editingNotaId === nota.id) {
      handleSaveEditNota();
    } else {
      setEditingNotaId(nota.id);
      setEditContent(nota.contenido);
      setEditVisibleParaElCliente(nota.visibleparaelcliente);
      setEditAnclarNota(nota.notaanclada);
    }
  };

  const handleSaveEditNota = async () => {
    if (editAnclarNota) {
      const { data: existingPinnedNotes, error: fetchError } = await supabase
        .from('notas')
        .select('id')
        .eq('notaanclada', true);

      if (fetchError) {
        console.error('Error fetching pinned notes:', fetchError);
        return;
      }

      if (existingPinnedNotes.length > 0 && existingPinnedNotes[0].id !== editingNotaId) {
        setAncladaError('Ya hay una nota anclada, quita el check y vuelve a intentar.');
        setEditAnclarNota(false);
        return;
      }
    }

    const { data, error } = await supabase
      .from('notas')
      .update({ contenido: editContent, visibleparaelcliente: editVisibleParaElCliente, notaanclada: editAnclarNota })
      .eq('id', editingNotaId)
      .select();

    if (error) {
      console.error('Error updating nota:', error);
      return;
    }

    fetchNotas();
    setEditingNotaId(null);
    setEditContent('');
    setEditVisibleParaElCliente(false);
    setEditAnclarNota(false);
    setAncladaError('');
  };

  const handleDeleteNota = async (id) => {
    const { error } = await supabase
      .from('notas')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting nota:', error);
    } else {
      fetchNotas();
    }
  };

  return (
    <div className="mb-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Notas</h2>
        <button
          onClick={toggleNuevaNota}
          className="flex items-center text-white bg-gradient-to-r from-purple-500 to-blue-500 px-4 py-2 rounded-md hover:from-purple-600 hover:to-blue-600"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Nueva nota
        </button>
      </div>

      {showInput && (
        <div className="relative bg-white p-4 rounded-lg shadow-md mb-4">
          <button
            onClick={toggleNuevaNota}
            className="absolute top-1 right-1 text-black hover:text-gray-700"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          </button>
          <textarea
            className="w-full h-24 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
            style={{ whiteSpace: 'pre-wrap' }}
            placeholder="Empieza a escribir para dejar una nota..."
            value={newNota}
            onChange={(e) => setNewNota(e.target.value)}
          ></textarea>
          <div className="flex items-center mt-2">
            <input
              type="checkbox"
              id="visibleParaElCliente"
              className="mr-2"
              checked={visibleParaElCliente}
              onChange={(e) => setVisibleParaElCliente(e.target.checked)}
            />
            <label htmlFor="visibleParaElCliente" className="text-sm font-bold text-gray-700">Visible para el cliente?</label>
          </div>
          <div className="flex items-center mt-2">
            <input
              type="checkbox"
              id="anclarNota"
              className="mr-2"
              checked={anclarNota}
              onChange={(e) => setAnclarNota(e.target.checked)}
            />
            <label htmlFor="anclarNota" className="text-sm font-bold text-gray-700">Anclar nota</label>
          </div>
          {uploadComplete && (
            <div className="flex items-center mt-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
              </svg>
              <span>{uploadedFileName}</span>
            </div>
          )}
          <div className="flex justify-end mt-2 space-x-4">
            <label className="flex items-center text-white bg-gradient-to-r from-purple-500 to-blue-500 px-4 py-2 rounded-md hover:from-purple-600 hover:to-blue-600 cursor-pointer">
              {uploading ? (
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              ) : (
                <span className="flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                  </svg>
                  Añadir documento
                </span>
              )}
              <input type="file" className="hidden" onChange={handleFileUpload} />
            </label>
            <button
              onClick={handleGuardarNota}
              className="bg-gradient-to-r from-purple-500 to-blue-500 text-white px-4 py-2 rounded-md hover:from-purple-600 hover:to-blue-600"
            >
              Guardar nota
            </button>
          </div>
        </div>
      )}

      <div className="space-y-2">
        {notas.sort((a, b) => {
          if (a.notaanclada && !b.notaanclada) return -1;
          if (!a.notaanclada && b.notaanclada) return 1;
          return new Date(b.fecha) - new Date(a.fecha);
        }).map((nota) => (
          <div
            key={nota.id}
            className={`bg-white p-4 rounded-lg shadow-md group relative ${nota.notaanclada ? 'border-4 border-purple-500' : ''}`}
          >
            <div className="flex justify-between items-center">
              <p className="text-sm text-gray-500">{new Date(nota.fecha).toLocaleString()}</p>
              <button
                className="opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                onClick={() => handleEditNota(nota)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                </svg>
              </button>
            </div>
            {editingNotaId === nota.id ? (
              <div>
                <textarea
                  className="w-full h-24 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
                  value={editContent}
                  onChange={(e) => setEditContent(e.target.value)}
                ></textarea>
                <div className="flex items-center mt-2">
                  <input
                    type="checkbox"
                    id={`editVisibleParaElCliente-${nota.id}`}
                    className="mr-2"
                    checked={editVisibleParaElCliente}
                    onChange={(e) => setEditVisibleParaElCliente(e.target.checked)}
                  />
                  <label htmlFor={`editVisibleParaElCliente-${nota.id}`} className="text-sm font-bold text-gray-700">Visible para el cliente?</label>
                </div>
                <div className="flex items-center mt-2">
                  <input
                    type="checkbox"
                    id={`editAnclarNota-${nota.id}`}
                    className="mr-2"
                    checked={editAnclarNota}
                    onChange={(e) => setEditAnclarNota(e.target.checked)}
                  />
                  <label htmlFor={`editAnclarNota-${nota.id}`} className="text-sm font-bold text-gray-700">Anclar nota</label>
                </div>
                {ancladaError && <p className="text-sm text-red-500 mt-2">{ancladaError}</p>}
                <div className="flex justify-end mt-2">
                  <button
                    onClick={handleSaveEditNota}
                    className="bg-gradient-to-r from-purple-500 to-blue-500 text-white px-4 py-2 rounded-md hover:from-purple-600 hover:to-blue-600"
                  >
                    Guardar cambios
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <p className="mt-2" style={{ whiteSpace: 'pre-wrap' }}>{nota.contenido}</p>
                {nota.visibleparaelcliente && (
                  <div className="text-green-500 text-xs mt-2">
                    Visible para el cliente
                  </div>
                )}
                {nota.documentos && nota.documentos.length > 0 && (
                  <div className="flex items-center mt-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />
                    </svg>
                    <a href={`${nota.documentos[0].signedUrl}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                      {nota.documentos[0].url_documento.split('/').pop()}
                    </a>
                  </div>
                )}
              </div>
            )}
            <div className="flex justify-between items-center mt-2">
              <div className="flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                </svg>
                <p className="text-sm text-gray-700">{nota.usuario_nombre} {nota.usuario_apellidos}</p>
              </div>
              <button
                className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 text-gray-500 hover:text-gray-700"
                onClick={() => handleDeleteNota(nota.id)}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Notas;
