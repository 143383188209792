import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import bankOptions from "../../data/bankOptions"
import { supabase } from '../../utils/supabaseClient';

// Importación de componentes
import FormularioSimulacion from './FormularioSimulacion';
import ResultadoCarencia from './ResultadoCarencia';
import PantallaExclusion from './PantallaExclusion';
import ModalInformacion from './ModalInformacion';

const initialFormData = {
  // Datos básicos
  precioVivienda: '',
  firmasteHipoteca: '',
  viviendaHabitual: '',
  fechaSubasta: '',

  // Datos bancarios
  bancoHipoteca: '',
  ingresosBrutosAnuales: '',
  ingresosNetosAnuales: '',
  pagoMensualHipoteca: '',
  cantidadPorPagar: '',
  anosFaltantes: '',
  plazoOriginalHipoteca: '',
  interesAnual: '',
  cuotaHace4Anos: '',
  cuotaJunio2022: '',
  ingresosHace4Anos: '',

  // Datos de vulnerabilidad
  gradoDiscapacidad: '',
  mayor60: '',
  dificultadesPago: '',
  grupoFamiliar: '',
};

const questions = [
  {
    label: '¿El precio de tu vivienda es inferior a 300.000€?',
    name: 'precioVivienda',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'El precio de adquisición de la vivienda debe ser inferior a 300.000€ para cumplir los requisitos.'
  },
  {
    label: '¿Firmaste tu hipoteca antes del 31 de diciembre de 2022?',
    name: 'firmasteHipoteca',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'Debe haber firmado su hipoteca antes del 31 de diciembre de 2022 para calificar.'
  },
  {
    label: '¿Es su vivienda habitual?',
    name: 'viviendaHabitual',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'Debe ser su vivienda principal y habitual y estar empadronado'
  },
  {
    label: '¿Tiene su hipoteca fecha de subasta?',
    name: 'fechaSubasta',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'Si su hipoteca tiene fecha de subasta no podrá acogerse al Código de Buenas Prácticas.'
  },
  {
    label: '¿Con qué banco tiene la hipoteca? Busca la hipoteca en la lista.',
    name: 'bancoHipoteca',
    type: 'autocomplete',
    options: bankOptions,
    explanation: 'Seleccione el banco con el que tiene su hipoteca.'
  },
  {
    label: '¿Existe algún grado de discapacidad en la unidad familiar?',
    name: 'gradoDiscapacidad',
    type: 'select',
    options: [
      'No',
      'Sí, superior al 33%',
      'Sí, superior al 65%',
      'Sí, discapacidad intelectual o enfermedad mental igual o superior al 33%',
      'Sí, discapacidad física o sensorial igual o superior al 65%'
    ],
    explanation: 'El grado de discapacidad afecta al límite de ingresos permitido y a la consideración de familia vulnerable.'
  },
  {
    label: '¿Cuáles son los ingresos Brutos Anuales de tu unidad familiar?',
    name: 'ingresosBrutosAnuales',
    type: 'number',
    explanation: 'Suma todos los ingresos brutos (antes de impuestos) de la unidad familiar durante un año.'
  },
  {
    label: '¿Cuáles son los ingresos Netos Anuales de tu unidad familiar?',
    name: 'ingresosNetosAnuales',
    type: 'number',
    explanation: 'Suma los ingresos netos (después de impuestos) que recibe tu unidad familiar durante un año.'
  },
  {
    label: '¿Es el deudor mayor de 60 años?',
    name: 'mayor60',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'La edad del deudor puede afectar a la consideración de familia vulnerable.'
  },
  {
    label: '¿Ha tenido dificultades para pagar la cuota de su hipoteca en los últimos 4 años?',
    name: 'dificultadesPago',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'Indique si ha tenido problemas para pagar la hipoteca en los últimos 4 años.'
  },
  {
    label: '¿Su familia pertenece a alguno de estos grupos: familias numerosas, monoparentales, con menores, o víctimas de violencia?',
    name: 'grupoFamiliar',
    type: 'radio',
    options: ['Sí', 'No'],
    explanation: 'Seleccione si su familia pertenece a alguno de estos grupos específicos.'
  },
  {
    label: '¿Cuánto pagas de hipoteca al mes?',
    name: 'pagoMensualHipoteca',
    type: 'number',
    explanation: 'Indica cuánto pagas mensualmente por tu hipoteca.'
  },
  {
    label: '¿Cuánto dinero te queda por pagar de la hipoteca (cuantía total)?',
    name: 'cantidadPorPagar',
    type: 'number',
    explanation: 'Indica el importe pendiente total, puedes verlo en la app del banco o en tu último recibo.'
  },
  {
    label: '¿Cuántos años le faltan para pagar la hipoteca?',
    name: 'anosFaltantes',
    type: 'number',
    explanation: 'Indica cuántos años te faltan para terminar de pagar la hipoteca.'
  },
  {
    label: '¿Cuál es el plazo original de tu hipoteca?',
    name: 'plazoOriginalHipoteca',
    type: 'number',
    explanation: 'Indica el plazo original en años que estableciste al firmar la hipoteca.'
  },
  {
    label: '¿Qué interés te están aplicando actualmente?',
    name: 'interesAnual',
    type: 'number',
    explanation: 'Indica el interés actual (Euribor + diferencial) que estás pagando.'
  },
  {
    label: '¿Cuánto pagabas mensualmente de cuota hace 4 años?',
    name: 'cuotaHace4Anos',
    type: 'number',
    explanation: 'Indica la cuota que pagabas hace 4 años para calcular el incremento.'
  },
  {
    label: '¿Cuánto pagabas de cuota en junio 2022?',
    name: 'cuotaJunio2022',
    type: 'number',
    explanation: 'Necesitamos saber la cuota que pagabas en junio 2022 para calcular el beneficio de la carencia.'
  },
  {
    label: '¿Cuánto eran tus Ingresos Netos Anuales hace 4 años?',
    name: 'ingresosHace4Anos',
    type: 'number',
    explanation: 'Indica tus ingresos netos anuales de hace 4 años para calcular el incremento del esfuerzo.'
  }
];
function SimulacionCBP() {
  const navigate = useNavigate();
  const { id } = useParams();

  // Estados
  const [estado, setEstado] = useState('en curso');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [filteredProvinces, setFilteredProvinces] = useState([]);
  const [error, setError] = useState('');
  const [exclusion, setExclusion] = useState(false);
  const [vulnerable, setVulnerable] = useState(false);
  const [interesCarencia, setInteresCarencia] = useState(0);
  const [cuotaNuevaCarencia, setCuotaNuevaCarencia] = useState(0);
  const [ahorroCarencia, setAhorroCarencia] = useState(0);
  const [carencia, setCarencia] = useState('');
  const [loadedData, setLoadedData] = useState(null);

  // Función para calcular 'vulnerable' basada en 'formData'
  const computeVulnerable = (formData) => {
    return (
      (formData.gradoDiscapacidad && formData.gradoDiscapacidad !== 'No') ||
      formData.mayor60 === 'Sí' ||
      formData.grupoFamiliar === 'Sí'
    );
  };

  // Determinar el tipo de pantalla/carencia
  const determineScreen = (formData, vulnerable) => {
    if (!formData.ingresosNetosAnuales || !formData.pagoMensualHipoteca) {
      return 'exclusion';
    }

    const monthlyNetosAnuales = formData.ingresosNetosAnuales / 12;
    const monthlyIngresosHace4Anos = formData.ingresosHace4Anos / 12;

    const currentEffortRate = formData.pagoMensualHipoteca / monthlyNetosAnuales;
    const historicalEffortRate = formData.cuotaHace4Anos / monthlyIngresosHace4Anos;
    const effortIncrease = currentEffortRate / historicalEffortRate;

    const isHighEffort = currentEffortRate >= 0.5;
    const hasSignificantIncrease = effortIncrease > 1.5;

    if (isHighEffort && (vulnerable || hasSignificantIncrease)) {
      return 'carencia5';
    } else if (currentEffortRate >= 0.5) {
      return 'carencia2';
    } else if (currentEffortRate >= 0.3) {
      return 'carencia1';
    }

    return 'exclusion';
  };

  // Efecto para cargar datos existentes
  useEffect(() => {
    const fetchLeadData = async () => {
      if (!id) {
        setIsLoading(false);
        return;
      }

      try {
        const { data: leadData, error } = await supabase
          .from('leads')
          .select('*')
          .eq('id', id)
          .single();

        if (error) {
          console.error('Error fetching lead data:', error);
          setIsLoading(false);
          return;
        }

        if (leadData) {
          setLoadedData(leadData);
          setFormData({
            ...initialFormData,
            ...leadData
          });
          
          if (leadData.Carencia) {
            setCarencia(leadData.Carencia);
            setInteresCarencia(leadData.InteresCarencia);
            setCuotaNuevaCarencia(leadData.NuevaCuota);
            setAhorroCarencia(leadData.ahorroCarencia);
            setEstado('Lead simulador completo');
          }
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setIsLoading(false);
      }
    };

    fetchLeadData();
  }, [id]);

  // Efecto principal que maneja la carga de datos y cálculos
  useEffect(() => {
    const loadAndProcessData = async () => {
      if (isLoading) {
        return;
      }

      const isVulnerable = computeVulnerable(formData);
      setVulnerable(isVulnerable);

      if (!isLoading && estado === 'en curso') {
        const newScreen = determineScreen(formData, isVulnerable);
        let years = 0;
        let nuevoInteres = 0;

        switch (newScreen) {
          case 'carencia5':
            nuevoInteres = parseFloat((2.6 - 0.10).toFixed(2));
            years = 5;
            break;
          case 'carencia2':
            nuevoInteres = parseFloat((formData.interesAnual - 0.5).toFixed(2));
            years = 2;
            break;
          case 'carencia1':
            nuevoInteres = parseFloat((formData.interesAnual - 0.5).toFixed(2));
            years = 1;
            break;
          default:
            break;
        }

        setInteresCarencia(nuevoInteres);

        let nuevaCuota = (formData.cantidadPorPagar * (nuevoInteres / 100)) / 12;
        if (newScreen === 'carencia1' && nuevaCuota < formData.cuotaJunio2022) {
          nuevaCuota = formData.cuotaJunio2022;
        }

        setCuotaNuevaCarencia(Math.round(nuevaCuota));
        const ahorro = (formData.pagoMensualHipoteca - nuevaCuota) * 12 * years;
        setAhorroCarencia(ahorro.toLocaleString('es-ES', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
        setCarencia(newScreen);
      }
    };

    loadAndProcessData();
  }, [isLoading, formData, estado]);

  // Manejadores de eventos
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
  
    setFormData(prevData => ({
      ...prevData,
      [name]: newValue
    }));
  
    if (name === 'bancoHipoteca') {
      const filtered = bankOptions.filter(bank =>
        bank.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredOptions(filtered);
    }
  };

  const handleFormDataUpdate = (newData) => {
    setFormData(newData);
    
    const isVulnerable = computeVulnerable(newData);
    setVulnerable(isVulnerable);
    
    const newScreen = determineScreen(newData, isVulnerable);
    setCarencia(newScreen);

    let nuevoInteres = 0;
    let years = 0;

    switch (newScreen) {
      case 'carencia5':
        nuevoInteres = 2.5;
        years = 5;
        break;
      case 'carencia2':
        nuevoInteres = parseFloat((newData.interesAnual - 0.5).toFixed(2));
        years = 2;
        break;
      case 'carencia1':
        nuevoInteres = parseFloat((newData.interesAnual - 0.5).toFixed(2));
        years = 1;
        break;
      default:
        break;
    }

    setInteresCarencia(nuevoInteres);

    let nuevaCuota = (newData.cantidadPorPagar * (nuevoInteres / 100)) / 12;
    if (newScreen === 'carencia1' && nuevaCuota < newData.cuotaJunio2022) {
      nuevaCuota = newData.cuotaJunio2022;
    }

    setCuotaNuevaCarencia(Math.round(nuevaCuota));
    const ahorro = (newData.pagoMensualHipoteca - nuevaCuota) * 12 * years;
    setAhorroCarencia(ahorro.toLocaleString('es-ES', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));
  };
  const handleOptionClick = (option) => {
    setFormData(prevData => ({
      ...prevData,
      bancoHipoteca: option
    }));
    setFilteredOptions([]);
  };

  const handleNext = () => {
    const currentQuestionObj = questions[currentQuestion];
    if (currentQuestionObj.name === 'bancoHipoteca' &&
      !bankOptions.includes(formData.bancoHipoteca)) {
      setModalContent('Por favor seleccione una opción válida.');
      setIsModalOpen(true);
      return;
    }
    setCurrentQuestion(currentQuestion + 1);
  };

  const handlePrev = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    
    if (!id) {
      console.error('No ID provided');
      return;
    }

    setEstado('Lead simulador completo');
    
    const isVulnerable = computeVulnerable(formData);
    setVulnerable(isVulnerable);
    const newScreen = determineScreen(formData, isVulnerable);
    setCarencia(newScreen);
    
    let nuevoInteres = 0;
    let years = 0;

    switch (newScreen) {
      case 'carencia5':
        nuevoInteres = 2.5;
        years = 5;
        break;
      case 'carencia2':
        nuevoInteres = parseFloat((formData.interesAnual - 0.5).toFixed(2));
        years = 2;
        break;
      case 'carencia1':
        nuevoInteres = parseFloat((formData.interesAnual - 0.5).toFixed(2));
        years = 1;
        break;
      default:
        break;
    }

    let nuevaCuota = (formData.cantidadPorPagar * (nuevoInteres / 100)) / 12;
    if (newScreen === 'carencia1' && nuevaCuota < formData.cuotaJunio2022) {
      nuevaCuota = formData.cuotaJunio2022;
    }

    const ahorro = (formData.pagoMensualHipoteca - nuevaCuota) * 12 * years;

    try {
      const updateData = {
        ...formData,
        Carencia: newScreen,
        NuevaCuota: Math.round(nuevaCuota),
        InteresCarencia: nuevoInteres,
        ahorroCarencia: ahorro,
        estado: 'Lead simulador completo',
        updated_at: new Date().toISOString()
      };

      const { error } = await supabase
        .from('leads')
        .update(updateData)
        .eq('id', id);

      if (error) {
        console.error('Error updating lead:', error);
      } else {
        setInteresCarencia(nuevoInteres);
        setCuotaNuevaCarencia(Math.round(nuevaCuota));
        setAhorroCarencia(ahorro.toLocaleString('es-ES', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleModalOpen = (content) => {
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  const navigateToPricing = () => {
    navigate(`/pricing-${carencia}`);
  };

  if (isLoading) {
    return (
      <div className="flex min-h-screen bg-gray-50 justify-center items-center">
        Cargando...
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6 bg-gray-50 overflow-y-auto">
      <div className="flex-grow p-2 flex justify-center items-center">
        <div className="bg-white shadow-lg rounded-lg p-2 w-full max-w-7xl">
          {loadedData?.Carencia ? (
            // Si hay datos con Carencia, mostrar resultados
            <ResultadoCarencia
              years={loadedData.Carencia === 'carencia5' ? 5 : 
                     loadedData.Carencia === 'carencia2' ? 2 : 1}
              interesCarencia={loadedData.InteresCarencia}
              cuotaNuevaCarencia={loadedData.NuevaCuota}
              pagoMensualHipoteca={loadedData.pagoMensualHipoteca}
              interesAnual={loadedData.interesAnual}
              ahorroCarencia={loadedData.ahorroCarencia}
              formData={formData}
              carencia={loadedData.Carencia}
              onFormDataUpdate={handleFormDataUpdate}
              id={id}
            />
          ) : estado === 'Lead simulador completo' ? (
            // Si el estado es completo pero no hay Carencia, mostrar resultados calculados
            carencia !== 'exclusion' ? (
              <ResultadoCarencia
                years={carencia === 'carencia5' ? 5 : carencia === 'carencia2' ? 2 : 1}
                interesCarencia={parseFloat(interesCarencia).toFixed(2)}
                cuotaNuevaCarencia={cuotaNuevaCarencia}
                pagoMensualHipoteca={formData.pagoMensualHipoteca}
                interesAnual={formData.interesAnual}
                ahorroCarencia={ahorroCarencia}
                handleModalOpen={handleModalOpen}
                navigateToPricing={navigateToPricing}
                formData={formData}
                carencia={carencia}
                onFormDataUpdate={handleFormDataUpdate}
                id={id}
              />
            ) : (
              <PantallaExclusion
                formData={formData}
                handleRetry={() => window.location.reload()}
                mostrarResumen={true}
              />
            )
          ) : (
            // Si no hay datos ni resultados, mostrar el formulario
            exclusion ? (
              <PantallaExclusion
                formData={formData}
                handleRetry={() => window.location.reload()}
                mostrarResumen={false}
              />
            ) : (
              <FormularioSimulacion
                questions={questions}
                currentQuestion={currentQuestion}
                formData={formData}
                handleChange={handleChange}
                handleNext={handleNext}
                handlePrev={handlePrev}
                handleSubmit={handleSubmit}
                handleModalOpen={handleModalOpen}
                filteredOptions={filteredOptions}
                filteredProvinces={filteredProvinces}
                handleOptionClick={handleOptionClick}
                error={error}
                progress={(currentQuestion / questions.length) * 100}
              />
            )
          )}
        </div>
      </div>
      <ModalInformacion
        isModalOpen={isModalOpen}
        modalContent={modalContent}
        handleModalClose={handleModalClose}
        formData={formData}
        carencia={carencia}
      />
    </div>
  );
}

export default SimulacionCBP;