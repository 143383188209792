import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { debounce } from 'lodash';
import { supabase } from '../../utils/supabaseClient';
import {
  InformationCircleIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/solid';

const MetricaCircular = ({ titulo, valor, unidad, dashoffset, subtexto }) => (
  <div className="flex flex-col items-center mb-4 md:mb-0 w-full md:w-1/4 px-2">
    <div className="border border-gray-300 p-3 rounded-md flex flex-col items-center w-full bg-white hover:shadow-md transition-shadow">
      <span className="text-lg text-gray-700 mb-3 font-bold text-center">{titulo}</span>
      <div className="relative w-32 h-32">
        <svg className="absolute top-0 left-0 w-full h-full transform -rotate-90">
          <circle 
            className="text-purple-100" 
            strokeWidth="8" 
            stroke="currentColor" 
            fill="transparent" 
            r="45" 
            cx="64" 
            cy="64" 
          />
          <circle 
            className="text-purple-600 transition-all duration-1000 ease-in-out" 
            strokeWidth="8" 
            strokeDasharray="283" 
            strokeDashoffset={dashoffset} 
            strokeLinecap="round" 
            stroke="currentColor" 
            fill="transparent" 
            r="45" 
            cx="64" 
            cy="64" 
          />
        </svg>
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <span className="text-2xl font-bold text-gray-700">
            {typeof valor === 'number' ? Math.round(valor) : valor}
          </span>
          <span className="text-sm text-gray-500">{unidad}</span>
        </div>
      </div>
      {subtexto && (
        <span className="mt-2 text-sm text-gray-500 text-center">{subtexto}</span>
      )}
    </div>
  </div>
);

const MetricaCircularCalculador = ({ 
  titulo, 
  valor, 
  unidad, 
  dashoffset, 
  selectedAmpliacion, 
  maxAmpliacion, 
  setSelectedAmpliacion, 
  diferenciaAnual, 
  anosFaltantes 
}) => (
  <div className="flex flex-col items-center mb-4 md:mb-0 w-full md:w-1/4 px-2">
    <div className="border border-gray-300 p-3 rounded-md flex flex-col items-center w-full">
      <span className="text-lg text-gray-700 mb-3 font-bold text-center">{titulo}</span>
      <div className="relative w-32 h-32">
        <svg className="absolute top-0 left-0 w-full h-full transform -rotate-90">
          <circle 
            className="text-purple-100" 
            strokeWidth="8" 
            stroke="currentColor" 
            fill="transparent" 
            r="45" 
            cx="64" 
            cy="64" 
          />
          <circle 
            className="text-purple-600" 
            strokeWidth="8" 
            strokeDasharray="283" 
            strokeDashoffset={dashoffset} 
            strokeLinecap="round" 
            stroke="currentColor" 
            fill="transparent" 
            r="45" 
            cx="64" 
            cy="64" 
          />
        </svg>
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <span className="text-2xl font-bold text-gray-700">{Math.round(valor)}</span>
          <span className="text-sm text-gray-500">{unidad}</span>
        </div>
      </div>
      <div className="mt-3 w-full space-y-3">
        <div className="space-y-2">
          <div className="flex justify-between text-xs text-gray-600">
            <span>0 años</span>
            <span>{maxAmpliacion} años</span>
          </div>
          <input
            type="range"
            min="0"
            max={maxAmpliacion}
            value={selectedAmpliacion}
            onChange={(e) => setSelectedAmpliacion(Number(e.target.value))}
            className="w-full h-2 bg-purple-200 rounded-lg appearance-none cursor-pointer"
          />
          <div className="text-center text-purple-700 text-sm font-semibold">
            Ampliación: {selectedAmpliacion} años
          </div>
        </div>
        {selectedAmpliacion > 0 && (
          <div className="text-center text-xs text-gray-600 space-y-1">
            <p>Plazo total: {anosFaltantes + selectedAmpliacion} años</p>
            <p>Diferencia mensual: {diferenciaAnual}€</p>
          </div>
        )}
      </div>
    </div>
  </div>
);

const BeneficiosCarencia = ({ tipoCBP, years }) => {
  const beneficios = tipoCBP === 'nuevo' ? {
    titulo: 'Beneficios del Nuevo CBP (RD 19/2022)',
    items: [
      'Reducción del 0,5% sobre el interés actual',
      'Posibilidad de tipo fijo según entidad',
      'Carencia durante 12 meses',
      'Ampliación hasta 7 años (máximo 40 años)',
    ]
  } : {
    titulo: 'Beneficios del CBP Antiguo (RD 6/2012)',
    items: [
      years === 5 ? 'Reducción a euribor -0,10' : 'Reducción del 0,5% sobre el interés actual',
      `Carencia de ${years} ${years === 1 ? 'año' : 'años'}`,
      'Ampliación hasta 40 años',
      'Posibilidad de una segunda carencia en el futuro',
    ]
  };

  return (
    <div className="bg-white rounded-lg p-4 shadow-sm">
      <h3 className="text-lg font-bold mb-3">{beneficios.titulo}</h3>
      <ul className="space-y-2">
        {beneficios.items.map((item, index) => (
          <li key={index} className="flex items-center text-gray-700">
            <CheckCircleIcon className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const ResumenDatos = ({ formData, onDataChange }) => {
  const datosMostrar = [
    { 
      label: 'Datos de la hipoteca', 
      items: [
        { 
          label: 'Entidad bancaria', 
          name: 'bancoHipoteca',
          value: formData.bancoHipoteca,
          type: 'text'
        },
        { 
          label: 'Capital pendiente', 
          name: 'cantidadPorPagar',
          value: formData.cantidadPorPagar,
          type: 'number',
          suffix: '€'
        },
        { 
          label: 'Cuota actual', 
          name: 'pagoMensualHipoteca',
          value: formData.pagoMensualHipoteca,
          type: 'number',
          suffix: '€'
        },
        { 
          label: 'Tipo de interés', 
          name: 'interesAnual',
          value: formData.interesAnual,
          type: 'number',
          suffix: '%'
        },
        { 
          label: 'Plazo restante', 
          name: 'anosFaltantes',
          value: formData.anosFaltantes,
          type: 'number',
          suffix: 'años'
        },
      ]
    },
    { 
      label: 'Datos económicos', 
      items: [
        { 
          label: 'Ingresos netos anuales', 
          name: 'ingresosNetosAnuales',
          value: formData.ingresosNetosAnuales,
          type: 'number',
          suffix: '€'
        },
        { 
          label: 'Cuota junio 2022', 
          name: 'cuotaJunio2022',
          value: formData.cuotaJunio2022,
          type: 'number',
          suffix: '€'
        },
      ]
    },
    { 
      label: 'Situación familiar', 
      items: [
        { 
          label: 'Discapacidad', 
          name: 'gradoDiscapacidad',
          value: formData.gradoDiscapacidad,
          type: 'select',
          options: [
            'No',
            'Sí, superior al 33%',
            'Sí, superior al 65%',
            'Sí, discapacidad intelectual o enfermedad mental igual o superior al 33%',
            'Sí, discapacidad física o sensorial igual o superior al 65%'
          ]
        },
        { 
          label: 'Mayor de 60 años', 
          name: 'mayor60',
          value: formData.mayor60,
          type: 'select',
          options: ['Sí', 'No']
        },
        { 
          label: 'Familia protegida', 
          name: 'grupoFamiliar',
          value: formData.grupoFamiliar,
          type: 'select',
          options: ['Sí', 'No']
        },
      ]
    }
  ];

  const handleInputChange = (name, value) => {
    const numericFields = ['cantidadPorPagar', 'pagoMensualHipoteca', 'interesAnual', 
                          'anosFaltantes', 'ingresosNetosAnuales', 'cuotaJunio2022'];
    const newValue = numericFields.includes(name) ? Number(value) : value;
    onDataChange({ ...formData, [name]: newValue });
  };

  return (
    <div className="bg-white rounded-lg p-6 shadow-sm">
      <h3 className="text-xl font-bold mb-6 text-purple-600">Resumen de tu situación</h3>
      <div className="space-y-6">
        {datosMostrar.map((seccion, index) => (
          <div key={index} className="border-b border-gray-200 pb-4 last:border-b-0">
            <h4 className="text-lg font-semibold mb-3 text-gray-700">{seccion.label}</h4>
            <div className="grid grid-cols-2 gap-4">
              {seccion.items.map((item, itemIndex) => (
                <div key={itemIndex} className="flex flex-col">
                  <label className="text-sm text-gray-500">{item.label}</label>
                  <div className="relative">
                    {item.type === 'select' ? (
                      <select
                        value={item.value}
                        onChange={(e) => handleInputChange(item.name, e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                      >
                        {item.options.map((option, optionIndex) => (
                          <option key={optionIndex} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type={item.type}
                        value={item.value}
                        onChange={(e) => handleInputChange(item.name, e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
                      />
                    )}
                    {item.suffix && (
                      <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500">
                        {item.suffix}
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ResultadoCarencia = ({
  years,
  interesCarencia: initialInteresCarencia,
  cuotaNuevaCarencia: initialCuotaNuevaCarencia,
  pagoMensualHipoteca,
  interesAnual,
  ahorroCarencia: initialAhorroCarencia,
  tipoCBP,
  formData: initialFormData,
  carencia,
  onFormDataUpdate,
  id
}) => {
  const [formData, setFormData] = useState(initialFormData);
  const [selectedAmpliacion, setSelectedAmpliacion] = useState(0);
  const [cuotaCalculada, setCuotaCalculada] = useState(0);
  const [interesCarencia, setInteresCarencia] = useState(initialInteresCarencia);
  const [cuotaNuevaCarencia, setCuotaNuevaCarencia] = useState(initialCuotaNuevaCarencia);
  const [ahorroCarencia, setAhorroCarencia] = useState(initialAhorroCarencia);
  const [isUpdating, setIsUpdating] = useState(false);

  const maxAmpliacion = useMemo(() => {
    const anosHastaMaximo40 = 40 - parseInt(formData.plazoOriginalHipoteca);
    return carencia === 'carencia5' 
      ? Math.max(0, anosHastaMaximo40)
      : Math.min(7, Math.max(0, anosHastaMaximo40));
  }, [carencia, formData.plazoOriginalHipoteca]);

  const recalcularValores = useCallback((newFormData) => {
    let nuevoInteres = 0;
    if (carencia === 'carencia5') {
      nuevoInteres = 2.5;
    } else {
      nuevoInteres = parseFloat((newFormData.interesAnual - 0.5).toFixed(2));
    }
    
    const nuevaCuota = (newFormData.cantidadPorPagar * (nuevoInteres / 100)) / 12;
    let cuotaFinalCarencia = nuevaCuota;
    
    if (carencia === 'carencia1' && nuevaCuota < newFormData.cuotaJunio2022) {
      cuotaFinalCarencia = newFormData.cuotaJunio2022;
    }
    
    const ahorro = (newFormData.pagoMensualHipoteca - cuotaFinalCarencia) * 12 * years;
    
    const mesesTotales = (parseInt(newFormData.anosFaltantes) + selectedAmpliacion) * 12;
    const interesOriginalMensual = (parseFloat(newFormData.interesAnual) / 100) / 12;
    const nuevaCuotaPostCarencia = parseFloat(newFormData.cantidadPorPagar) * 
      (interesOriginalMensual * Math.pow(1 + interesOriginalMensual, mesesTotales)) /
      (Math.pow(1 + interesOriginalMensual, mesesTotales) - 1);

    return {
      interesCarencia: nuevoInteres,
      cuotaNuevaCarencia: Math.round(cuotaFinalCarencia),
      ahorroCarencia: ahorro.toFixed(2),
      cuotaCalculada: Math.round(nuevaCuotaPostCarencia)
    };
  }, [carencia, years, selectedAmpliacion]);

  const debouncedUpdate = useMemo(
    () => debounce(async (newFormData) => {
      if (!id) return;
      
      setIsUpdating(true);
      try {
        const nuevosValores = recalcularValores(newFormData);
        const updateData = {
          ...newFormData,
          Carencia: carencia,
          NuevaCuota: nuevosValores.cuotaNuevaCarencia,
          InteresCarencia: nuevosValores.interesCarencia,
          ahorroCarencia: parseFloat(nuevosValores.ahorroCarencia),
          estado: 'Lead simulador completo',
          updated_at: new Date().toISOString()
        };

        const { error } = await supabase
          .from('leads')
          .update(updateData)
          .eq('id', id);

        if (error) {
          console.error('Error updating lead:', error);
        }
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsUpdating(false);
      }
    }, 1000),
    [id, carencia, recalcularValores]
  );

  useEffect(() => {
    const nuevosValores = recalcularValores(formData);
    setInteresCarencia(nuevosValores.interesCarencia);
    setCuotaNuevaCarencia(nuevosValores.cuotaNuevaCarencia);
    setAhorroCarencia(nuevosValores.ahorroCarencia);
    setCuotaCalculada(nuevosValores.cuotaCalculada);
  }, [formData, recalcularValores]);

  const handleDataChange = (newFormData) => {
    setFormData(newFormData);
    onFormDataUpdate?.(newFormData);
    debouncedUpdate(newFormData);
  };

  const cuotaCompleta = cuotaNuevaCarencia >= pagoMensualHipoteca;
  const dashoffset = { 5: 0, 2: 113.097, 1: 169.646 };
  const strokeDashoffsetCuota = cuotaCompleta 
    ? dashoffset[years] 
    : 377 - (377 * (cuotaNuevaCarencia / pagoMensualHipoteca));
  const safeStrokeDashoffsetCuota = isNaN(strokeDashoffsetCuota) ? 0 : strokeDashoffsetCuota;

  const interesCompleto = interesCarencia >= interesAnual;
  const strokeDashoffsetInteres = interesCompleto 
    ? 0 
    : 377 - (377 * (interesCarencia / interesAnual));
  const safeStrokeDashoffsetInteres = isNaN(strokeDashoffsetInteres) ? 0 : strokeDashoffsetInteres;

  const strokeDashoffsetCalculada = 377 - (377 * (cuotaCalculada / pagoMensualHipoteca));
  const safeStrokeDashoffsetCalculada = isNaN(strokeDashoffsetCalculada) ? 0 : strokeDashoffsetCalculada;

  return (
    <div className="container mx-auto text-left bg-gray-100 p-6 rounded-lg shadow-md max-w-7xl w-full">
      {isUpdating && (
        <div className="fixed top-0 right-0 m-4 bg-purple-600 text-white px-4 py-2 rounded-md shadow-md">
          Actualizando datos...
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        <div className="md:col-span-4 space-y-6">
          <BeneficiosCarencia tipoCBP={tipoCBP} years={years} />
          <ResumenDatos 
            formData={formData} 
            onDataChange={handleDataChange}
          />
        </div>

        <div className="md:col-span-8">
          <div className="bg-white rounded-lg p-6 mb-6">
            <h2 className="text-2xl font-bold text-purple-600 mb-4">¡Buenas noticias!</h2>
            <p className="text-lg text-gray-700 leading-relaxed">
              Puedes acogerte al {tipoCBP === 'nuevo' ? 'nuevo' : 'antiguo'} Código de Buenas Prácticas 
              y conseguir una carencia de 
              <span className="font-bold text-purple-600"> {years} {years === 1 ? 'año' : 'años'}</span>.
              Durante este periodo, tu cuota mensual será de
              <span className="font-bold text-purple-600"> {Math.round(cuotaNuevaCarencia)}€</span>,
              lo que supone un ahorro total de
              <span className="font-bold text-purple-600"> {ahorroCarencia}€</span>.
            </p>
          </div>

          <div className="bg-white rounded-lg p-6">
            <div className="flex flex-wrap md:flex-nowrap justify-center -mx-2">
              <MetricaCircular
                titulo="Periodo de carencia"
                valor={years}
                unidad={years === 1 ? 'año' : 'años'}
                dashoffset={dashoffset[years]}
                subtexto="Duración del beneficio"
              />

              <MetricaCircular
                titulo="Nueva cuota mensual"
                valor={Math.round(cuotaNuevaCarencia)}
                unidad="€/mes"
                dashoffset={safeStrokeDashoffsetCuota}
                subtexto={`Ahorro mensual: ${Math.round(pagoMensualHipoteca - cuotaNuevaCarencia)}€`}
              />

              <MetricaCircular
                titulo="Nuevo tipo de interés"
                valor={interesCarencia}
                unidad="%"
                dashoffset={safeStrokeDashoffsetInteres}
                subtexto={`Reducción: ${(interesAnual - interesCarencia).toFixed(2)}%`}
              />

              <MetricaCircularCalculador
                titulo="Cuota tras carencia"
                valor={cuotaCalculada}
                unidad="€/mes"
                dashoffset={safeStrokeDashoffsetCalculada}
                selectedAmpliacion={selectedAmpliacion}
                maxAmpliacion={maxAmpliacion}
                setSelectedAmpliacion={setSelectedAmpliacion}
                diferenciaAnual={pagoMensualHipoteca - cuotaCalculada}
                anosFaltantes={parseInt(formData.anosFaltantes)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultadoCarencia;