import React, { useEffect, useState, useCallback } from 'react';
import { supabase } from '../utils/supabaseClient';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Confetti from 'react-confetti';
import 'react-datepicker/dist/react-datepicker.css';

const Tareas = ({ leadId, clienteId }) => {
  const [tareas, setTareas] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [newTituloTarea, setNewTituloTarea] = useState('');
  const [newTarea, setNewTarea] = useState('');
  const [assignedUser, setAssignedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState(null);
  const [fechaTarea, setFechaTarea] = useState(new Date());
  const [confetti, setConfetti] = useState(false);
  const [editingTarea, setEditingTarea] = useState(null);
  const [editedTarea, setEditedTarea] = useState('');
  const [tareaComercial, setTareaComercial] = useState(false);
  const [tareaProcesal, setTareaProcesal] = useState(false);

  const fetchTareas = useCallback(async () => {
    let query = supabase.from('tareas').select('*').order('fecha', { ascending: false });

    if (leadId) {
      query = query.or(`lead_id.eq.${leadId}`);
    }
    if (clienteId) {
      query = query.or(`cliente_id.eq.${clienteId}`);
    }

    const { data: tareasData, error } = await query;

    if (error) {
      console.error('Error fetching tareas:', error);
    } else {
      setTareas(tareasData || []);
    }
  }, [leadId, clienteId]);

  useEffect(() => {
    fetchTareas();
  }, [fetchTareas]);

  useEffect(() => {
    const fetchUsers = async () => {
      const { data: usersData } = await supabase
        .from('usuarios')
        .select('user_id, nombre, apellidos');
      setUsers(usersData || []);
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();

      if (sessionError) {
        console.error('Error fetching session:', sessionError);
      } else if (session) {
        const { user } = session;

        const { data, error } = await supabase
          .from('usuarios')
          .select('user_id, nombre, apellidos')
          .eq('user_id', user.id)
          .single();

        if (error) {
          console.error('Error fetching user data:', error);
        } else {
          setUserData(data);
          setAssignedUser({
            value: data.user_id,
            label: `${data.nombre} ${data.apellidos}`
          });
        }
      }
    };

    fetchUserData();
  }, []);

  const toggleNuevaTarea = () => {
    setShowInput(!showInput);
  };

  const handleUserChange = (selectedOption) => {
    setAssignedUser(selectedOption);
  };

  const handleGuardarTarea = async () => {
    if (!userData || !assignedUser || !newTarea || !newTituloTarea) {
      return;
    }

    const usuarioAsignado = users.find(user => user.user_id === assignedUser.value);

    const { error } = await supabase
      .from('tareas')
      .insert([{
        lead_id: leadId,
        cliente_id: clienteId,
        titulo_tarea: newTituloTarea,
        descripcion: newTarea,
        fecha: new Date(),
        fecha_tarea: fechaTarea,
        user_id: userData.user_id,
        usuario_nombre: userData.nombre,
        usuario_apellidos: userData.apellidos,
        user_idasignado: assignedUser.value,
        usuarioasignado_nombre: usuarioAsignado ? usuarioAsignado.nombre : '',
        usuarioasignado_apellidos: usuarioAsignado ? usuarioAsignado.apellidos : '',
        tarea_pendiente: true,
        tarea_realizada: false,
        tarea_comercial: tareaComercial,
        tarea_procesal: tareaProcesal
      }]);

    if (error) {
      console.error('Error al guardar la tarea:', error);
    } else {
      setNewTituloTarea('');
      setNewTarea('');
      setFechaTarea(new Date());
      setShowInput(false);
      setTareaComercial(false);
      setTareaProcesal(false);
      fetchTareas(); // Para recargar la lista de tareas
    }
  };

  const handleMarcarTareaRealizada = async (tareaId) => {
    const { error } = await supabase
      .from('tareas')
      .update({ tarea_pendiente: false, tarea_realizada: true })
      .eq('id', tareaId);

    if (error) {
      console.error('Error al actualizar la tarea:', error);
    } else {
      setConfetti(true);
      fetchTareas(); // Para recargar la lista de tareas
      setTimeout(() => setConfetti(false), 3000); // Desactivar confetti después de 3 segundos
    }
  };

  const handleEditarTarea = (tarea) => {
    setEditingTarea(tarea.id);
    setEditedTarea(tarea.descripcion);
  };

  const handleGuardarEdicion = async (tareaId) => {
    const { error } = await supabase
      .from('tareas')
      .update({ descripcion: editedTarea })
      .eq('id', tareaId);

    if (error) {
      console.error('Error al editar la tarea:', error);
    } else {
      setEditingTarea(null);
      setEditedTarea('');
      fetchTareas(); // Para recargar la lista de tareas
    }
  };

  const handleBorrarTarea = async (tareaId) => {
    const { error } = await supabase
      .from('tareas')
      .delete()
      .eq('id', tareaId);

    if (error) {
      console.error('Error al borrar la tarea:', error);
    } else {
      fetchTareas(); // Para recargar la lista de tareas
    }
  };

  const userOptions = users.map(user => ({
    value: user.user_id,
    label: `${user.nombre} ${user.apellidos}`
  }));

  return (
    <div className="mb-6">
      {confetti && <Confetti />}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold">Tareas</h2>
        <button
          onClick={toggleNuevaTarea}
          className="flex items-center text-white bg-gradient-to-r from-purple-500 to-blue-500 px-4 py-2 rounded-md hover:from-purple-600 hover:to-blue-600"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
          </svg>
          Nueva tarea
        </button>
      </div>

      {showInput && (
        <div className="relative bg-white p-4 rounded-lg shadow-md mb-4">
          <button
            onClick={toggleNuevaTarea}
            className="absolute top-1 right-1 text-black hover:text-gray-700"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          </button>
          <input
            type="text"
            className="w-full mb-2 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
            placeholder="Título de la nueva tarea..."
            value={newTituloTarea}
            onChange={(e) => setNewTituloTarea(e.target.value)}
          />
          <textarea
            className="w-full h-24 p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
            placeholder="Describe la nueva tarea..."
            value={newTarea}
            onChange={(e) => setNewTarea(e.target.value)}
          ></textarea>
          <div className="mt-2">
            <label htmlFor="assignedUser" className="text-sm font-bold text-gray-700">Asignar a:</label>
            <Select
              id="assignedUser"
              options={userOptions}
              value={assignedUser}
              onChange={handleUserChange}
              className="w-full mt-2"
              placeholder="Seleccionar usuario..."
            />
          </div>
          <div className="mt-2">
            <label htmlFor="fechaTarea" className="text-sm font-bold text-gray-700">Fecha de la tarea:</label>
            <DatePicker
              selected={fechaTarea}
              onChange={(date) => setFechaTarea(date)}
              className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <div className="mt-2 flex items-center">
            <input
              type="checkbox"
              id="tareaComercial"
              checked={tareaComercial}
              onChange={(e) => setTareaComercial(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="tareaComercial" className="text-sm font-bold text-gray-700">Tarea comercial</label>
          </div>
          <div className="mt-2 flex items-center">
            <input
              type="checkbox"
              id="tareaProcesal"
              checked={tareaProcesal}
              onChange={(e) => setTareaProcesal(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="tareaProcesal" className="text-sm font-bold text-gray-700">Tarea procesal</label>
          </div>
          <div className="flex justify-end mt-2">
            <button
              onClick={handleGuardarTarea}
              className="bg-gradient-to-r from-purple-500 to-blue-500 text-white px-4 py-2 rounded-md hover:from-purple-600 hover:to-blue-600"
            >
              Guardar tarea
            </button>
          </div>
        </div>
      )}

      <div className="space-y-2">
        {tareas.map((tarea) => (
          <div key={tarea.id} className="relative flex items-center bg-white p-4 rounded-lg shadow-md">
            <div className="mr-4">
              <button
                onClick={() => handleMarcarTareaRealizada(tarea.id)}
                className={`w-8 h-8 rounded-full ${tarea.tarea_realizada ? 'bg-green-500' : 'bg-gray-300 hover:bg-green-300'}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-full h-full text-white"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="flex flex-col flex-grow">
              <div className="flex justify-between items-center mb-2">
                <p className={`text-sm font-bold ${tarea.tarea_realizada ? 'line-through' : ''}`}>{tarea.titulo_tarea}</p>
                <div className="flex items-center">
                  <p className="text-sm text-gray-500 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-1">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                    </svg>
                    {new Date(tarea.fecha_tarea).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' })}
                  </p>
                  <button
                    onClick={() => handleBorrarTarea(tarea.id)}
                    className="ml-2 text-gray-500 hover:text-red-700"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                      <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                    </svg>
                  </button>
                </div>
              </div>
              {editingTarea === tarea.id ? (
                <div className="flex items-center mb-2">
                  <textarea
                    className="w-full p-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
                    value={editedTarea}
                    onChange={(e) => setEditedTarea(e.target.value)}
                  />
                  <button
                    onClick={() => handleGuardarEdicion(tarea.id)}
                    className="ml-2 text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-md"
                  >
                    Guardar
                  </button>
                </div>
              ) : (
                <>
                  <p
                    className={`text-sm text-gray-500 mb-2 ${tarea.tarea_realizada ? 'line-through' : ''}`}
                    onClick={() => handleEditarTarea(tarea)}
                  >
                    {tarea.descripcion}
                  </p>
                  <p className="text-xs text-gray-400 mb-2">Tarea asignada a {tarea.usuarioasignado_nombre} {tarea.usuarioasignado_apellidos}</p>
                </>
              )}
            </div>
            <div className="absolute bottom-2 right-2 text-sm text-gray-500 flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 mr-1">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>
              Tarea creada por {tarea.usuario_nombre} {tarea.usuario_apellidos}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tareas;
