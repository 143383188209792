import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPhone, 
  faPhoneVolume,
  faPlay, 
  faFileLines,
  faChevronDown,
  faChevronUp 
} from '@fortawesome/free-solid-svg-icons';

const CentralizadorLlamadas = () => {
  const [llamadas, setLlamadas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transcripcionVisible, setTranscripcionVisible] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [llamadasAircall, setLlamadasAircall] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000), // Última semana por defecto
    endDate: new Date()
  });

  useEffect(() => {
    fetchLlamadas();
  }, [dateRange]);

  const fetchLlamadas = async () => {
    setLoading(true);
    try {
      const params = {
        from: Math.floor(dateRange.startDate.getTime() / 1000),
        to: Math.floor(dateRange.endDate.getTime() / 1000)
      };

      const response = await axios.get('https://backend.codigobuenaspracticas.es/api/aircall/calls', {
        params
      });
      
      if (!response.data) {
        toast.error('La respuesta del servidor no tiene el formato esperado');
        setLlamadasAircall([]);
        return;
      }

      setLlamadasAircall(response.data);
      
      if (response.data.length > 0) {
        toast.success(`Se cargaron ${response.data.length} llamadas`);
      } else {
        toast.info('No se encontraron llamadas en el rango de fechas seleccionado');
      }

    } catch (error) {
      console.error('Error al obtener llamadas:', error);
      toast.error('Error al cargar las llamadas: ' + (error.message || 'Error desconocido'));
      setLlamadasAircall([]);
    } finally {
      setLoading(false);
    }
  };

  const loadCallDetails = async (llamadaId) => {
    if (!expandedRows.has(llamadaId)) {
      try {
        const [transcriptionRes, summaryRes] = await Promise.all([
          axios.get(`https://backend.codigobuenaspracticas.es/api/aircall/calls/${llamadaId}/transcription`),
          axios.get(`https://backend.codigobuenaspracticas.es/api/aircall/calls/${llamadaId}/summary`)
        ]);

        setLlamadasAircall(prevLlamadas => 
          prevLlamadas.map(llamada => 
            llamada.id === llamadaId 
              ? {
                  ...llamada,
                  transcripcion: transcriptionRes.data.transcription,
                  resumen: summaryRes.data.summary
                }
              : llamada
          )
        );
      } catch (error) {
        console.error('Error al cargar detalles de la llamada:', error);
        toast.error('Error al cargar los detalles de la llamada');
      }
    }
    toggleRow(llamadaId);
  };

  const toggleRow = (id) => {
    setExpandedRows(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  const getTipoLlamadaIcon = (tipo) => {
    return tipo === 'entrante' ? 
      <FontAwesomeIcon icon={faPhone} className="text-green-500" /> : 
      <FontAwesomeIcon icon={faPhoneVolume} className="text-blue-500" />;
  };

  const formatearFecha = (fecha) => {
    return new Date(fecha).toLocaleString('es-ES', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const filtrarLlamadas = () => {
    return llamadasAircall.filter(llamada => {
      const searchTermLower = searchTerm.toLowerCase();
      return (
        llamada.tipo.toLowerCase().includes(searchTermLower) ||
        (llamada.usuario_emisor?.nombre || '').toLowerCase().includes(searchTermLower) ||
        (llamada.usuario_receptor?.nombre || '').toLowerCase().includes(searchTermLower) ||
        llamada.telefono_emisor?.toLowerCase().includes(searchTermLower) ||
        llamada.telefono_receptor?.toLowerCase().includes(searchTermLower) ||
        formatearFecha(llamada.fecha).toLowerCase().includes(searchTermLower)
      );
    });
  };

  const renderEstadoLlamada = (estado) => {
    const estados = {
      'done': 'Completada',
      'missed': 'Perdida',
      'voicemail': 'Buzón de voz'
    };
    return (
      <span className={`px-2 py-1 rounded text-sm ${
        estado === 'done' ? 'bg-green-100 text-green-800' :
        estado === 'missed' ? 'bg-red-100 text-red-800' :
        'bg-gray-100 text-gray-800'
      }`}>
        {estados[estado] || estado}
      </span>
    );
  };

  const renderRow = (llamada) => (
    <React.Fragment key={llamada.id}>
      <tr className="border-b hover:bg-gray-50 cursor-pointer" 
          onClick={() => loadCallDetails(llamada.id)}>
        <td className="px-4 py-3">
          {getTipoLlamadaIcon(llamada.tipo)}
        </td>
        <td className="px-4 py-3">
          {formatearFecha(llamada.fecha)}
        </td>
        <td className="px-4 py-3">
          {llamada.tipo === 'entrante' ? 
            llamada.telefono_emisor : 
            llamada.usuario_emisor?.nombre || llamada.telefono_emisor}
        </td>
        <td className="px-4 py-3">
          {llamada.tipo === 'saliente' ? 
            llamada.telefono_receptor : 
            llamada.usuario_receptor?.nombre || llamada.telefono_receptor}
        </td>
        <td className="px-4 py-3">
          {renderEstadoLlamada(llamada.estado)}
        </td>
        <td className="px-4 py-3">
          {Math.floor(llamada.duracion / 60)}:{(llamada.duracion % 60).toString().padStart(2, '0')}
        </td>
        <td className="px-4 py-3 text-center">
          <FontAwesomeIcon 
            icon={expandedRows.has(llamada.id) ? faChevronUp : faChevronDown} 
            className="text-gray-500"
          />
        </td>
      </tr>
      
      {expandedRows.has(llamada.id) && (
        <tr>
          <td colSpan="7" className="px-4 py-3 bg-gray-50">
            <div className="p-4">
              {llamada.descripcion && (
                <div className="mb-4">
                  <h4 className="font-semibold mb-2">Descripción:</h4>
                  <p>{llamada.descripcion}</p>
                </div>
              )}
              
              {llamada.transcripcion ? (
                <div className="mb-4">
                  <h4 className="font-semibold mb-2">Transcripción:</h4>
                  <p className="bg-white p-2 rounded">{llamada.transcripcion}</p>
                </div>
              ) : (
                <div className="mb-4">
                  <p className="text-gray-500 italic">
                    {llamada.hasTranscription ? 'Cargando transcripción...' : 'Transcripción no disponible'}
                  </p>
                </div>
              )}
              
              {llamada.resumen ? (
                <div className="mb-4">
                  <h4 className="font-semibold mb-2">Resumen AI:</h4>
                  <p className="bg-white p-2 rounded">{llamada.resumen}</p>
                </div>
              ) : (
                <div className="mb-4">
                  <p className="text-gray-500 italic">
                    {llamada.hasSummary ? 'Cargando resumen...' : 'Resumen no disponible'}
                  </p>
                </div>
              )}
              
              <div className="flex gap-4">
                {llamada.url_grabacion && (
                  <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faPlay} className="text-gray-500" />
                    <audio controls className="w-64">
                      <source src={llamada.url_grabacion} type="audio/mpeg" />
                    </audio>
                  </div>
                )}
              </div>
            </div>
          </td>
        </tr>
      )}
    </React.Fragment>
  );

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-6">Centralizador de Llamadas</h1>
      
      <div className="mb-4 flex gap-4 items-center">
        <div>
          <label className="block text-sm font-medium text-gray-700">Desde</label>
          <input
            type="date"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            value={dateRange.startDate.toISOString().split('T')[0]}
            onChange={(e) => setDateRange(prev => ({
              ...prev,
              startDate: new Date(e.target.value)
            }))}
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Hasta</label>
          <input
            type="date"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            value={dateRange.endDate.toISOString().split('T')[0]}
            onChange={(e) => setDateRange(prev => ({
              ...prev,
              endDate: new Date(e.target.value)
            }))}
          />
        </div>
        <button
          onClick={fetchLlamadas}
          className="mt-6 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700"
        >
          Actualizar
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white rounded-lg overflow-hidden">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-3 text-left">Tipo</th>
                <th className="px-4 py-3 text-left">Fecha</th>
                <th className="px-4 py-3 text-left">De</th>
                <th className="px-4 py-3 text-left">Para</th>
                <th className="px-4 py-3 text-left">Estado</th>
                <th className="px-4 py-3 text-left">Duración</th>
                <th className="px-4 py-3 text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              {filtrarLlamadas().map(renderRow)}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default CentralizadorLlamadas;
