// FormularioSimulacion.js
import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

const FormularioSimulacion = ({
  questions,
  currentQuestion,
  formData,
  handleChange,
  handleNext,
  handlePrev,
  handleSubmit,
  handleModalOpen,
  filteredOptions,
  filteredProvinces,
  handleOptionClick,
  error,
  progress
}) => {
  // Validación inicial
  if (!questions || !Array.isArray(questions) || questions.length === 0 || currentQuestion >= questions.length) {
    return <div>No hay preguntas disponibles</div>;
  }

  const currentQuestionData = questions[currentQuestion];
  
  // Validación de la pregunta actual
  if (!currentQuestionData || !currentQuestionData.label) {
    return <div>Error en la pregunta actual</div>;
  }

  const renderInput = (question) => {
    if (!question || !question.type) return null;

    switch (question.type) {
      case 'radio':
        return (
          <>
            {question.options && question.options.map((option) => (
              <div key={option} className="mb-2">
                <input
                  type="radio"
                  id={`${question.name}-${option}`}
                  name={question.name}
                  value={option}
                  checked={formData[question.name] === option}
                  onChange={handleChange}
                  className="hidden"
                />
                <label
                  htmlFor={`${question.name}-${option}`}
                  className={`block cursor-pointer py-2 px-4 rounded-md border text-center text-sm md:text-base lg:text-lg 
                    ${formData[question.name] === option 
                      ? 'bg-blue-600 text-white border-blue-600' 
                      : 'bg-blue-100 text-blue-600 border-blue-600'}`}
                >
                  {option}
                </label>
              </div>
            ))}
          </>
        );

      case 'select':
        return (
          <select
            name={question.name}
            value={formData[question.name] || ''}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg"
          >
            <option value="">Seleccione una opción</option>
            {question.options && question.options.map((option) => (
              <option key={option} value={option}>
                {question.optionsText ? question.optionsText[option] : option}
              </option>
            ))}
          </select>
        );

      case 'autocomplete':
        return (
          <div className="relative">
            <input
              type="text"
              name={question.name}
              value={formData[question.name] || ''}
              onChange={handleChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg"
              placeholder="Escribe para buscar..."
            />
            {filteredOptions && filteredOptions.length > 0 && (
              <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-md shadow-lg mt-1 max-h-60 overflow-y-auto">
                {filteredOptions.map((option) => (
                  <div
                    key={option}
                    className="py-2 px-4 hover:bg-gray-100 cursor-pointer text-sm md:text-base lg:text-lg"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        );

      case 'number':
        return (
          <input
            type="number"
            name={question.name}
            value={formData[question.name] || ''}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-3 px-4 text-sm md:text-base lg:text-lg no-spin"
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <h2 className="text-2xl font-bold mb-4 text-center">Simulación CBP</h2>
      <div className="relative w-full h-2 bg-gray-300 rounded-full mb-6">
        <div
          className="absolute top-0 left-0 h-2 bg-purple-600 rounded-full"
          style={{ width: `${progress}%` }}
        />
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <div className="flex justify-between items-center mb-4 relative">
            <label className="block text-gray-700 text-sm md:text-base lg:text-lg">
              {currentQuestionData.label}
            </label>
            {currentQuestionData.explanation && (
              <InformationCircleIcon
                className="w-6 h-6 text-gray-400 hover:text-gray-600 cursor-pointer flex-shrink-0"
                onClick={() => handleModalOpen(currentQuestionData.explanation)}
              />
            )}
          </div>
          {renderInput(currentQuestionData)}
          <div className="flex justify-between mt-6">
            <button
              type="button"
              onClick={handlePrev}
              className={`py-2 px-6 rounded-md text-sm md:text-base lg:text-lg 
                ${currentQuestion === 0
                  ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
                  : 'bg-purple-600 text-white hover:bg-purple-700'}`}
              disabled={currentQuestion === 0}
            >
              Atrás
            </button>
            <button
              type="button"
              onClick={currentQuestion === questions.length - 1 ? handleSubmit : handleNext}
              className={`py-2 px-6 rounded-md text-sm md:text-base lg:text-lg 
                ${formData[currentQuestionData.name]
                  ? 'bg-purple-600 text-white hover:bg-purple-700'
                  : 'bg-gray-400 text-gray-700 cursor-not-allowed'}`}
              disabled={!formData[currentQuestionData.name]}
            >
              {currentQuestion === questions.length - 1 ? 'Ver resultado' : 'Siguiente'}
            </button>
          </div>
        </div>
      </form>
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
    </>
  );
};

export default FormularioSimulacion;