// src/components/SignIn.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { supabase } from '../utils/supabaseClient';
import { AuthContext } from '../contexts/AuthContext';
import logo from '../assets/logo.png';

function SignIn() {
    const [credentials, setCredentials] = useState({ email: '', password: '' });
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();
    const { setUser } = useContext(AuthContext);

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const { data, error } = await supabase.auth.signInWithPassword({
                email: credentials.email,
                password: credentials.password
            });

            if (error) {
                toast.error(error.message);
                return;
            }

            const { user } = data;
            const { data: usuario, error: usuarioError } = await supabase
                .from('usuarios')
                .select('*')
                .eq('email', user.email)
                .single();

            if (usuarioError || !usuario) {
                toast.error("Espacio reservado solo para empleados de CBP");
                await supabase.auth.signOut();
                return;
            }

            toast.success("Inicio de sesión exitoso!");
            setUser(user);
            localStorage.setItem('user', JSON.stringify(user));
            navigate('/usuarios');  // Redirige a la página de inicio o cualquier otra

        } catch (err) {
            console.error('Error iniciando sesión:', err);
            toast.error("Error iniciando sesión. Por favor, inténtalo de nuevo.");
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <section className="py-16 xl:pb-56 bg-white overflow-hidden">
            <div className="container px-4 mx-auto">
                <div className="text-center max-w-md mx-auto">
                    <a className="mb-36 inline-block" href="#">
                        <img src={logo} alt="Logo" />
                    </a>
                    <h2 className="mb-4 text-6xl md:text-7xl text-center font-bold font-heading tracking-px-n leading-tight">Accede a tu cuenta</h2>
                    <form onSubmit={handleSubmit}>
                        <label className="block mb-5">
                            <input className="px-4 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300"
                                   type="email"
                                   placeholder="Correo electrónico"
                                   name="email"
                                   onChange={handleChange} />
                        </label>
                        <label className="relative block mb-5">
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer" onClick={togglePasswordVisibility}>
                                {showPassword ? <FaEyeSlash /> : <FaEye />}
                            </div>
                            <input className="px-4 pr-10 py-3.5 w-full text-gray-500 font-medium placeholder-gray-500 bg-white outline-none border border-gray-300 rounded-lg focus:ring focus:ring-indigo-300"
                                   type={showPassword ? "text" : "password"}
                                   placeholder="Contraseña"
                                   name="password"
                                   onChange={handleChange} />
                        </label>
                        <button className="mb-8 py-4 px-9 w-full text-white font-semibold border border-indigo-700 rounded-xl shadow-4xl focus:ring focus:ring-indigo-300 bg-indigo-600 hover:bg-indigo-700 transition ease-in-out duration-200" type="submit">Entrar</button>
                        <p className="font-medium">
                            <a className="text-indigo-600 hover:text-indigo-700" href="/signup">Crea una cuenta ahora</a>
                        </p>
                        <p className="mt-4 font-medium">
                            <a className="text-indigo-600 hover:text-indigo-700" href="/resetpassword">Resetear contraseña</a>
                        </p>
                    </form>
                </div>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                transition={Slide}
            />
        </section>
    );
}

export default SignIn;
