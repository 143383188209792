import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useTable, useSortBy, useGlobalFilter } from 'react-table';
import { supabase } from '../../utils/supabaseClient';
import { Link } from 'react-router-dom';

const TusTareas = () => {
  const [tareas, setTareas] = useState([]);
  const [userData, setUserData] = useState(null);
  const [selectedTareas, setSelectedTareas] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [activeTab, setActiveTab] = useState('todas');
  const rowsPerPage = 10;

  const fetchUserData = useCallback(async () => {
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();

    if (sessionError) {
      console.error('Error fetching session:', sessionError);
    } else if (session) {
      const { user } = session;

      const { data, error } = await supabase
        .from('usuarios')
        .select('user_id, nombre, apellidos')
        .eq('user_id', user.id)
        .single();

      if (error) {
        console.error('Error fetching user data:', error);
      } else {
        setUserData(data);
      }
    }
  }, []);

  const fetchTareas = useCallback(async () => {
    if (!userData) return;

    let query = supabase
      .from('tareas')
      .select(`
        *,
        leads!lead_id (
          id,
          nombre,
          apellidos,
          telefono,
          email,
          estado
        ),
        clientes!cliente_id (
          id,
          nombre,
          apellidos,
          telefono,
          email
        )
      `)
      .eq('user_idasignado', userData.user_id)
      .eq('tarea_pendiente', true);

    if (activeTab === 'comerciales') {
      query = query.eq('tarea_comercial', true);
    } else if (activeTab === 'procesales') {
      query = query.eq('tarea_procesal', true);
    }

    const { data: tareasData, error } = await query;

    if (error) {
      console.error('Error fetching tareas:', error);
    } else {
      const tareasFormateadas = tareasData.map(tarea => {
        const contacto = tarea.leads || tarea.clientes;
        
        return {
          ...tarea,
          nombre: contacto?.nombre || '',
          apellidos: contacto?.apellidos || '',
          telefono: contacto?.telefono || '',
          email: contacto?.email || '',
          estado: tarea.leads?.estado || null
        };
      });
      setTareas(tareasFormateadas);
    }
  }, [userData, activeTab]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  useEffect(() => {
    if (userData) {
      fetchTareas();
    }
  }, [userData, fetchTareas]);

  const handleMarcarTareaRealizada = async (tareaIds) => {
    const { error } = await supabase
      .from('tareas')
      .update({ tarea_pendiente: false, tarea_realizada: true })
      .in('id', tareaIds);

    if (error) {
      console.error('Error al actualizar la tarea:', error);
    } else {
      setTimeout(() => {
        setTareas(prevTareas => prevTareas.filter(tarea => !tareaIds.includes(tarea.id)));
        setSelectedTareas([]);
      }, 500);
    }
  };

  const handleCheckboxChange = (tareaId) => {
    setSelectedTareas(prev =>
      prev.includes(tareaId) ? prev.filter(id => id !== tareaId) : [...prev, tareaId]
    );
  };

  const handleSelectAllChange = useCallback(() => {
    if (selectedTareas.length === tareas.length) {
      setSelectedTareas([]);
    } else {
      setSelectedTareas(tareas.map(tarea => tarea.id));
    }
  }, [selectedTareas.length, tareas]);

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Texto copiado al portapapeles');
    }).catch(err => {
      console.error('Error al copiar el texto: ', err);
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: () => (
          <input
            type="checkbox"
            onChange={handleSelectAllChange}
            checked={selectedTareas.length === tareas.length}
            className="w-5 h-5"
          />
        ),
        accessor: 'select',
        Cell: ({ row }) => (
          <input
            type="checkbox"
            checked={selectedTareas.includes(row.original.id)}
            onChange={() => handleCheckboxChange(row.original.id)}
            className="w-5 h-5"
          />
        ),
      },
      {
        Header: 'Acciones',
        accessor: 'acciones',
        Cell: ({ row }) => (
          <button 
            onClick={() => handleMarcarTareaRealizada([row.original.id])} 
            className={`flex items-center justify-center ${
              row.original.tarea_realizada ? 'text-green-500' : 'text-gray-500 hover:text-green-500'
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-10 h-10">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
            </svg>
          </button>
        ),
      },
      {
        Header: 'Titulo tarea',
        accessor: 'titulo_tarea',
        Cell: ({ row, value }) => {
          const path = row.original.lead_id 
            ? `/leads/${row.original.lead_id}`
            : row.original.cliente_id 
              ? `/clientes/${row.original.cliente_id}`
              : '#';
              
          return (
            <Link 
              to={path} 
              target="_blank" 
              rel="noopener noreferrer" 
              className={`text-blue-500 hover:underline ${row.original.tarea_realizada ? 'line-through' : ''}`}
            >
              {value}
            </Link>
          );
        },
      },
      {
        Header: 'Fecha',
        accessor: 'fecha_tarea',
        Cell: ({ value }) => new Date(value).toLocaleDateString('es-ES'),
      },
      {
        Header: 'Nombre',
        accessor: 'nombre',
      },
      {
        Header: 'Apellidos',
        accessor: 'apellidos',
      },
      {
        Header: 'Teléfono',
        accessor: 'telefono',
        Cell: ({ value }) => (
          <div className="flex items-center">
            <button onClick={() => handleCopy(value)} className="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z" />
              </svg>
            </button>
            {value}
          </div>
        ),
      },
    ],
    [selectedTareas, tareas, handleSelectAllChange]
  );

  const defaultColumn = useMemo(() => ({ Filter: () => null }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: tareas,
      defaultColumn,
      initialState: { globalFilter: '' },
    },
    useGlobalFilter,
    useSortBy
  );

  const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => (
    <div className="flex justify-between items-center">
      <div className="flex items-center justify-center">
        <button
          onClick={() => handleMarcarTareaRealizada(selectedTareas)}
          className={`p-2 flex items-center ${selectedTareas.length === 0 ? 'bg-gray-300' : 'bg-gradient-to-r from-purple-500 to-blue-500 text-white'}`}
          disabled={selectedTareas.length === 0}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 mr-2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1-1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
          </svg>
          Marca como realizado
        </button>
      </div>
    </div>
  );

  const paginatedRows = rows.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);
  const pageCount = Math.ceil(rows.length / rowsPerPage);

  const renderTableContent = () => (
    <div className="pt-2 bg-white border border-gray-200 rounded-xl">
      <div className="px-1">
        <div className="w-full overflow-x-auto">
          <table {...getTableProps()} className="w-full min-w-max">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()} className="text-left">
                  {headerGroup.headers.map(column => {
                    const { key, ...rest } = column.getHeaderProps(column.getSortByToggleProps());
                    return (
                      <th key={key} {...rest} className="p-3 border-b border-gray-300 bg-gray-100 text-gray-700">
                        <div className="flex items-center">
                          {column.render('Header')}
                          <span className="ml-2">
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                </svg>
                              ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                                </svg>
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {paginatedRows.map((row, i) => {
                prepareRow(row);
                const backgroundColor = i % 2 === 0 ? 'bg-gray-50' : 'bg-white';
                return (
                  <tr key={row.id} {...row.getRowProps()} className={`${backgroundColor} hover:bg-gray-100`}>
                    {row.cells.map(cell => {
                      const { key, ...rest } = cell.getCellProps();
                      return (
                        <td key={key} {...rest} className="py-3 pr-4 pl-3 border-b border-gray-200">
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  return (
    <div className="py-4 overflow-hidden">
      <div className="container mx-auto">
        <div className="flex justify-between mb-4">
          <div className="flex-1">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        </div>
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            <button
              className={`whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm ${activeTab === 'todas' ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
              onClick={() => setActiveTab('todas')}
            >
              Todas las Tareas
            </button>
            <button
              className={`whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm ${activeTab === 'comerciales' ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
              onClick={() => setActiveTab('comerciales')}
            >
              Tareas Comerciales
            </button>
            <button
              className={`whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm ${activeTab === 'procesales' ? 'border-blue-500 text-blue-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`}
              onClick={() => setActiveTab('procesales')}
            >
              Tareas Procesales
            </button>
          </nav>
        </div>
        {renderTableContent()}
        <div className="flex justify-between mt-4 pt-2">
          <span className="text-sm text-gray-600">Mostrando {currentPage * rowsPerPage + 1} a {Math.min((currentPage + 1) * rowsPerPage, rows.length)} de {rows.length} resultados</span>
          <div className="flex space-x-1">
            <button className="p-2 bg-gray-200 text-black rounded" onClick={() => setCurrentPage(0)} disabled={currentPage === 0}>
              {"<<"}
            </button>
            <button className="p-2 bg-gray-200 text-black rounded" onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
              {"<"}
            </button>
            {Array.from({ length: pageCount }, (_, index) => (
              <button
                key={index}
                className={`p-2 ${index === currentPage ? 'bg-gray-300' : 'bg-gray-200'} text-black rounded`}
                onClick={() => setCurrentPage(index)}
              >
                {index + 1}
              </button>
            ))}
            <button className="p-2 bg-gray-200 text-black rounded" onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage >= pageCount - 1}>
              {">"}
            </button>
            <button className="p-2 bg-gray-200 text-black rounded" onClick={() => setCurrentPage(pageCount - 1)} disabled={currentPage >= pageCount - 1}>
              {">>"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TusTareas;
